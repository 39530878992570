@import '../../../styles/responsive';
@import '../../../styles/variables/variables';

.consumerInfoBtn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 70px;
	height: 40px;
	border: none;
	border-radius: 3px;

	& span {
		margin-left: 7px;
		font-family: $secondary-font;
		font-weight: 400;
		font-size: 16px;
		line-height: 23px;
		text-transform: capitalize;
		color: $primary_color;
	}

	&:not(:last-of-type) {
		margin-right: 10px;
	}

	&:first-of-type {
		width: 280px;
		height: 40px;
		background-color: $secondary_bg_color;

		&:hover {
			background-color: $main_bg_color;
		}
	}
}

.pfAdditionalInfo {
	.btnContainer {
		display: flex;
		margin-bottom: 50px;

		hr {
			color: $hr;
			margin: 5px 10px;
		}

		.dropdownBasicItemText {
			display: flex;
			align-items: center;

			span {
				margin-left: 14px;
				font-family: $base-font;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 22px;
				color: $title_color;
			}
		}

		.dropdownItemText {
			display: flex;
			align-items: center;

			span {
				margin-left: 14px;
				font-family: $base-font;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 22px;
				color: $warning_color;
			}

			svg {
				path {
					stroke: $warning_color !important;
				}
			}
		}
	}

	.pfLeadsInfoDescription {
		display: flex;
		flex-direction: column;
		color: $text_color;
		margin-bottom: 20px;

		& span:first-child {
			font-family: $base-font;
			font-style: normal;
			font-weight: 500;
			font-size: 12.8px;
			line-height: 18px;
			color: $placeholder_color;
			margin-bottom: 5px;
		}

		& span:last-child {
			font-family: $base-font;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 22px;
			color: $text_color;
		}
	}

	.pfLeadsInfoTeam {
		padding: 0;
		margin-bottom: 50px;

		& li {
			display: flex;
			align-items: center;
			color: $text_color;
			list-style-type: none;

			& > div {
				margin-right: 15px;
			}

			&:not(:last-of-type) {
				margin-bottom: 10px;
			}

			.teamItemInfo {
				p {
					margin: 0;

					&:first-child {
						font-family: $base-font;
						font-style: normal;
						font-weight: normal;
						font-size: 16px;
						line-height: 19px;
						color: $text_color;
					}
					&:last-child {
						ont-family: $base-font;
						font-style: normal;
						font-weight: normal;
						font-size: 12.8px;
						line-height: 150%;
						color: $text_color;
						opacity: 0.5;
					}
				}
			}
		}
	}

	.additionalInfoWrapper {
		margin-bottom: 50px;
	}

	.additionalInfoTitle {
		margin-bottom: 25px;
		font-family: $secondary-font;
		font-weight: 500;
		font-size: 13px;
		line-height: 25px;
		text-transform: uppercase;
		color: $placeholder_color;
	}
}

//.current-info {
//  &__btn {
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    width: 70px;
//    height: 40px;
//    border: none;
//    border-radius: 3px;
//
//    & span {
//      margin-left: 15px;
//      font-family: $secondary-font;
//      font-weight: 500;
//      font-size: 16px;
//      line-height: 23px;
//      text-transform: uppercase;
//      color: $white;
//    }
//
//    &:not(:last-of-type) {
//      margin-right: 10px;
//    }
//
//    &:first-of-type {
//      width: 280px;
//      height: 40px;
//      background-color: $primary_color;
//
//      &:hover {
//        background-color: $primary_hover_color;
//      }
//    }
//  }
//  &__btn-container {
//    display: flex;
//    margin-bottom: 50px;
//  }
//
//  &__title {
//    margin-bottom: 25px;
//    font-family: $secondary-font;
//    font-weight: 500;
//    font-size: 13px;
//    line-height: 18px;
//    text-transform: uppercase;
//    color: $placeholder_color;
//  }
//
//  &__subtitle {
//    margin-bottom: 10px;
//    font-weight: 500;
//    color: $primary_color;
//  }
//
//  &__risks-list {
//    padding: 0;
//    margin-bottom: 50px;
//
//    & li {
//      position: relative;
//      padding-left: 30px;
//      color: $text_color;
//      list-style-type: none;
//
//      &:not(:last-of-type) {
//        margin-bottom: 12px;
//      }
//
//      &::before {
//        content: "";
//        position: absolute;
//        top: 50%;
//        left: 1px;
//        transform: translateY(-50%);
//        width: 14px;
//        height: 14px;
//        background: $approval_color;
//        border-radius: 2px;
//      }
//    }
//
//    & li.high-risk::before {
//      background: $warning_color;
//    }
//  }
//
//  &__meeting {
//    display: flex;
//    margin-bottom: 50px;
//
//    & > div {
//      margin-right: 15px;
//    }
//
//    & span:first-of-type {
//      margin-right: 15px;
//      font-weight: 500;
//      color: $title_color;
//    }
//
//    & span:last-of-type {
//      color: $text_color;
//    }
//  }
//
//  &__reports {
//    display: flex;
//
//    & > span {
//      margin-right: 25px;
//      color: $title_color;
//    }
//
//    & ul {
//      padding: 0;
//      margin-bottom: 0;
//    }
//
//    & li {
//      cursor: pointer;
//      display: flex;
//      align-items: center;
//      padding: 7px 12px;
//      height: 30px;
//      background: $secondary_bg_color;
//      border-radius: 24px;
//
//      &:not(:last-of-type) {
//        margin-bottom: 10px;
//      }
//
//      & > div {
//        margin-left: 10px;
//      }
//
//      & > span {
//        font-size: 10px;
//        line-height: 14px;
//        color: $text_color;
//      }
//    }
//  }
//}
//
//.btn__icon {
//  cursor: pointer;
//}
//
//#bg-nested-dropdown {
//  width: 70px;
//  height: 40px;
//  margin-left: 10px;
//  border: none;
//  border-radius: 3px;
//  color: $title_color;
//  background-color: $secondary_bg_color;
//  &:after {
//    content: none;
//  }
//  &:focus {
//    box-shadow: none;
//  }
//  &:hover {
//    background-color: $main_bg_color;
//  }
//  div {
//    display: flex;
//    flex-direction: row;
//    justify-content: space-between;
//    align-items: center;
//    width: 17.25px;
//    margin: auto;
//  }
//}
//
//.dropdown-menu {
//  width: 260px;
//  padding: 0 !important;
//  inset: 5px auto auto 0 !important;
//  transform: translate(-180px, 42px) !important;
//  background: $white;
//  border: 1px solid rgba(0, 0, 0, 0.04);
//  box-sizing: border-box;
//  box-shadow: 0 1px 12px rgba(63, 72, 87, 0.1);
//  border-radius: 3px;
//}
//
//.dropdown-item {
//  display: flex !important;
//  justify-content: flex-start;
//  align-items: center;
//  height: 45px;
//  padding: 0 20px !important;
//
//  &:hover {
//    background-color: $secondary_bg_color !important;
//    text-decoration: none;
//  }
//
//  &__text {
//    display: flex;
//    align-items: center;
//
//    span {
//      margin-left: 16px;
//      font-family: $base-font;
//      font-style: normal;
//      font-weight: normal;
//      font-size: 16px;
//      line-height: 22px;
//      color: $title_color;
//    }
//  }
//}
