@import '../../../styles/responsive';

.pf-teamContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.pf-tableTeam {
	display: inline-block;
	width: 28px;
	height: 22px;
	margin-right: 14px;
}
