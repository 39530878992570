@import '../../styles/responsive';
@import '../../styles/variables/variables';

.social-bloc {
  &__wrapper {
    width: 100%;
    max-width: 570px;

    .youtube-frame {
      width: 100%;
      max-width: 570px;
      height: 320px;
      margin-bottom: 50px;

      @include belowMd {
        max-width: 320px;
        height: 200px;
      }
    }
  }

  &__label {
    font-family: $secondary-font;
    font-style: normal;
    font-weight: 500;
    font-size: 12.8px;
    line-height: 25px;
    text-transform: uppercase;
    color: $placeholder_color;

    margin-top: 0;
  }

  &__tags {
    margin-bottom: 25px;
    .pf-badge {
      margin-top: 0;
      margin-bottom: 10px;
    }
    @include belowLg {
      margin-bottom: 18px;
    }
  }

  &__emptyMessage {
    font-family: $base-font;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: $text_color;
  }
}
