@import '../../../styles/responsive';

.ecoAdvisorProfile {
	display: flex;
	flex-direction: column;
	flex: 1;
	background-color: $white;
	padding: 50px 120px 0;

	&:has(.invitation-note) {
		padding: 55px 120px 0;

		@include belowLg {
			padding: 40px 20px 0;
		}
	}

	@include belowLg {
		padding: 10px 20px 0;
		align-items: center;
		background-color: $white;
	}

	// ###################### grid-wrapper ###########################
	.grid-wrapper {
		display: grid;
		grid-template-columns: 380px 2fr;
		grid-template-rows: auto auto auto;
		column-gap: 150px;
		row-gap: 40px;

		.header {
			grid-column: 1 / 3;
		}
		.contact-info {
			grid-column: 1 / 2;
			grid-row: 2 / 3;
		}
		.general-info {
			grid-column: 2 / 3;
			grid-row: 2 / 4;
		}
		.social-info {
			grid-column: 1 / 2;
			grid-row: 3 / 4;
		}
		@include belowXL {
			column-gap: 30px;
		}
		@include belowMd {
			grid-template-columns: minmax(325px, 380px);
			grid-template-rows: repeat(4, auto); // auto 1fr 1fr 1fr
			column-gap: 0;
			row-gap: 50px;
			.header {
				grid-column: 1 / 2;
			}
			.contact-info {
				grid-column: 1 / 2;
				grid-row: 2 / 3;
			}
			.general-info {
				grid-column: 1 / 2;
				grid-row: 3 / 4;
			}
			.social-info {
				grid-column: 1 / 2;
				grid-row: 4 / 5;
			}
		}
	}

	.grid-wrapper {

		.header {
			.goBack-button {
				width: auto;
				padding: 0;

			}

			.invitation-note {
				width: 100%;
				max-width: 1100px;
				border-radius: 6px;
				padding: 32px;

				@media only screen and (max-width: 767px) {
					padding: 32px 20px;
				}

				.note-main {
					display: flex;
					flex-direction: column;
					padding-right: 30px;

					.note-text {
						font-family: $base-font;
						font-style: normal;
						font-weight: 400;
						font-size: 16px;
						line-height: 24px;
						color: #FFFFFF;
						margin-bottom: 15px;
					}

					.note-appLinks {
						display: flex;
						justify-content: flex-start;
						width: 100%;

						@media only screen and (max-width: 767px) {
							height: initial;
							justify-content: center;
							align-items: flex-start;
							flex-direction: column;
						}

						a {
							width: 165px;
							height: 50px;

							&:first-child {
								margin-bottom: 10px;
								margin-right: 10px;

								img {
									width: 100%;
									height: auto;
								}

								@media only screen and (max-width: 767px) {
									margin-right: initial;
									margin-bottom: 10px;
								}
							}

							&:nth-child(2) {
								img {
									width: 100%;
									height: auto;
								}
							}

						}
					}
				}
				
				.note-wrapper {
					display: flex;
					flex-direction: row;

					.note-img {
						max-width: 200px;
						max-height: 200px;
					}
				}
			}
		}

		.contact-info {
			.social-bloc__wrapper {
				margin-top: 40px;
			}

			&__avatar {
				&-wrapper {
					display: flex;
					align-items: center;
					@include onLg {
						margin-bottom: 30px;
					}
				}
			}
			&__name {
				font-family: $secondary-font;
				color: $title_color;
				font-weight: 600;
				margin-top: 15px !important;
				&-wrapper {
					display: flex;
					flex-direction: column;
					padding-left: 25px;
				}
			}
			.viewProfileButton {
				svg {
					path {
						stroke: none;
						fill: $primary_color;
					}
				}

				&:disabled {
					svg {
						path {
							stroke: none;
							fill: rgba($text_color, 0.5);
						}
					}
				}
			}
			.isActive-wrapper {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;
				margin-bottom: 15px;

				.isActive-dot {
					margin-right: 14px;

					&.activeDot {
						svg {
							circle {
								fill: #00874E;
							}
						}
					}

					&.inactiveDot {
						svg {
							circle {
								fill: #F92D4D;
							}
						}
					}
				}

				.text {
					font-family: $base-font;
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 19px;
					display: flex;
					align-items: center;

					color: $title_color;
				}
			}
			&__certificate {
				font-size: 12.8px;
				line-height: 18px;
				font-family: $base-font;
				font-weight: 400;
				width: 100%;
				padding: 0;
				color: $secondary_active_color;
			}
			&__edit-button {
				color: $placeholder_color;
				padding: 0;
				margin-bottom: 20px;
				font-weight: 400;
				@include belowLg {
					margin-top: 25px;
					color: $text_color;
					text-align: left;
					padding-left: 0;
					padding-right: 0;
				}
			}
			&__contact {
				color: $text_color;
				padding-top: 15px;
				padding-bottom: 20px;
				@include belowLg {
					padding-top: 30px;
					padding-bottom: 19px;
				}
			}
			&__info {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				align-items: center;
				font-family: $base-font;
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 19px;
				color: $text_color;

				&.companyName {
					font-weight: 500;
					margin-bottom: 40px;
				}

				.companyLogo {
					margin-right: 25px;
				}

				&-icon {
					display: inline-block;
					margin-right: 15px;
				}
			}
			&__buttons {
				display: grid;
				grid-template-columns: minmax(280px, 340px) 1fr;
				column-gap: 15px;
				row-gap: 5px;

				.deepLink {
					svg {
						path {
							stroke: $white;
						}
					}
				}

				.pf-button {
					height: 40px;
					padding: 0;
				}
			}
			&__role {
				font-family: $base-font;
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 19px;
				display: flex;
				align-items: center;

				color: #3F4857;

			}

			.btnContainer {
				display: flex;
				margin-bottom: 50px;
				width: 100%;
				max-width: 341px;

				@include belowMd {
					margin-bottom: 0;
				}


				.btnGroup {
					display: flex;
					flex-direction: column;
					width: 100%;

					&>button:first-child {
						margin-bottom: 5px;
					}

					.shareProfileButtonWrapper {
						display: flex;
						align-items: center;
						position: relative;
						z-index: 10;

						.shareProfileButton {
							&.noAuth {
								svg {
									path {
										fill: $white;
									}
								}
							}
							&:disabled {
								svg {
									path {
										fill: rgba($text_color, 0.5);
									}
								}
							}
						}

						.shareProfileTooltip {
							position: absolute;
							right: -34px;
						}
					}
				}

				hr {
					color: $hr;
					margin: 5px 10px;
				}

				.dropdownItemText {
					display: flex;
					align-items: center;

					svg {
						path {
							fill: $title_color;
						}
					}

					span {
						margin-left: 14px;
						font-family: $base-font;
						font-style: normal;
						font-weight: normal;
						font-size: 16px;
						line-height: 22px;
						color: $title_color;
					}

					&.removeText {

						svg {
							path {
								fill: $warning_color !important;
							}
						}

						span {
							color: $warning_color;
						}
					}
				}
				.dropdown {
					.dropdown-menu.show {
						min-width: 260px;
						padding: 0 !important;
						border-radius: 3px !important;
						overflow: hidden !important;
						border: 1px solid rgba(0, 0, 0, 0.04) !important;
						box-shadow: 0 1px 12px rgba(63, 72, 87, 0.1) !important;
						margin-top: 5px;
					}
				}
			}
		}

		.general-info {
			.social-bloc__wrapper {
				margin-top: 45px;
			}
		}
		.social-info {
		}
	}

	// ######################################################

	.pf-copyright {
		padding-top: 103px;
	}

	&__emptyMessage {
		font-family: $base-font;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 150%;
		color: $text_color;
	}
}
