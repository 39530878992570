@import '../../../styles/responsive';

.insurance-properties {
	&--container {
		padding: 30px 0 0 42px;
	}

	&--group {
		&:not(:last-child) {
			border-bottom: 1px solid #eeeff1;
			margin-bottom: 15px;
		}

		.riders {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;

			.rider {
				font-family: $base-font;
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 150%;
				text-align: right;

				color: $title_color;

			}
		}
	}
}
