@import '../../styles/responsive';
@import '../../styles/variables/variables';

.snackbar-containerRoot {
  width: 100%;
  max-width: 540px;
  z-index: 5000;

  &>div {
    width: 100%;
  }

  .icon-approved, .icon-attention {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-right: 15px;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

