@import '../../../../styles/responsive';
@import '../../../../styles/variables/variables';

.view-all {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	padding: 40px 0;

	&__container {
		width: 100%;
		max-width: 1000px;
		min-width: 320px;
		padding: 0 70px;

		@include belowMd() {
			padding: 0 20px;
		}

		&-backButton {
			margin-bottom: 20px;
		}

		&-titleWrapper {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			margin-bottom: 30px;

			@include belowMd() {
				margin-bottom: 45px;
			}

			&-title {
				font-family: $secondary-font;
				font-style: normal;
				font-weight: 500;
				font-size: 31.25px;
				line-height: 39px;
				text-transform: capitalize;
				margin-right: 30px;
				margin-bottom: 35px;

				color: $title_color;
			}

			&-sendTaskButton {
				margin-bottom: 35px;
				max-width: 220px;
				height: 40px;
			}
		}
	}
}
