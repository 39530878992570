@import '../../../styles/responsive';
@import '../../../styles/variables/variables';

.specialization-item {
	cursor: pointer;
	width: fit-content;
	padding: 10px 20px;
	height: 100%;
	background-color: $secondary_bg_color;
	border-radius: 30px;
	color: $text_color;
	text-align: center;
	font-family: $base-font;
	font-style: normal;
	font-weight: 400;
	font-size: 12.8px;
	line-height: 15px;
	margin: 5px 5px 5px 0;

	-ms-user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	user-select: none;

	&.checked {
		background: $primary_color;
		color: $white;
	}

	border: none;
	outline: none;
}
