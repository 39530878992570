@import '../../../styles/responsive';

.radioGroup {
	.pf-radio-group {
		display: flex;
		flex-wrap: wrap;

		.MuiFormControlLabel-root {

			//margin-bottom: 20px !important;
			margin-left: 0 !important;

			.radioIcon {
				display: flex;
				justify-content: center;
				align-items: center;

				div {
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}

			.MuiButtonBase-root {
				padding: 0 !important;
				margin-right: 15px !important;
			}

			.MuiFormControlLabel-label {
				font-family: $base-font;
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 19px;

				color: #3F4857;

			}

			&:hover {
				cursor: pointer;
				background-color: transparent !important;
			}
		}

		&--vertical {
			.MuiFormControlLabel-root {
				&:not(:last-child) {
					margin-bottom: 20px !important;
				}
			}
		}
		&--horizontal {
			.MuiFormControlLabel-root {
				margin-bottom: 20px !important;
			}
		}

		&__hint {
			font-weight: normal;
			width: 100%;
			text-align: left;
			font-size: 12.8px;
			color: $text_color;
			line-height: 1.38;
			margin-top: 5px;
			&--error {
				//margin-top: 5px;
				color: $warning_color;
			}
		}
	}
}
