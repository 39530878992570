@import '../../../styles/variables/variables';
@import '../../../styles/responsive';

.pf-signUp {
	background-color: $main_bg_color;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.pf-select__menu {
		text-align: left;
	}

	@include belowLg {
		background-color: $white;
		.pf-header {
			justify-content: center;
			box-shadow: 0px 0px 12px rgba(63, 72, 87, 0.1);
			&__label,
			&__user {
				display: none;
			}
		}
	}
	&__form-wrapper {
		background-color: $white;
		width: 590px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		padding: 70px 70px 60px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		border-radius: 6px;
		@include belowSm {
			background-color: transparent;
			padding-top: 40px;
			padding-left: 35px;
			padding-right: 35px;
			padding-bottom: 10px;
		}
	}
	&__form {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 50px;
		padding-bottom: 50px;
		&-title {
			font-family: $secondary-font;
			font-style: normal;
			font-weight: 600;
			font-size: 31.25px;
			color: $title_color;
		}
		&-footer {
			/* txt */

			font-family: $base-font;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 22px;
			/* identical to box height */

			color: #59657a;
		}

		.termsOfUseCheckContainer {
			margin-top: 50px !important;
			margin-bottom: 50px !important;

			@include belowSm {
				margin-top: 35px !important;
				margin-bottom: 35px !important;
			}
		}

		.inputSelectCompany {
			display: flex;
			flex-direction: row;
			align-items: flex-end;
			margin-bottom: 16px !important;
			transition: opacity 0.4s;

			&__companyLogo {
				margin-right: 5px;
			}

			&.disabled {
				opacity: 0.5;
			}
		}

		.anotherCompany {
			height: 280px;
			opacity: 1;
			transition: all 0.4s;

			&.disabled {
				overflow: hidden;
				height: 0;
				opacity: 0;
			}
		}

		.signUpButton {
			height: 50px;
		}
	}

	&__footer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		max-width: 450px;
		margin: 0 auto;
		padding: 47px 70px 0;

		.linkWrapper {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			padding-bottom: 40px;
			width: 100%;

			span,
			.pf-signUp__footer-link {
				font-family: $base-font;
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 19px;
			}

			span {
				margin-right: 5px;
				color: $text_color;
			}

			.pf-signUp__footer-link {
				font-weight: 500;
				color: #3349b5;
			}

			@include belowLg {
				padding-bottom: 80px;
			}
		}

		@include belowLg {
			border-top: 1px solid #eeeff1;
			padding: 35px 70px 0;
		}
	}
}
