@import 'styles/responsive';
@import 'styles/variables/variables';

.container {
	min-width: 240px;

	display: flex;
	flex-direction: column;

	@include belowXL() {
		position: fixed;
		bottom: 0;
		left: 0;

		width: 100%;

		padding: 15px 20px;

		border-top: 1px solid #eeeff1;
		background: #ffffff;
	}
}

.content {
	display: flex;
	flex-direction: column;

	@include belowXL() {
		flex-direction: row-reverse;
		justify-content: space-between;
		align-items: center;
	}
}

.beforeDiscount {
	font-family: $secondary-font;
	font-style: normal;
	font-weight: 400;
	font-size: 25px;
	line-height: 31px;
	text-decoration-line: line-through;

	color: #7a8495;

	@include belowXL() {
		text-align: right;
		font-size: 10.24px;
		line-height: 13px;
	}
}

.price {
	margin-bottom: 10px;

	font-family: $secondary-font;
	font-style: normal;
	font-weight: 700;
	font-size: 48.83px;
	line-height: 61px;

	color: #3349b5;

	@include belowXL() {
		margin-bottom: 0px;

		text-align: right;
		font-size: 25px;
		line-height: 30px;
	}
}

.period {
	display: flex;
	align-items: center;

	font-family: $base-font;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;

	color: #3f4857;

	> span {
		text-transform: capitalize;

		> span {
			text-transform: none;
		}
	}

	@include belowXL() {
		font-size: 12.8px;
		line-height: 20px;
	}

	&Button {
		display: flex;
		align-items: center;

		user-select: none;

		font-family: $base-font;
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;

		color: #3349b5;

		@include belowXL() {
			font-size: 12.8px;
			line-height: 20px;
		}
	}
}

.discountBadge {
	margin-left: 5px;
	padding: 0 8px;
	border-radius: 24px;

	font-family: $base-font;
	font-style: normal;
	font-weight: 700;
	font-size: 10.24px;
	line-height: 20px;

	text-transform: uppercase;

	color: #ffffff;
	background: #db8f2c;
}

.submit {
	margin-top: 25px;

	@include belowXL() {
		margin-top: 10px;
	}
}
