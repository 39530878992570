@import '../../../styles/responsive';
@import '../../../styles/variables/variables';

.view-all {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 40px 0;

  &__container {
    width: 100%;
    max-width: 1000px;
    min-width: 320px;
    padding: 0 70px;

    @include belowMd() {
      padding: 0 20px;
    }

    &-backButton {
      margin-bottom: 20px;
    }

    &-title {
      margin-bottom: 65px;
      font-family: $secondary-font;
      font-style: normal;
      font-weight: 500;
      font-size: 31.25px;
      line-height: 39px;
      text-transform: capitalize;

      color: $title_color;

      @include belowMd() {
        margin-bottom: 45px;
      }
    }
  }
}
