@use 'sass:map';
@import './variables/variables';
@mixin closeBtn {
	background-image: url('../assets/icon-closeToasts.svg');
	margin-right: initial !important;
	margin-left: initial !important;
}
@mixin belowLg() {
	@media screen and (max-width: map.get( $grid-breakpoints, 'lg') - 1px) {
		@content;
	}
}
@mixin belowSm() {
	@media screen and (max-width: map.get( $grid-breakpoints, 'sm') - 1px) {
		@content;
	}
}
@mixin belowMd() {
	@media screen and (max-width: map.get( $grid-breakpoints, 'md') - 1px) {
		@content;
	}
}

@mixin belowLg() {
	@media screen and (max-width: map.get( $grid-breakpoints, 'lg') - 1px) {
		@content;
	}
}

@mixin onLg() {
	@media screen and (min-width: map.get( $grid-breakpoints, 'lg')) {
		@content;
	}
}

@mixin belowXL() {
	@media screen and (max-width: map.get( $grid-breakpoints, 'xl') - 1px) {
		@content;
	}
}

@mixin belowXXL() {
	@media screen and (max-width: map.get( $grid-breakpoints, 'xxl') - 1px) {
		@content;
	}
}
