@import '../../../../styles/responsive';

.consumers {
	.tableEmailCell {
		display: flex;
		flex-direction: column;

		.unconfirmedEmail {
			margin: 5px 0 0;
			font-family: $base-font;
			font-style: normal;
			font-weight: 400;
			font-size: 12.8px;
			line-height: 15px;

			color: #F92D4D;
		}
	}
}

@media only screen and (max-width: 360px) {
	.rs-table-cell:first-child,
	.rs-table-cell-header:first-child {
		width: 60px !important;
		min-width: 60px !important;
	}
	.rs-table-cell-first .rs-table-cell-content {
		width: 60px !important;
		min-width: 60px !important;
	}
}
