@import '../../../styles/responsive';

.pf-header {
	display: flex;
	align-items: center;
	//justify-content: space-between;
	justify-content: space-between;
	padding: 0 34px;
	width: 100%;
	min-width: 100%;
	height: 60px;
	z-index: 1101;

	&.showSearch {
		z-index: 1104;
	}

	@include belowLg {
		height: 50px;
		padding: 0;
	}

	@include belowXL {
		padding: 0;
	}

	&__user {
		display: flex;
		align-items: center;
		height: 100%;

		&-img {
			width: 40px;
			height: 40px;
			object-fit: cover;
		}
	}
	&__dropdown {
		.dropdown {
			&-toggle {
				border: none;
				&.dropdown-toggle {
					&::after {
						display: none;
					}
				}
			}
			&-header {
				font-weight: 600;
				color: $title_color;
				font-size: 16px;
				line-height: 22.08px;
				padding: 25px 20px 20px;
			}
			&-item {
				padding-left: 25px;
				padding-right: 25px;
				color: $title_color;
				&:hover {
					background-color: $main_bg_color;
					color: $title_color;
				}

				.shareProfileLink {
					svg {
						path {
							fill: #3F4857;
						}
					}
				}
			}
			&-menu {
				margin-top: 5px;
				margin-left: -35px;
				border: none;
				border-radius: 12px;
				box-shadow: (0px 0px 12px rgba($title_color, 0.1));

				&::before {
					content: '';
					display: block;
					position: absolute;
					top: -24px;
					width: 0;
					height: 0;
					right: 10px;
					margin: auto;
					border: 13px solid transparent;
					border-bottom-color: white;
				}
			}
		}
	}

	&__container {
		display: flex;
		align-items: center;
		flex-grow: 1;

		@include belowLg {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			padding: 0;
			flex-grow: 0;
		}
	}

	// variants
	&--portal {
		box-shadow: 0 0 12px rgba($title_color, 0.1);
		background-color: $white;
	}

	.dropdown-menu {
		width: initial;
		min-width: 310px;
		padding: 0 !important;


		.banner__img {
			width: 100%;
			max-height: 70px;
		}
	}

	.banner {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 10px;

		.banner-main-wrapper {
			width: 100%;
			border-radius: 6px;
			overflow: hidden;
			background-size: cover;
			background-repeat: no-repeat;
		}
		.banner-main {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			padding: 13px 16px;
			background: rgba(51, 73, 181, 0.3);
			backdrop-filter: blur(15px);

			.banner-title {
				font-family: $secondary-font;
				font-style: normal;
				font-weight: 500;
				font-size: 12.8px;
				line-height: 16px;
				text-transform: uppercase;
				color: $white;
				opacity: 0.7;
				margin-bottom: 3px;
			}

			.eduHab {
				span {
					font-family: $secondary-font;
					font-style: normal;
					font-weight: 500;
					font-size: 20px;
					line-height: 25px;
					color: $white;
				}
			}
		}
	}

	.banner__hr {
		margin: 10px 0 !important;
		border-top: solid 1px $hr;
		height: 0 !important;
	}

	.dropdown-footer {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		padding: 12px 15px 22px;

		.dropdownItem span {
			margin-right: 20px;
			margin-left: 0;
			font-family: $base-font;
			font-style: normal;
			font-weight: 400;
			font-size: 12.8px;
			line-height: 15px;
			color: $line_color !important;
		}
	}
}

.pf-superAdminHeader__dropdown-menu {
	padding: 25px 0 17px 0;
}


.upgrade-action-wrapper {
	margin-left: 8px;
	margin-right: 8px;
	border-radius: 6px;
	margin-bottom: 15px;
}

.upgrade-action-button {
	margin: unset !important;
	background: rgba(51, 73, 181, 0.3) !important;
	backdrop-filter: blur(15px) !important;
}

.dropdown-user-section {
	display: flex !important;

	.logout-icon {
		margin-left: auto;
		cursor: pointer;
	}
}

.pro-email-signature {
	margin-top: 5px;
	max-width: 93%;
    margin-left: 10px;
}

.french-village-community {
	margin-top: 15px;
	max-width: 93%;
    margin-left: 10px;
}