@import '../../../../styles/responsive';

.pf-advisorProfile {
	display: flex;
	flex-direction: column;
	flex: 1;
	background-color: $white;
	padding: 50px 120px 0;

	@include belowLg {
		padding: 10px 20px 0;
		align-items: center;
		background-color: $white;
	}
	// ###################### grid-wrapper ###########################
	.grid-wrapper {
		display: grid;
		grid-template-columns: 380px 2fr;
		grid-template-rows: auto 1fr 1fr;
		column-gap: 150px;
		row-gap: 40px;

		.header {
			grid-column: 1 / 3;
		}
		.contact-info {
			grid-column: 1 / 2;
			grid-row: 2 / 3;
		}
		.general-info {
			grid-column: 2 / 3;
			grid-row: 2 / 4;
		}
		.social-info {
			grid-column: 1 / 2;
			grid-row: 3 / 4;
		}
		@include belowXL {
			column-gap: 30px;
		}
		@include belowMd {
			grid-template-columns: minmax(325px, 380px);
			grid-template-rows: repeat(4, auto); // auto 1fr 1fr 1fr
			column-gap: 0;
			row-gap: 50px;
			.header {
				grid-column: 1 / 2;
			}
			.contact-info {
				grid-column: 1 / 2;
				grid-row: 2 / 3;
			}
			.general-info {
				grid-column: 1 / 2;
				grid-row: 3 / 4;
			}
			.social-info {
				grid-column: 1 / 2;
				grid-row: 4 / 5;
			}
		}
	}

	.grid-wrapper {
		.header {
			.goBack-button {
				width: auto;
				padding: 0;
			}
		}

		.contact-info {
			&__avatar {
				&-wrapper {
					display: flex;
					align-items: center;
					@include onLg {
						margin-bottom: 30px;
					}
				}
			}
			&__name {
				font-family: $secondary-font;
				color: $title_color;
				font-weight: 600;
				margin-top: 15px !important;
				&-wrapper {
					display: flex;
					flex-direction: column;
					padding-left: 25px;
				}
			}
			&__certificate,
			&__status {
				font-size: 12.8px;
				line-height: 18px;
				color: $text_color;
				font-family: $base-font;
				font-weight: 400;
				padding-left: 13px;
			}
			&__status {
				margin: 5px 0 15px;
				padding-left: 13px;
				position: relative;
				&:before {
					content: '';
					display: block;
					position: absolute;
					height: 6px;
					width: 6px;
					border-radius: 6px;
					background-color: $placeholder_color;
					top: 0;
					bottom: 2px;
					left: 0;
					margin: auto;
				}
				&--active {
					&:before {
						background-color: $active_color;
					}
				}
				&--inactive {
					&:before {
						background-color: $warning_color;
					}
				}
			}
			&__certificate {
				width: 100%;
				padding: 0;
				color: $secondary_active_color;
			}
			&__edit-button {
				color: $placeholder_color;
				padding: 0;
				margin-bottom: 20px;
				font-weight: 400;
				@include belowLg {
					margin-top: 25px;
					color: $text_color;
					text-align: left;
					padding-left: 0;
					padding-right: 0;
				}
			}
			&__contact {
				color: $text_color;
				padding-top: 15px;
				padding-bottom: 20px;
				@include belowLg {
					padding-top: 30px;
					padding-bottom: 19px;
				}
			}
			&__info {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				align-items: center;
				font-family: $base-font;
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 19px;
				color: $text_color;

				&.companyName {
					font-weight: 500;
					margin-bottom: 40px;
				}

				.companyLogo {
					margin-right: 11px;
				}

				&.email {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					margin-bottom: 1rem;

					.emailWrapper {
						display: flex;
						flex-direction: row;
					}

					.unconfirmedEmail {
						margin: 5px 0 0 31px;
						font-family: $base-font;
						font-style: normal;
						font-weight: 400;
						font-size: 12.8px;
						line-height: 15px;

						color: #f92d4d;
					}
				}

				&-icon {
					display: inline-block;
					margin-right: 15px;
				}
			}
			&__buttons {
				display: grid;
				grid-template-columns: minmax(280px, 340px) 1fr;
				column-gap: 15px;
				row-gap: 5px;

				.deepLink {
					svg {
						path {
							stroke: $white;
						}
					}
				}

				.pf-button {
					height: 40px;
					padding: 0;
				}
			}
			&__role {
				font-family: $base-font;
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 19px;
				display: flex;
				align-items: center;

				color: #3f4857;
			}
		}

		.general-info {
		}
		.social-info {
		}
	}

	// ######################################################

	.pf-copyright {
		padding-top: 103px;
	}

	&__emptyMessage {
		font-family: $base-font;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 150%;
		color: $text_color;
	}

	.dropdownButton {
		button {
			margin-left: 0 !important;
		}

		.dropdown-menu {
			padding: 0 !important;
			inset: 5px auto auto 0 !important;
			transform: translate(0px, 42px) !important;
			min-width: 260px;
			border-radius: 3px !important;
			overflow: hidden !important;
			border: 1px solid rgba(0, 0, 0, 0.04) !important;
			box-shadow: 0 1px 12px rgba(63, 72, 87, 0.1) !important;
		}

		hr {
			color: $hr;
			margin: 5px 10px;
		}
	}

	.dropdownItemText {
		display: flex;
		align-items: center;

		span {
			margin-left: 14px;
			font-family: $base-font;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 22px;
			color: $warning_color;
		}

		svg {
			path {
				stroke: $warning_color !important;
			}
		}

		&__block {
			display: flex;
			align-items: center;
			margin-left: -4px;

			svg {
				path,
				circle {
					stroke: $title_color;
				}
			}

			span {
				margin-left: 10px;
				font-family: $base-font;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 22px;
				color: $title_color;
			}
		}
	}

	.dropdownItemTextChange {
		display: flex;
		align-items: center;

		span {
			margin-left: 14px;
			font-family: $base-font;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 22px;
			color: $title_color;
		}
		svg {
			path {
				stroke: $title_color !important;
			}
		}
	}

	.dropdownSendItemTextChange {
		display: flex;
		align-items: center;

		span {
			margin-left: 14px;
			font-family: $base-font;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 22px;
			color: $title_color;
		}
	}
}
