@import '../../../../styles/responsive';

.api-instances {
	height: 100%;


	.name-group {
		display: flex;
		flex-direction: column;

		.name {
			word-break: break-word;
			font-family: $base-font;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
			color: $text_color;
		}
	}


	.instance-key {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;

		.icon-copy {
			cursor: pointer;
			width: 16px;
			height: auto;
			margin: 0 14px 0 0;
		}

		&__text {
			cursor: pointer;
			max-height: 57px;
			//overflow: auto;
			&::-webkit-scrollbar {
				width: 0;
			}
			-ms-overflow-style: none; /* IE 10+ */
			scrollbar-width: none; /* Firefox */
		}
	}
	.instance-note,
	.instance-name {
		max-height: 57px;
		overflow: auto;
		&::-webkit-scrollbar {
			width: 0;
		}
		-ms-overflow-style: none; /* IE 10+ */
		scrollbar-width: none; /* Firefox */
	}

	.newAPI-button {
		overflow: hidden;
		position: fixed;
		right: 40px;
		bottom: 90px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 50px;
		height: 50px;
		padding: 0 15px;
		background: $primary_color;
		border-radius: 36px;
		color: $white;
		transition: width 0.5s ease-out;
		box-shadow: 0 0 12px rgba($title_color, 0.1);

		.pf-icon-plus {
			position: absolute;
			left: 19px;
			transition: 0.5s ease-out;

			span {
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}

		&__text {
			position: absolute;
			left: 50px;
			width: 165px;
			font-family: $secondary-font;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 23px;
			text-transform: capitalize;
			color: $white;
			opacity: 0;
			transition: 0.5s ease-out;
		}

		&:hover {
			width: 280px;
			background: $primary_hover_color;

			.pf-icon-plus {
				position: absolute;
				left: 46px;
			}

			.newAPI-button__text {
				left: 71px;
				opacity: 1;
			}
		}
	}

	.api-menu {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0;

		.pf-IconButton__api {
			width: 70px;
			height: 40px;
			&:hover {
				background-color: $hr !important;
				border-radius: 0 !important;
			}
			div {
				background-color: transparent !important;
			}
		}
	}

	.dropdownButton {
		position: static;
		.dropdown-menu {
			padding: 0 !important;
			inset: 5px auto auto 0 !important;
			transform: translate(0px, 42px) !important;
			min-width: 260px;
			border-radius: 3px !important;
			overflow: hidden !important;
			border: 1px solid rgba(0, 0, 0, 0.04) !important;
			box-shadow: 0 1px 12px rgb(63 72 87 / 10%) !important;
		}
	}
	//.rs-table-hover .rs-table-row:hover .rs-table-cell, .rs-table-hover .rs-table-row:hover .rs-table-cell-group, .rs-table-hover .rs-table-row:not(.rs-table-row-header):hover {
	//  background-color: white !important;
	//  cursor: initial;
	//}

	.active {
		background-color: white !important;
		cursor: initial;

		& > div {
			div {
				background-color: white !important;
			}
		}
		&:hover {
			background: #f7f7f8 !important;
			& > div {
				div:not(.pf-splitButton div) {
					background: #f7f7f8 !important;
				}
			}
		}
	}

	.pf-splitButton {
		button {
			background-color: transparent;
		}
	}

	@media only screen and (max-width: 991px) {
		.newAPI-button {
			width: 40px;
			height: 40px;
			right: 65px;
			bottom: initial;

			.pf-icon-plus {
				left: 14px;
				margin-right: 0;
			}

			&:hover {
				width: 240px;

				.pf-icon-plus {
					left: 25px;
				}

				.newAPI-button__text {
					left: 51px;
				}
			}
		}
	}
	@media only screen and (max-width: 530px) {
		.newAPI-button {
			&:hover {
				width: 40px;

				.pf-icon-plus {
					left: 14px;
				}

				.newAPI-button__text {
					display: none;
				}
			}
		}
	}
}

.pf-popover__API {
	.pf-popover__API__hr {
		margin: 5px 10px;
		background-color: #eeeff1;
		height: 1px;
	}

	.rs-dropdown-item-content {
		padding: 10px 15px !important;
		display: flex !important;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		transition: background-color linear 0.3s !important;
		&:hover {
			background-color: $hr !important;
		}
		.text {
			font-family: $base-font;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 19px;
			display: flex;
			align-items: center;
			color: $title_color;
			&.delete {
				color: $warning_color;
			}
		}
	}
	.rs-dropdown-menu {
		padding: 0 !important;
	}
	.api-button-icon {
		margin-right: 10px;
	}
	.rs-popover-arrow {
		display: none !important;
	}
}
