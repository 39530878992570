@import '../../../styles/variables/variables';
@import '../../../styles/responsive';

.container-cropper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 300px;
	height: auto;
	margin: 0 auto;

	@include belowSm {
		width: 250px;
		height: auto;
	}
}

.cropper {
	width: 300px;
	height: 300px;
	position: relative;
	margin-bottom: 20px;

	@include belowSm {
		width: 250px;
		height: 250px;
	}

	.reactEasyCrop_Container {
		width: 100%;
		height: 100%;

		.reactEasyCrop_CropArea {
			border: 2px solid #FFFFFF;
			color: rgba(63, 72, 87, 0.5);
		}
	}
}

.slider {
	height: 10%;
	display: flex;
	align-items: center;
	margin: auto;
	width: 100%;

	&__dot {
		color: #3349B5;
	}

	&__range-line {
		color: #8B919B !important;
	}
}