@import '../../../styles/responsive';

.specializationsCellContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	&__content {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
	}

	.specBadge {
		color: $text_color;
		background-color: $secondary_bg_color;

		display: inline-flex;
		justify-content: center;
		align-items: center;
		padding: 6.5px 20px;
		position: relative;
		border-radius: 30px;

		@include belowLg {
			font-size: 12.8px;
		}

		-ms-user-select: none;
		-moz-user-select: none;
		-webkit-user-select: none;
		user-select: none;
	}

	.specDots {
		font-family: $base-font;
		font-style: normal;
		font-weight: 400;
		font-size: 12.8px;
		line-height: 15px;
		display: flex;
		align-items: flex-end;
		margin-left: 5px;

		color: #59657A;
	}
}

.pf-tooltip {
	width: fit-content !important;
	max-width: 461px !important; // fit-content или 461px
	min-width: 340px;
	padding: 10px !important;

	background-color: $white !important;
	border: 1px solid rgba(0, 0, 0, 0.08) !important;
	box-shadow: 0 0 12px rgba(63, 72, 87, 0.1) !important;
	border-radius: 6px !important;

	.specBadge {
		color: $text_color;
		background-color: $secondary_bg_color;

		display: inline-flex;
		justify-content: center;
		align-items: center;
		padding: 6.5px 20px;
		position: relative;
		border-radius: 30px;
		margin: 2.5px;

		@include belowLg {
			font-size: 12.8px;
		}

		-ms-user-select: none;
		-moz-user-select: none;
		-webkit-user-select: none;
		user-select: none;
	}
}
