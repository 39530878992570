@import '../../../../../styles/variables/variables';

.add-button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	border: none;
	outline: none;
	border-radius: 50%;
	background: $primary_color;

	&__icon,
	&__icon div {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 8px;
		height: 8px;

		svg {
			width: 100%;
			height: auto;
		}
	}
}
