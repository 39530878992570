@import '../../../styles/responsive';

.container {
	min-height: 100vh;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	background-color: $main_bg_color;

	@include belowLg {
		background-color: $white;
	}
}

.header {
	z-index: 1;

	padding: 10px 34px;

	@include belowLg {
		padding: 10px 0;

		box-shadow: 0px 0px 12px rgba(63, 72, 87, 0.1);
	}

	> a {
		margin-right: 0;
	}
}
