@import '../../../styles/variables/variables';
@import '../../../styles/responsive';

.tabsBlock {

	.tabs-root {
		transition: margin-right 0.3s ease-in-out;
		@include belowMd {
			margin-right: -20px;
		}
	}

	.tab-page {
		padding: 55px 0 45px;
		margin-bottom: 30px;

		@include belowLg {
			padding: 30px 0 45px;
		}
	}

	.tab-indicator {
		background-color: $primary_color;
	}

	.tab-scroller {
		border-bottom: 1px solid #EEEFF1;
	}

	.tabs-flexContainer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.tab-button {
			min-height: 40px;
			width: 100%;
			min-width: 190px;
			padding: 10px 10px 16px 10px;
			font-family: $secondary-font;
			font-style: normal;
			font-weight: 400;
			font-size: 20px;
			line-height: 25px;
			display: flex;
			align-items: center;
			text-align: center;
			text-transform: capitalize;

			color: rgba(89, 101, 122, 0.7);

			@include belowMd {
				min-width: 150px;
			}

			&__selected {
				font-family: $secondary-font;
				font-weight: 500;
				color: $primary_color !important;
			}
		}
	}
}

