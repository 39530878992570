.pf-view {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.modal-open .pf-view {
	@media screen and (max-width: 465px) {
		height: calc(100vh - 60px);
		overflow-y: hidden;
	}
}
