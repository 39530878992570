@import '../../../../../styles/variables/variables';

.modal_footer {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__buttonsWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto;

    &__clearButton {
      margin-right: 5px;
    }

    button {
      max-height: 40px;
    }
  }
}
