@import '../../../../styles/responsive';

.addNewCompany {
  &__buttons-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 60px;
  }
}

.addNewCompany {
  &__form {

    h4 {
      margin-bottom: 39px;
      font-family: $secondary-font;
      color: $text_color;
      @include belowLg {
        margin-top: 10px;
      }
    }

    &-button {
      width: 150px;
      &--submit {
        margin-left: 15px;
        width: 230px;
        @include belowLg {
          margin-left: 5px;
        }
      }
    }

    .formButton-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 50px;
    }

    //.administratorsSection {
    //  display: flex;
    //  flex-direction: column;
    //  padding: 0;
    //  margin-top: 70px;
    //  @include belowLg {
    //    margin-top: 45px;
    //  }
    //
    //  &>* {
    //    flex-shrink: 0;
    //    width: 100%;
    //    max-width: 100%;
    //    padding-right: calc(var(--bs-gutter-x) * 0.5);
    //    padding-left: calc(var(--bs-gutter-x) * 0.5);
    //    margin-top: var(--bs-gutter-y);
    //  }
    //
    //  .pf-companyProfileEdit {
    //    flex: 0 1 100% !important;
    //    position: relative;
    //
    //    .iconRemove {
    //      svg {
    //        path {
    //          stroke: #F92D4D;
    //        }
    //      }
    //    }
    //
    //    &__addAdminButton, &__assignAsButton {
    //      display: flex;
    //      flex-direction: row;
    //      justify-content: flex-start;
    //      align-items: center;
    //
    //      .addAdminButton, .assignAsButton {
    //        display: flex;
    //        flex-direction: row;
    //        justify-content: flex-start;
    //        align-items: center;
    //        max-width: 235px;
    //        padding: 0;
    //
    //        .pf-button__content {
    //          justify-content: flex-start;
    //        }
    //      }
    //
    //      .assignAsButton {
    //        max-width: 260px;
    //
    //        .pf-button__content {
    //          text-transform: initial;
    //        }
    //      }
    //    }
    //
    //    &__iconAdd {
    //      margin-right: 8px;
    //    }
    //
    //    .accountAdministratorHint {
    //      display: flex;
    //      flex-direction: row;
    //      justify-content: flex-start;
    //      align-items: center;
    //      margin-top: 5px;
    //
    //      &__icon {
    //        width: 10px;
    //        height: 10px;
    //        margin-right: 8px;
    //
    //        span {
    //          display: flex;
    //          justify-content: center;
    //          align-items: center;
    //        }
    //
    //        svg {
    //          path {
    //            fill: #D1D5DB;
    //            fill-opacity: 1;
    //          }
    //        }
    //      }
    //
    //      &__text {
    //        font-family: $base-font;
    //        font-style: normal;
    //        font-weight: 400;
    //        font-size: 12.8px;
    //        line-height: 145%;
    //        color: #8B919B;
    //      }
    //    }
    //  }
    //}
  }
}