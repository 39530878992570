@import '../../../styles/responsive';
@import '../../../styles/variables/variables';

.referredToMe {
	position: relative;

	.pf-tableBar {
		top: 0;

		.dismissRefer-button {
			min-width: 141px;

			@media only screen and (max-width: 991px) {
				min-width: 48px;
			}

			.dismissRefer-button-icon {
				svg {
					circle, path {
						stroke: #F92D4D;
					}
				}
			}
		}
	}

	.inviteNewAdvisorsEmptyPageButton {
		width: fit-content !important;
		min-width: 227px;
		border-radius: 36px;
	}

	.emptyPage__container__wrapper {
		max-width: 500px;

		.emptyPage__header {
			h1, h2 {
				text-align: center;
			}
			h2 {
				margin-bottom: 35px;
				font-weight: 400;
			}
		}
	}


	.referBusiness__emptyPage {
		display: flex;
		flex-direction: column;

		.text {
			margin: 14px 0;
			font-family: $base-font;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 150%;

			color: $text_color;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
		.image {
			width: 100%;
			height: auto;
			max-width: 321px;
		}

	}
}
