@import '../../../styles/variables/variables';

.pfAdditionalCompaniesInfo {
	.btnContainer {
		.dropdown {
			.dropdown-menu.show {
				min-width: 240px;
				padding: 0 !important;
				border-radius: 3px !important;
				overflow: hidden !important;
				border: 1px solid rgba(0, 0, 0, 0.04) !important;
				box-shadow: 0 1px 12px rgba(63, 72, 87, 0.1) !important;
				margin-top: 5px;
			}
		}
	}
}
