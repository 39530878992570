@import '../../../styles/variables/variables';
.pf-input {
	width: 100%;
	&__label {
		color: $text_color;
		&-asterisk {
			color: $warning_color;
			margin-right: 3px;
		}
		&-wrapper {
			font-family: $base-font;
			font-style: normal;
			font-weight: normal;
			font-size: 12.8px;
			line-height: 18px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-bottom: 5px;
		}
	}
	&__field {
		height: 45px;
		width: 100%;
		background: $input_bg_color;
		color: $title_color;
		border-radius: 3px;
		border: 1px solid transparent;
		padding-left: 20px;
		padding-right: 20px;

		font-family: $base-font;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 22px;
		/* identical to box height */

		display: flex;
		align-items: center;

		// deleted arrows for input type number
		-moz-appearance: textfield;
		&::-webkit-inner-spin-button {
			display: none;
		}

		&:focus {
			border: 1px solid $primary_color;
			outline: none;
		}
		&-wrapper {
			width: 100%;
			position: relative;
		}
		&-icon {
			position: absolute;
			right: 20px;
			top: 0;
			bottom: 0;
			margin: auto;
			display: flex;
			align-items: center;
			svg {
				cursor: pointer;
				color: #a4abb7;
			}
		}
	}
	&__hint {
		font-weight: normal;
		width: 100%;
		text-align: left;
		font-size: 12.8px;
		color: $text_color;
		line-height: 1.38;
		margin-bottom: 0 !important;
		margin-top: 3px;
		&--error {
			color: $warning_color;
		}
	}

	// react-tel-input
	.react-tel-input {
		.flag-dropdown.open {
			background-color: transparent !important;
		}
		.selected-flag {
			&:hover,
			&:focus {
				background-color: transparent !important;
			}
		}

		.flag-dropdown {
			border: none;
			background-color: transparent;
		}
		.pf-input__field {
			@extend .pf-input__field;
			padding-left: 50px;
			&:focus {
				box-shadow: none;
			}
		}
	}
	&--error {
		.pf-input {
			&__field {
				background-color: $input_error_bg_color;
				color: $warning_color;
				&:focus {
					border-color: $warning_color;
				}
				&-icon {
					color: $warning_color;
				}
			}
		}
	}
}
