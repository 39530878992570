@import '../../../../styles/responsive';

.rejectLeadModal__form {

  .rejectLeadModal__form {
    overflow: hidden;
    height: 115px;
    opacity: 1;
    transition: all 0.4s;

    &.disabled {
      opacity: 0;
      height: 0;
    }

    textarea {
      width: 100%;
      height: 90px;
      resize: none;
    }
  }
}
