@import '../../styles/responsive';

.deleted-page {

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 45px 125px 0;
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 380px;
    margin: 0 auto;

    img {
      width: 120px;
      height: auto;
      margin-bottom: 20px;
    }

    h1 {
      margin-bottom: 20px;
      font-family: $secondary-font;
      font-style: normal;
      font-weight: 500;
      font-size: 31.25px;
      line-height: 39px;
      text-align: center;
      color: $title_color;
    }

    h2 {
      margin-bottom: 50px;
      font-family: $base-font;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      color: $text_color;
    }
  }

}