@import '../../styles/responsive';

.referPopup {
  padding: 20px !important;

  .referPopup_paper {
    width: 100%;
    max-width: 680px !important;
    min-width: 320px;
    height: 100%;
    max-height: 764px;
    overflow-x: hidden;

    @media only screen and (max-width: 991px) {
      padding: 50px 20px 35px;
    }
  }

  .referPopup_contentBody {
    display: flex;
    flex-direction: column;
  }

  .referPopup_footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .buttonsWrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: auto;

      .referButton {
        margin-left: 5px;
      }

      button {
        max-width: 100%;
        max-height: 40px;
      }
    }
  }

  &_form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}