@import '../../../../styles/variables/fonts';
@import '../../../../styles/variables/colors';

.title {
	margin-bottom: 20px;

	text-align: center;
	font-family: $secondary-font;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;

	color: $title_color;
}

.description {
	text-align: center;
	font-family: $base-font;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;

	color: $text_color;

	&.warning {
		color: $warning_color;
	}
}
