@import '../../styles/responsive';

.clientTagsPopup {
	&__form {
		.footer {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			margin-top: 35px;

			button {
				height: 40px;
			}

			button:not(:last-child) {
				margin-right: 5px;
			}
		}
	}
	&__paper {
		overflow-y: visible !important;
		max-width: 600px !important;
	}
}
