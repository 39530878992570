@import '../../styles/responsive';
@import '../../styles/variables/variables';

.referredToMeModal {
	.descriptionInfo {
		display: flex;
		flex-direction: column;

		.lastViewed {
			margin-bottom: 10px;
			font-family: $base-font;
			font-style: normal;
			font-weight: 400;
			font-size: 12.8px;
			line-height: 15px;

			color: $text_color;
		}

		.referredBy {
			display: flex;
			flex-direction: row;
			width: 100%;

			.text {
				font-family: $base-font;
				font-style: normal;
				font-weight: 400;
				font-size: 12.8px;
				line-height: 15px;

				color: $text_color;
			}
			.referredBy_avatar {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-left: 15px;
				margin-right: 10px;

				.pf-avatar {
					margin: 0;
				}
			}
			.name {
				font-family: $base-font;
				font-style: normal;
				font-weight: 500;
				font-size: 12.8px;
				line-height: 15px;

				color: $primary_color;
			}
		}

	}
}
