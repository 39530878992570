@import '../../../styles/responsive';

.pf-leads-info {
	&__teamWrapper {
		&__title {
			margin-bottom: 40px;
			font-family: $secondary-font;
			font-style: normal;
			font-weight: 500;
			font-size: 12.8px;
			line-height: 16px;

			text-transform: uppercase;

			color: #A4ABB7;
		}
	}

	&__buttonsWrapper {
		margin-bottom: 40px;

		.pf-leads-accept-button {
			margin-bottom: 5px;
		}

		.pf-leads-scheduleMeeting-button {
			margin-top: 15px;

			&:disabled {
				svg {
					path {
						fill: $white;
					}
				}
			}
		}

		.sendScheduleMeetingNote {
			margin-top: 20px;
		}
	}
}