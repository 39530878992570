@import '../../../../styles/responsive';

.advisors {
	//.pf-tableText {
	//	padding-right: 10px;
	//}

	.activeInactiveTitle {
		color: #a4abb7;
		padding: 0;
		font-weight: 400;
		margin-bottom: 0;
		margin-right: 12px;
	}

	.newCompany_button {
		overflow: hidden;
		position: fixed;
		right: 40px;
		bottom: 90px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 50px;
		height: 50px;
		padding: 0 15px;
		background: $secondary_button_color;
		border-radius: 36px;
		color: $title_color;
		transition: width 0.5s ease-out;
		box-shadow: 0 0 12px rgba($title_color, 0.1);

		&:hover {
			width: 210px;
			background: $secondary_button_hover_color;

			.icon_plus {
				left: 40px;
			}

			.newCompany_button_text {
				left: 65px;
				opacity: 1;
			}
		}

		.icon_plus {
			position: absolute;
			left: 19px;
			transition: 0.5s ease-out;

			span {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			svg {
				rect {
					fill: $title_color;
				}
			}
		}

		.newCompany_button_text {
			position: absolute;
			left: 50px;
			width: 120px;
			font-family: $secondary-font;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 23px;
			text-transform: capitalize;
			color: $title_color;
			opacity: 0;
			transition: 0.5s ease-out;
		}
	}

	.tableEmailCell {
		display: flex;
		flex-direction: column;

		.unconfirmedEmail {
			margin: 5px 0 0;
			font-family: $base-font;
			font-style: normal;
			font-weight: 400;
			font-size: 12.8px;
			line-height: 15px;

			color: #F92D4D;
		}
	}

	@media only screen and (max-width: 991px) {
		.newCompany_button {
			width: 40px;
			height: 40px;
			bottom: initial;

			.icon_plus {
				left: 14px;
				margin-right: 0;
			}

			&:hover {
				width: 200px;

				.icon_plus {
					left: 25px;
				}

				.newCompany_button_text {
					left: 51px;
				}
			}
		}
	}

	@media only screen and (max-width: 530px) {
		.newCompany_button {
			&:hover {
				width: 40px;

				.icon_plus {
					left: 14px;
				}

				.newCompany_button_text {
					display: none;
				}
			}
		}
	}

	@media only screen and (max-width: 925px) {
		.pf-tableBar {
			flex-wrap: wrap;
			padding: 0 20px;
			align-items: center;

			.group-buttons {
				width: 100%;
			}

			.pf-button {
				margin: 0 5px 0 0;
			}
		}
	}

	@media only screen and (max-width: 900px) {
		//flex-direction: column;

		.companyInfo {
			width: 100%;
			height: auto;
			padding: 60px 120px;
			flex-direction: row;
		}

		.companyUsers {
			width: 100%;
			padding: 0px 74px;
		}
	}

	@media only screen and (max-width: 784px) {
		.pf-tableBar .pf-close-button {
			margin-top: 0;
		}
	}

	@media only screen and (max-width: 569px) {
		flex-direction: column;

		.companyInfo {
			padding: 10px 20px;
		}

		.companyUsers {
			padding: 0;
		}

		.pf-close-button {
			order: 1;
		}
		.pf-splitButton {
			order: 3;
		}
		.group-buttons {
			order: 2;
		}

		.pf-tableBar .pf-consumer-delete-button {
			min-width: 100px !important;
		}

		.pf-tableBar .pf-consumer-block-button {
			min-width: 200px !important;
		}

		.consumerInfoBtn {
			width: 250px !important;
		}

		.pf-tableBar {
			align-items: flex-end;
		}
	}
}

@media only screen and (max-width: 360px) {
	.rs-table-cell:first-child,
	.rs-table-cell-header:first-child {
		width: 60px !important;
		min-width: 60px !important;
	}
	.rs-table-cell-first .rs-table-cell-content {
		width: 60px !important;
		min-width: 60px !important;
	}

	.companyProfile .consumerInfoBtn {
		width: 200px !important;
	}

	.rs-table-word-wrap .rs-table-cell-content {
		word-break: break-word !important;
	}

	.companyProfile .pf-tableBar {
		flex-wrap: wrap;
		padding: 0 10px;
	}
}

.pf-confirmIsActiveUserModal,
.pf-confirmChangeCompany {
	.modal-title {
		text-align: center;
	}

	.pf-IsActive__form {
		.row > div {
			margin-bottom: 35px !important;
		}
		.pf-radio-group {
			flex-direction: column;
			flex-wrap: nowrap;
		}
	}

	.modal-header {
		padding: 50px 35px 35px;
	}
	.modal-body {
		padding: 0 35px 35px;

		label:not(:last-of-type) {
			margin-bottom: 20px;
		}

		.pf-radio-group__hint {
			display: none;
		}
	}
}
