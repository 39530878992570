@import '../../../styles/responsive';
@import '../../../styles/variables/variables';

.opportunitiesData {
  width: 100%;

  .empty {
    font-family: $base-font;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color:$text_color;
  }

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;

    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 14px;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &-text {
      font-family: $base-font;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $text_color;
    }
  }

  &__viewAllButton {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    height: 40px;
    background-color: transparent;
    margin-top: 35px;

    button {
      padding: 0;
      font-family: $base-font;
      font-style: normal;
      font-weight: 500;
      font-size: 12.8px;
      line-height: 15px;
      display: flex;
      align-items: center;
      background-color: transparent;
      color: $primary_color;

      svg {
        margin-left: 10px;
        color: #8b919b;
        path {
          stroke: $primary_color;
        }
      }
    }
  }
}
