@import '../../../../../styles/variables/variables';

.insurance-dropdown-button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	border-radius: 3px;
	border: none;
	background-color: transparent;

	#bg-nested-dropdown.dropdown-toggle {
		position: absolute;
		width: 100%;
		height: 100%;
		padding: 0;
		margin: 0 !important;
		background-color: transparent;
		transition: none;

		div {
			background-color: transparent;
		}
	}

	.dropdown-menu {
		padding: 0;
		margin-top: 7px;
		width: 100%;
		min-width: 220px;
		max-width: 220px;
		border-radius: 3px;
		border: none;
		background-color: #ffffff !important;
		box-shadow: 0 1px 12px rgba(63, 72, 87, 0.1);

		.dropdown-item {
			font-family: $base-font;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 19px;

			color: #59657a;
		}
	}
}
