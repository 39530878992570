@import '../../../styles/variables/variables';
@import '../../../styles/responsive';

.pf-contact {
	font-size: 16px;
	text-align: center;

	&__links {
		padding-bottom: 32px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 32px;

		span {
			color: $text_color;
		}
	}
	&__link {
		text-decoration: none;
		font-weight: 500 !important;
		font-family: $base-font;
		font-style: normal;
		font-size: 16px;
		line-height: 19px !important;
		margin-left: 5px;
		color: #3349b5 !important;
		word-break: break-word;
		&:hover {
			text-decoration: underline !important;
		}
	}
}
