@import '../../../../../styles/variables/variables';

.container {
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 12px 0;
}

.icon {
	flex-shrink: 0;

	height: 24px;
	width: 24px;

	margin-right: 18px;

	svg {
		width: 100%;
		height: auto;
	}
}

.infoContainer {
	display: flex;
	justify-content: space-between;

	flex-grow: 1;

	font-family: $base-font;
	font-style: normal;

	> div:nth-child(2) {
		flex-shrink: 0;

		margin-left: 15px;
	}

	.title,
	.amount {
		font-weight: 500;
		font-size: 16px;
		line-height: 150%;

		color: $title_color;
	}
	.amount {
		text-align: right;
	}

	.company,
	.date {
		margin-top: 5px;

		font-weight: 400;
		font-size: 12.8px;
		line-height: 15px;

		color: $placeholder_color;
	}
	.company {
		word-break: break-all;
	}
	.date {
		text-align: right;

		&Value {
			margin-left: 6px;

			color: $approval_color;
		}
	}
}

.actions {
	display: flex;
	justify-content: center;
	align-items: center;

	margin-left: 18px;

	background-color: white;
}
