@import '../../../styles/responsive';

.pf-sidebar {
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 200px;
	min-width: 200px;
	height: 100vh;
	padding: 80px 0 10px;
	background-color: $white;
	box-shadow: 0 0 12px rgba($title_color, 0.1);
	transition: width 0.5s, min-width 0.5s;

	//height: 100%;

	overflow-y: auto !important;
	overflow-x: hidden !important;

	scrollbar-color: rgba(87, 87, 87, 0.6) rgba(229, 229, 234) !important;
	scrollbar-width: thin !important;
	&::-webkit-scrollbar {
		width: 8px;
		background-color: rgba(229, 229, 234) !important;
		border-radius: 9px !important;
	}
	&::-webkit-scrollbar-thumb {
		background-color: rgba(87, 87, 87, 0.6) !important;
		border-radius: 9px !important;
	}
	&::-webkit-scrollbar-thumb:hover {
		background-color: rgba(87, 87, 87, 0.6) !important;
	}

	@include belowXL {
		position: fixed;
		top: 0;
		bottom: 0;
		z-index: 1150;
		left: 0;
		transition: transform 0.3s;
		width: 280px;
		overflow: auto;
		transform: translateX(-100%);
	}
	&__header {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding: 10px 15px;
	}
	&__close-button {
		&.pf-button {
			padding: 0;
			width: 32px;
			height: 32px;
			.pf-button {
				&__icon svg {
					width: 14px;
					path {
						stroke: $line_color;
					}
				}
			}
		}
	}
	&__container {
		padding-bottom: 35px;
	}

	.banner__hr {
		margin: 10px 0 !important;
		border-top: solid 1px $hr;
		height: 0 !important;
	}

	&__profile {
		.banner {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 25px 30px;

			.banner-main-wrapper {
				width: 100%;
				border-radius: 6px;
				overflow: hidden;
				background-size: cover;
				background-repeat: no-repeat;
			}
			.banner-main {
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				padding: 13px 16px;
				background: rgba(51, 73, 181, 0.2);
				backdrop-filter: blur(20px);

				.banner-title {
					font-family: $secondary-font;
					font-style: normal;
					font-weight: 500;
					font-size: 12.8px;
					line-height: 16px;
					text-transform: uppercase;
					color: $white;
					opacity: 0.7;
					margin-bottom: 3px;
				}

				.eduHab {
					span {
						font-family: $secondary-font;
						font-style: normal;
						font-weight: 500;
						font-size: 20px;
						line-height: 25px;
						color: $white;
					}
				}
			}
		}

		&-item {
			cursor: pointer;
			padding: 12px 0 12px 30px;
			color: $text_color;
			font-size: 16px;
			line-height: 22px;

			&.disabled {
				pointer-events: none;
				&:hover {
					background-color: initial;
				}
			}

			&--header {
				cursor: default;
				display: flex;
				padding-left: 30px;
				padding-top: 40px;
				padding-bottom: 25px;
				color: $title_color;
				font-weight: 600;
				border-top: 1px solid rgba($secondary_bg_color, 0.1);

				.pf-avatar__wrapper {
					margin-right: 16px;
				}

				&:hover {
					background-color: $white !important;
				}
			}

			&-edit {
				margin-top: 50px;
			}

			&:hover {
				background-color: $main_bg_color;
			}

			.shareProfileLink {
				svg {
					path {
						fill: #3F4857;
					}
				}
			}
		}
	}
	&--collapsed {
		@include belowXL {
			transform: translateX(0%);
		}
	}

	.pf-navigation__item {
		max-width: initial;
	}

	.dropdown-footer {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		padding: 30px;

		.dropdownItem span {
			margin-right: 20px;
			margin-left: 0;
			font-family: $base-font;
			font-style: normal;
			font-weight: 400;
			font-size: 12.8px;
			line-height: 15px;
			color: $line_color !important;
		}
	}
}

.upgrade-action-wrapper {
	margin-left: 8px;
	margin-right: 8px;
	border-radius: 6px;
	margin-bottom: 15px;
}

.upgrade-action-button {
	margin: unset !important;
	background: rgba(51, 73, 181, 0.3) !important;
	backdrop-filter: blur(15px) !important;
}

.dropdown-user-section {
	display: flex !important;

	.logout-icon {
		margin-left: auto;
		cursor: pointer;
	}
}

.pro-email-signature {
	margin-top: 10px;
	max-width: 93%;
    margin-left: 10px;

	@include belowXL {
		padding-left: 10px;
		padding-right: 10px;
	}
}

.french-village-community {
	margin-top: 15px;
	max-width: 93%;
    margin-left: 10px;

	@include belowXL {
		padding-left: 10px;
		padding-right: 10px;
	}
}
