@import '../../../styles/responsive';

.pf-driver-root {
	position: relative;
	top: initial !important;
	left: initial !important;

	.MuiBackdrop-root {
		background-color: rgba(63, 72, 87, 0.3);
	}

	&__paper {
		width: 100%;
		max-width: 465px;
		padding: 104px 45px 60px 60px;
		box-shadow: 0 0 12px rgba(63, 72, 87, 0.1) !important;

		@include belowSm {
			top: 10% !important;
			max-width: 100%;
			border-radius: 12px 12px 0 0;
			padding: 50px 30px 30px;
		}
	}

	&__closeButton {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: transparent;
		position: absolute;
		top: 40px;
		right: 40px;

		&.bottom {
			width: 50px;
			height: 4px;
			left: calc(50% - 50px/2);
			top: 10px;
			right: initial;

			background: #EAEAEB;
			border-radius: 50px;
		}

		div {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		svg {
			path {
				stroke: #D1D5DB;
				transition: stroke 0.2s;
			}
		}

		&:hover {
			svg {
				path {
					stroke: $primary_color;
				}
			}
		}
	}
}