@import '../../../styles/variables/variables';

.pf-tile {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 6.5px 15px;
    position: relative;
    border-radius: 30px;
    margin: 2.5px;
    width: -moz-fit-content;
    width: fit-content;

    &.primary{
        background-color: $primary_color !important;
        color: $white;
    }

    .pf-tile__remove-icon {
        padding-left: 5px;
        transform: scale(0.7);
    }
}