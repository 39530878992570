@import '../../../../../styles/responsive';

.recommendModal {
  padding: 20px !important;

  .recommendModal_paper {
    width: 100%;
    max-width: 870px !important;
    min-width: 320px;
    height: 100%;
    max-height: 713px;

    @media only screen and (max-width: 991px) {
      padding: 50px 20px 35px;
    }
  }

  .recommendModal_contentBody {
    display: flex;
    flex-direction: column;
  }

  .recommendModal_main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 35px;

    @media only screen and (max-width: 991px) {
      margin-bottom: 30px;
    }

    @include belowMd {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .searchSection {
      margin-bottom: 35px;

      @media only screen and (max-width: 991px) {
        margin-bottom: 18px;
      }
    }

    .searchResultSection {
      position: relative;
      display: flex;
      margin-bottom: 35px;
      flex-grow: 1;
      border-bottom: 1px solid #EEEFF1;

      @media only screen and (max-width: 991px) {
        margin-bottom: 30px;
      }

      div[data-test-id='virtuoso-scroller'] {
        /* width */
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
          transition: width 0.3s ease-in-out;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background-color: transparent;
          border-radius: 8px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background-color: transparent;
          border-radius: 8px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background-color: transparent;
        }
      }

      div[data-test-id='virtuoso-scroller']:hover {
        /* Track */
        &::-webkit-scrollbar-track {
          background-color: rgba($placeholder_color, 0.1);
          border-radius: 8px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background-color: rgba($placeholder_color, 0.3);
          border-radius: 8px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background-color: rgba($placeholder_color, 0.5);
        }
      }

      .loadingWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }
    }

    .searchActionsSection {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .selectedCount {
        font-family: $base-font;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;

        color: #A4ABB7;
      }

      .clearAllButton {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        outline: none;
        background-color: transparent;

        &__text {
          font-family: $base-font;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;

          color: $primary_color;
        }

        &__icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 16px;
          height: 16px;
          margin-right: 10px;

          span {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          svg {
            width: 100%;
            height: auto;

            path {
              stroke: $primary_color;
            }
          }
        }
      }
    }
  }

  .recommendModal_footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .buttonsWrapper {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin: auto;

      .recommendButton {
        margin-left: 5px;
      }

      button {
        max-width: 207px;
        max-height: 40px;
      }
    }
  }
}

.virtuoso__loadMore {
  display: flex;
  justify-content: center;
  align-items: center;

  &_icon {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg {
      width: 50px;
    }
  }
}

.virtuosoCurrentItem {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 15px 0;

  @include belowMd {
    padding: 10px 0;
  }

  &__checkbox {
    margin-right: 10px;
    min-height: initial !important;
  }
  &__avatar {
    margin-right: 15px;
  }
  &__name {
    font-family: $base-font;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $text_color;
  }
}

.emptyPage {

  &__container {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10px;
  }

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    h2 {
      margin-bottom: 50px;
      font-family: $base-font;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      white-space: pre-line;

      color: $text_color;
    }
  }

}