@import '../../styles/responsive';

.pf-profileEdit {
	&__form {
		padding-bottom: 50px;
		width: 700px;
		max-width: 100%;

		h4 {
			margin-top: 60px;
			margin-bottom: 37px;
			font-family: $secondary-font;
			color: $text_color;
			@include belowLg {
				margin-top: 10px;
			}
		}
		&-button {
			width: 150px;
			&--submit {
				margin-left: 15px;
				width: 230px;
				@include belowLg {
					margin-left: 5px;
				}
			}
		}

		.inputHint {
			margin-top: 5px;
			font-family: $base-font;
			font-style: normal;
			font-weight: 400;
			font-size: 12.8px;
			line-height: 15px;
			display: flex;
			align-items: center;

			color: $text_color;

			opacity: 0.7;
		}

		.reasonInactive {
			height: 50px;
			opacity: 1;
			transition: all 0.4s;
			&.disabled {
				opacity: 0;
				height: 0;
			}
		}

		.tabsContainer__label {
			position: relative;
			&.alertTab {
				&:before {
					content: '';
					position: absolute;
					width: 6px;
					height: 6px;
					border-radius: 50%;
					top: 0;
					right: -9.5px;
					background-color: #f92d4d;
				}
			}
		}

		.inputSelectCompany {
			display: flex;
			flex-direction: row;
			align-items: flex-end;

			&__companyLogo {
				margin-right: 5px;
			}

			&.disabled {
				opacity: 0.5;
			}
		}

		.anotherCompany {
			height: 280px;
			opacity: 1;
			transition: all 0.4s;

			&.disabled {
				overflow: hidden;
				height: 0;
				opacity: 0;
			}
		}
	}
}
