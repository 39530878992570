@import '../../../styles/variables/variables';
.pf-textarea {
	width: 100%;
	&__label {
		color: $text_color;
		&-asterisk {
			color: $warning_color;
			margin-right: 3px;
		}
		&-wrapper {
			font-family: $base-font;
			font-style: normal;
			font-weight: normal;
			font-size: 12.8px;
			line-height: 18px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-bottom: 5px;
		}
	}
	&__field {
		min-height: 90px;
		width: 100%;
		background: $input_bg_color;
		color: $title_color;
		border-radius: 3px;
		border: 1px solid transparent;
		padding: 12px 20px;

		font-family: $base-font;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 22px;
		/* identical to box height */

		display: flex;
		align-items: center;
		&:focus {
			border: 1px solid $primary_color;
			outline: none;
		}
		&-wrapper {
			width: 100%;
			position: relative;
		}
		&-icon {
			position: absolute;
			right: 20px;
			top: 0;
			bottom: 0;
			margin: auto;
			display: flex;
			align-items: center;
			svg {
				cursor: pointer;
			}
		}
	}
	&__hint {
		font-weight: normal;
		width: 100%;
		text-align: left;
		font-size: 12.8px;
		color: $text_color;
		line-height: 1.38;
		&--error {
			margin-top: 3px;
			color: $warning_color;
		}
	}
	&--error {
		.pf-textarea {
			&__field {
				background-color: $input_error_bg_color;
				color: $warning_color;
				&:focus {
					border-color: $warning_color;
				}
				&-icon {
					color: $warning_color;
				}
			}
		}
	}
}
