@import '../../../styles/responsive';
@import '../../../styles/variables/variables';

.consumerInfoBtn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 70px;
	height: 40px;
	border: none;
	border-radius: 3px;

	& span {
		margin-left: 7px;
		font-family: $secondary-font;
		font-size: 16px;
		line-height: 23px;
		text-transform: uppercase;
		color: $primary_color;
	}

	&:not(:last-of-type) {
		margin-right: 10px;
	}

	&:first-of-type {
		width: 280px;
		height: 40px;
		background-color: $secondary_bg_color;

		&:hover {
			background-color: $main_bg_color;
		}
	}
}

.pfAdditionalInfo {
	.btnContainer {
		display: flex;
		margin-bottom: 50px;

		hr {
			color: $hr;
			margin: 5px 10px;
		}

		.dropdownItemText {
			&__block,
			&__delete {
				display: flex;
				align-items: center;

				span {
					margin-left: 10px;
					font-family: $base-font;
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 22px;
				}
			}

			&__block {
				margin-left: -4px;

				svg {
					path,
					circle {
						stroke: $title_color;
					}
				}

				span {
					color: $title_color;
				}
			}

			&__delete {
				span {
					color: $warning_color;
				}

				svg {
					path {
						stroke: $warning_color !important;
					}
				}
			}
		}
	}

	.pfLeadsInfoDescription {
		display: flex;
		flex-direction: column;
		color: $text_color;
		margin-bottom: 20px;

		& span:first-child {
			font-family: $base-font;
			font-style: normal;
			font-weight: 500;
			font-size: 12.8px;
			line-height: 18px;
			color: $placeholder_color;
			margin-bottom: 5px;
		}

		& span:last-child {
			font-family: $base-font;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 22px;
			color: $text_color;
		}
	}

	.pfLeadsInfoTeam {
		padding: 0;
		margin-bottom: 50px;

		& li {
			display: flex;
			align-items: center;
			color: $text_color;
			list-style-type: none;

			& > div {
				margin-right: 15px;
			}

			&:not(:last-of-type) {
				margin-bottom: 10px;
			}
		}
	}

	.additionalInfoWrapper {
		margin-bottom: 50px;

		p {
			font-family: $base-font;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 22px;
			color: $text_color;

			span:first-child {
				font-weight: 600;
			}
			span:last-child {
				margin-left: 10px;
				opacity: 0.5;
			}
		}
	}

	.additionalInfoTitle {
		margin-bottom: 25px;
		font-family: $secondary-font;
		font-weight: 500;
		font-size: 13px;
		line-height: 25px;
		text-transform: uppercase;
		color: $placeholder_color;
	}
}

.mobile_hidden {
	margin-left: 0 !important;
}

@media only screen and (max-width: 569px) {
	.mobile_hidden {
		display: none;
	}
}
