@import '../../../styles/variables/variables';
@import '../../../styles/responsive';

.pf_user_description {
	display: flex;
	align-items: center;
	margin-bottom: 40px;

	@include belowLg {
		margin-bottom: 30px;
	}

	.companyLogo {
		margin-right: 25px;
	}

	.label {
		margin: 10px 0px;
		font-family: $secondary-font;
		font-weight: 600;
		font-size: 25px;
		line-height: 125%;
		color: $title_color;
		word-break: break-word;
	}

	.description {
		font-size: 13px;
		line-height: 18px;
		color: $text_color;
	}
}
