@import '../../../../styles/responsive';

.referPopup-inputGroup {
    height: 58px;
    overflow: hidden;
    border-radius: 3px !important;
    transition: none !important;
    border: none !important;
    outline: none !important;

    &:focus-within, &:hover {
        outline: none !important;
        border: none !important;
    }

    @include belowMd {
        height: 45px !important;
    }


    .rs-input {
        height: 60px !important;
        padding-left: 25px;
        padding-right: 50px !important;
        border-radius: 0;
        border: none !important;
        outline: none !important;
        background-color: #f2f2f2 !important;
        color: $title_color;
        caret-color: $primary_color;

        &:focus-within, &:hover {
            outline: none !important;
            border: none !important;
        }

        @include belowMd {
            height: 45px !important;
        }
    }

    .referPopup-inputGroup__addon {
        cursor: pointer;
        right: 23px !important;
        top: calc(50% - 16px / 2 - 1px) !important;
        width: 16px !important;
        height: 16px !important;
        padding: 0 0 0 1px !important;

        span {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        svg {
            width: 100%;
            height: auto;

            path {
                stroke: #8B919B;
            }
        }

        &:hover {
            background-color: transparent !important;

            svg {
                path {
                    stroke: $primary_color;
                }
            }
        }
    }
}