@import '../../../styles/responsive';

.infoTooltip {
	max-width: 260px !important;
	padding: 18px 20px 18px 30px !important;
	background: rgba(63, 72, 87, 0.9) !important;
	box-shadow: 0 0 12px rgba(63, 72, 87, 0.1);
	backdrop-filter: blur(25px);
	border-radius: 6px  !important;
	z-index: 1201 !important;
	text-align: left !important;

	font-family: $base-font;
	font-style: normal;
	font-weight: 400;
	font-size: 16px !important;
	line-height: 150% !important;
	color: #FFFFFF  !important;

	&.placement-bottom-end, &.placement-bottom-start {
		&:after {
			border-bottom-color: rgba(63, 72, 87, 0.9) !important;
		}
	}
	&.placement-top-end, &.placement-top-start {
		&:after {
			border-top-color: rgba(63, 72, 87, 0.9) !important;
		}
	}
	&.placement-left-start, &.placement-left-end {
		&:after {
			border-left-color: rgba(63, 72, 87, 0.9) !important;
		}
	}
	&.placement-right-start, &.placement-right-end {
		&:after {
			border-right-color: rgba(63, 72, 87, 0.9) !important;
		}
	}
}

.infoTooltip-clickAria {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;

	&__icon {
		display: flex;
		justify-content: center;
		align-items: center;

		div {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}