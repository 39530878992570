@import '../../styles/responsive';

.restore {
  overflow: hidden;
  width: initial;
  font-family: $base-font;
  font-style: normal;
  font-weight: 500;
  font-size: 12.8px;
  line-height: 15px;
  color: $primary_color;
  height: 0;
  transition: all 0.4s;
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  text-align: left;

  &.deleted {
    height: 15px;
    margin-top: 6px;
  }
}