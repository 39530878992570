@import '../../../styles/responsive';

.leadsPage {
	.scheduleMeetingButton {
		max-width: fit-content;
		margin-left: 15px;

		&:disabled {
			svg {
				path {
					fill: $white;
				}
			}
		}

		@media only screen and (max-width: 991px) {
			padding: 0;
			min-width: 48px;

			.pf-button__icon--prefix {
				padding: 0;
			}

			.button-text {
				display: none;
			}
		}
	}
}

.beforeTableHeader {
	position: relative;

	.tableBarOverride {
		top: 42px !important;

		@media only screen and (max-width: 576px) {
			top: 72px !important;
		}

		@media only screen and (max-width: 530px) {
			top: 92px !important;
		}
	}
}

@media only screen and (max-width: 360px) {
	.rs-table-cell:first-child,
	.rs-table-cell-header:first-child {
		width: 60px !important;
		min-width: 60px !important;
	}
	.rs-table-cell-first .rs-table-cell-content {
		width: 60px !important;
		min-width: 60px !important;
	}
}
