@import '../../../styles/responsive';

/*
 TODO: Used "! Important" because the "@rsuite" library, when using "Virtualized", does not allow setting the width as a percentage
*/

//.rs-table-row {
//  .rs-table-cell:first-child, .rs-table-cell-header:first-child {
//    min-width: 100px !important;
//  }
//  .rs-table-cell:nth-child(2), .rs-table-cell-header:nth-child(2) {
//    width: 100% !important;
//    min-width: 215px;
//    max-width: 16.75% !important;
//    padding-left: 16px;
//    padding-right: 16px;
//    box-sizing: content-box;
//  }
//  .rs-table-cell:nth-child(3), .rs-table-cell-header:nth-child(3) {
//    width: 100% !important;
//    min-width: 180px !important;
//    max-width: 25% !important;
//    padding-left: 16px;
//    padding-right: 16px;
//    box-sizing: content-box;
//  }
//  .rs-table-cell:nth-child(4), .rs-table-cell-header:nth-child(4) {
//    width: 100% !important;
//    min-width: 160px !important;
//    max-width: 18.3% !important;
//    padding-left: 16px;
//    padding-right: 16px;
//    box-sizing: content-box;
//  }
//  .rs-table-cell:nth-child(5), .rs-table-cell-header:nth-child(5) {
//    width: 100% !important;
//    min-width: 90px !important;
//    max-width: 11.5% !important;
//    padding-left: 16px;
//    padding-right: 16px;
//    box-sizing: content-box;
//  }
//  .rs-table-cell:nth-child(6), .rs-table-cell-header:nth-child(6) {
//    width: 100% !important;
//    min-width: 130px !important;
//    max-width: 15.65% !important;
//    padding-left: 16px;
//    padding-right: 16px;
//    box-sizing: content-box;
//  }
//}
//
//.rs-table-cell-group.rs-table-cell-group-scroll {
//  position: relative;
//  display: flex;
//}
//
//.rs-table-cell {
//  position: static !important;
//  table-layout: initial !important;
//}
//
//.rs-checkbox-inline {
//  margin-left: 0 !important;
//}
//
//.rs-table-cell-content {
//  padding: 0 !important;
//}

@media only screen and (max-width: 360px) {
	.rs-table-cell:first-child,
	.rs-table-cell-header:first-child {
		width: 60px !important;
		min-width: 60px !important;
	}
	.rs-table-cell-first .rs-table-cell-content {
		width: 60px !important;
		min-width: 60px !important;
	}
}

.scheduleMeetingButton {
	max-width: fit-content;
	margin-left: 15px;

	&:disabled {
		svg {
			path {
				fill: $white;
			}
		}
	}

	@media only screen and (max-width: 991px) {
		padding: 0;
		min-width: 48px;

		.pf-button__icon--prefix {
			padding: 0;
		}

		.button-text {
			display: none;
		}
	}
}

.pf-icon-quit-from-team {
	svg {
		path:nth-child(1) {
			fill: $white;
		}
		path:nth-child(2) {
			stroke: $white;
		}
	}
}
