@import '../../../styles/responsive';

.logo {
	margin-right: 34px;

	@include belowLg {
		display: flex;
		justify-content: center;
		width: auto;
	}
	&__img {
		width: 40px;
		height: 40px;
	}
}
.sidebar .logo__img,
.sidebar svg {
	width: 30px;
	height: 30px;
}
