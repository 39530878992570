@import '../../styles/responsive';
@import '../../styles/variables/variables';

.pf-profileForm {
  background-color: $white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include belowLg {
    background-color: $white;
  }

  &__container {
    width: 1000px;
    max-width: 100%;
    padding: 60px 100px 70px;
    margin-left: auto;
    margin-right: auto;
    @include belowLg {
      max-width: 100%;
      padding: 20px 20px 50px;
    }
  }

  &__wrapper {
    background-color: $white;

    padding: 0 70px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 6px;
    @include belowLg {
      background-color: transparent;
      padding: 0;
      width: 100%;
      max-width: 700px;
      margin: 0 auto 40px;
    }
  }

  &__head {
    width: 100%;
    margin-bottom: 30px;

    @include belowLg {
      width: 100%;
      max-width: 700px;
      margin: 0 auto 40px;
    }
    .goBack-button {
      width: auto;
      padding: 0;

      span {
        width: initial;
      }
    }
  }

  &__title {
    font-family: $secondary-font;
    font-style: normal;
    font-weight: 600;
    font-size: 31.25px;
    color: $title_color;
    width: 100%;
    text-align: left;
    margin-bottom: 65px;
    @include belowLg {
      margin-bottom: 43px;
    }
  }

  .pf-copyright {
    padding-top: 30px;
  }
}
