@import '../../styles/variables/variables';
@import '../../styles/responsive';

.completeSignUp {
	background-color: $main_bg_color;
	display: flex;
	flex-direction: column;

	@include belowLg {
		background-color: $white;
		.pf-header {
			justify-content: center;
			box-shadow: 0px 0px 12px rgba(63, 72, 87, 0.1);
			&__label,
			&__user {
				display: none;
			}
		}
	}
	&__backButton {
		margin-bottom: 20px;
		padding: 0;
		height: initial;
		@include belowLg {
			margin-top: 20px;
			margin-left: 23px;
		}
	}
	&__container {
		display: flex;
		flex: 1;
		max-width: 500px;
		width: 100%;

		margin: auto;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
	}
	&__card {
		width: 100%;
		padding: 60px 70px;
		border-radius: 6px;
		background-color: $white;
		color: $text_color;
		@include belowLg {
			padding-left: 35px;
			padding-right: 35px;
		}
		.pf-input__error {
			margin-bottom: 23px;
		}
	}
	&__icon {
		text-align: center;
		color: $secondary_color;
		padding-bottom: 15px;
		&--fail {
			color: $warning_color;
		}
	}
	&__title {
		font-family: $secondary-font;
		font-style: normal;
		font-weight: 500;
		line-height: 29px;
		color: $title_color;
		text-align: center;
		margin-bottom: 15px;
	}
	&___text {
		font-family: $base-font;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 150%;
		text-align: center;
		color: $text_color;
		span {
			font-weight: 600;
		}
	}
	&__title,
	&___text {
		padding: 0 40px;

		@include belowLg {
			padding: 0;
			text-align: center;
		}
	}

	&__button {
		margin: 30px 0 36px;
		max-width: 100%;
	}

	&__footer-text {
		font-family: $base-font;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		text-align: center;
		color: $text_color;
		span {
			cursor: pointer;
			font-weight: 500;
			margin-left: 5px;
			color: $primary_color;
		}
	}

	&__footer-link {
		cursor: pointer;
		text-decoration: none;
		font-weight: 500 !important;
		font-family: 'Inter', sans-serif;
		font-style: normal;
		font-size: 16px;
		line-height: 19px !important;
		margin-left: 5px;
		color: #3349b5 !important;
		font-weight: 500;
		word-break: break-word;

		&-disabled {
			cursor: default;
			color: #a4abb7 !important;

			&:hover {
				text-decoration: none;
			}
		}
	}

	&__footer-resend-bloc {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
	}
}

.start-button {
	width: 300px;
	margin: 0 auto;
}
