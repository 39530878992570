@import '../../../../styles/variables/variables';

.referPopup__textarea {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .pf-textarea__field-wrapper {
        display: flex;
        flex-grow: 1;
    }
}