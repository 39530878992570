@import '../../../../styles/responsive';

.addNewAPIInstance {
	.pf-profileEdit__form-title {
		margin-bottom: 67px;
	}

	.instance-key {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 53px;

		.icon-copy {
			cursor: pointer;
			width: 16px;
			height: auto;
			margin: 0 14px 0 0;
		}

		&__text {
			cursor: pointer;
			font-family: $base-font;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 19px;
			color: #59657a;
		}
	}

	&__buttons-wrapper {
		display: flex;
		justify-content: flex-end;
		margin-top: 60px;
	}
}
