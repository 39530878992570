@import '../../../styles/variables/variables';
@import '../../../styles/responsive';

$maxWidth: 1920;
@mixin adaptiv-font($pcSize, $mobSize) {
	$addSize: $pcSize - $mobSize;
	$maxWidth: $maxWidth - 360;
	font-size: calc(#{$mobSize + px} + #{$addSize} * ((100vw - 360px) / #{$maxWidth}));
}

.pf-invitation-page {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 2.7% 6.5% 2.1%;
	height: 100%;

	@media only screen and (max-width: 1200px) {
		padding: 4.2% 3.4% 2.1%;
	}

	@media only screen and (max-width: 820px) {
		padding: 20px 20px;
	}

	.pf-invitation-main {
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 50px;
		height: 750px;
		width: 100%;
		border-radius: 24px;
		background-size: cover;
		box-shadow: 0 30px 40px -30px #293ba1eb;
		overflow: auto;

		@media only screen and (max-width: 820px) {
			height: 510px;
		}

		@media only screen and (max-width: 640px) {
			flex-direction: column;
			height: 720px;
			overflow: hidden;
			margin-bottom: 45px;
		}

		.pf-invitation-logo {
			position: absolute;
			top: 6%;
			right: 3%;
			width: 3.6%;
			height: 8%;
			min-width: 50px;
			min-height: 50px;

			@media only screen and (max-width: 640px) {
				position: initial;
				margin: 60px 0 25px;
			}
		}

		.main-mobileImg {
			position: relative;
			width: 35%;
			height: 100%;
			overflow: hidden;

			@media only screen and (max-width: 1200px) {
				width: 40%;
			}

			@media only screen and (max-width: 820px) {
				width: 54%;
			}


			img {
				position: absolute;
				top: 13.4%;
				right: 0;
				width: 71%;

				@media only screen and (max-width: 1200px) {
					width: 90.3%;
				}

				@media only screen and (max-width: 820px) {
					width: 83.6%;
					top: 50px;
				}

				@media only screen and (max-width: 640px) {
					top: 38px;
					width: 233px;
					right: initial;
					margin: 0 auto;
				}
			}

			@media only screen and (max-width: 640px) {
				display: flex;
				justify-content: center;
				width: 100%;
				min-height: 250px;
				order: 2;
			}
		}

		.main-text {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			width: 65%;
			height: 100%;
			padding: 0 9%;

			@media only screen and (max-width: 1200px) {
				padding: 0 7%;
			}

			@media only screen and (max-width: 820px) {
				padding: 0 17% 0 7%;
			}

			@media only screen and (max-width: 640px) {
				width: 100%;
				align-items: center;
				text-align: center;
				padding: 0 11%;
				order: 1;
			}

			h1 {
				font-family: $secondary-font;
				font-style: normal;
				font-weight: 500;
				font-size: 61.04px;
				line-height: 110%;
				text-transform: uppercase;
				color: $secondary_hover_color;
				margin-bottom: 0;
				letter-spacing: 3px;

				@media only screen and (max-width: 1000px) {
					@include adaptiv-font(61.04, 31.25);
				}

				@media only screen and (max-width: 820px) {
					font-size: 31.25px;
					margin-bottom: 8px;
				}

				@media only screen and (max-width: 640px) {
					margin-bottom: 8px;
				}
			}

			h2 {
				font-family: $base-font;
				font-style: normal;
				font-weight: 500;
				font-size: 25px;
				line-height: 150%;
				color: $white;
				margin-bottom: 0;

				@media only screen and (max-width: 1000px) {
					@include adaptiv-font(25, 16);
				}

				@media only screen and (max-width: 820px) {
					font-size: 16px;
				}
			}

			p {
				display: inline-block;
				width: 100%;
				max-width: 545px;
				font-family: $base-font;
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 150%;
				color: #eeeff1;
				margin: 3.8% 0 6.1%;

				.yellow-text {
					color: $secondary_hover_color;
					font-weight: 500;
				}

				@media only screen and (max-width: 1000px) {
					@include adaptiv-font(16, 12.8);
				}

				@media only screen and (max-width: 820px) {
					font-size: 12.8px;
					margin: 20px 0 25px;
				}
			}

			.main-appLinks {
				display: flex;
				justify-content: flex-start;
				width: 100%;
				height: 6.3%;

				@media only screen and (max-width: 820px) {
					height: initial;
					justify-content: center;
					align-items: flex-start;
					flex-direction: column;
				}

				@media only screen and (max-width: 640px) {
					align-items: center;
				}

				a {
					height: 100%;
					min-height: 45px;
					max-height: 45px;

					&:first-child {
						margin-bottom: 10px;
						margin-right: 1.4%;

						img {
							max-width: 152px;
						}

						@media only screen and (max-width: 640px) {
							margin-right: initial;
							margin-bottom: 1.4%;
						}
					}

					&:nth-child(2) {
						img {
							max-width: 135px;
						}
					}

					img {
						height: 100%;
					}
				}
			}

			.main-info {
				display: flex;
				justify-content: space-around;
				align-items: center;
				width: 100%;
				max-width: 545px;
				min-height: 10.6%;
				margin-top: 9.2%;
				background-color: rgba(255, 255, 255, 0.2);
				border-radius: 12px;

				.main-info__img {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 14.6%;
					height: 100%;
					padding: 10px;

					img {
						width: 50%;
						max-width: 40px;
						min-width: 20px;
					}
				}

				.main-info__message {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
					padding: 10px;

					font-family: $base-font;
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 150%;
					color: $white;

					@media only screen and (max-width: 1000px) {
						@include adaptiv-font(16, 12.8);
					}

					@media only screen and (max-width: 820px) {
						font-size: 12.8px;
					}

					@media only screen and (max-width: 640px) {
						padding-right: 10px;
					}
				}

				@media only screen and (max-width: 820px) {
					display: none;
				}
			}
		}
	}

	.pf-invitation-footer {
		.footer-learn-more {
			margin-bottom: 45px;
			font-family: $base-font;
			font-style: normal;
			font-weight: normal;
			@include adaptiv-font(20, 16);
			line-height: 24px;
			text-align: center;
			color: $primary_color;

			@media only screen and (max-width: 820px) {
				font-size: 16px;
			}

			@media only screen and (max-width: 640px) {
				margin-bottom: 40px;
			}

			.blue-text {
				text-decoration: none;
				color: $primary_color;

				span {
					font-weight: 600;
				}
			}
		}

		.footer-socialLinks {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			margin-bottom: 20px;

			a {
				margin: 0 0.9%;
				width: 45px;

				&:not(:last-child) {
					margin-right: 20px;
				}

				svg {
					g {
						path {
							transition-duration: 0.5s;
							transition-property: fill;
						}
					}
				}

				&:first-child {
					margin-left: 0;
				}

				&:last-child {
					margin-right: 0;
				}

				&:hover {
					svg {
						g {
							opacity: 1;
							path {
								fill: $primary_color;
							}
						}
					}
				}
			}
		}

		.copyright {

			font-style: normal;
			font-weight: 500;
			font-size: 12.8px;
			line-height: 140%;

			color: $placeholder_color;

			@media only screen and (max-width: 640px) {
				margin-bottom: 0;
			}
		}
	}
}
