@import '../../../styles/variables/variables';
@import '../../../styles/responsive';
.pf-confirm-email {
	background-color: $main_bg_color;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	@include belowLg {
		background-color: $white;
		.pf-header {
			justify-content: center;
			box-shadow: 0px 0px 12px rgba(63, 72, 87, 0.1);
			&__label,
			&__user {
				display: none;
			}
		}
	}
	&__container {
		display: flex;
		flex: 1;
	}
	&__card {
		margin: auto;
		width: 580px;
		padding: 60px 70px;
		border-radius: 6px;
		background-color: $white;
		color: $text_color;
		max-width: 100%;
		@include belowLg {
			padding-left: 35px;
			padding-right: 35px;
		}
		.pf-input__error {
			margin-bottom: 23px;
		}
	}
	&__icon {
		text-align: center;
		color: $secondary_color;
		padding-bottom: 15px;
		&--fail {
			color: $warning_color;
		}
	}
	&__title {
		font-family: $secondary-font;
		font-style: normal;
		font-weight: 500;
		line-height: 29px;
		color: $title_color;
		text-align: center;
		margin-bottom: 15px;
	}
	&___text {
		margin-bottom: 36px;
	}
	&__title,
	&___text {
		padding: 0 40px;

		@include belowLg {
			padding: 0;
			text-align: center;
		}
	}
	&___text.or {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		padding: 0;

		span {
			display: flex;
			width: 100%;
			border-bottom: 1px solid #eeeff1;

			&:first-child {
				margin-right: 20px;
			}
			&:last-child {
				margin-left: 20px;
			}
		}
	}
	&__button {
		margin-top: 36px;
		width: 300px;
		margin-left: auto;
		margin-right: auto;
		max-width: 100%;
	}
}

.start-button {
	width: 300px;
	margin: 0 auto;
}
