@import '../../../styles/responsive';

.dropdownItem {
	display: flex;
	align-items: center;

	div {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	span {
		margin-left: 16px;
		font-family: $base-font;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 22px;
		color: $title_color;
	}
	&.disabled {
		opacity: 0.5;
	}
}
