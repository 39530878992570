@import '../../../../styles/responsive';

.form {
	padding-bottom: 50px;
	width: 700px;
	max-width: 100%;

	h4 {
		margin-top: 70px;
		margin-bottom: 25px;
		font-family: $secondary-font;
		color: $text_color;
		font-size: 20px;
		line-height: 150%;
		@include belowLg {
			margin-top: 10px;
		}
		.asterisk {
			color: $warning_color;
		}
	}
}

.insurancePayer {
	flex: 0 0 auto;
	width: 100%;
	margin-bottom: 43px;
}

.buttons {
	padding-top: 45px;
	margin-top: 30px;

	display: flex;
	justify-content: flex-end;
	align-items: center;

	.cancel {
		width: 150px;
	}

	.submit {
		width: 230px;

		margin-left: 15px;
		@include belowLg {
			margin-left: 5px;
		}
	}
}

.date {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
	$dateRightMargin: 10px;

	@include belowSm {
		flex-wrap: wrap;
	}

	& > div:not(:last-child) {
		margin-right: $dateRightMargin;
	}

	.month {
		width: 100%;
		max-width: 300px;

		@include belowSm {
			max-width: 100%;
			margin-right: 0 !important;
		}
	}
	.day {
		width: 100%;
		max-width: 190px;

		@include belowSm {
			max-width: calc(50% - ($dateRightMargin / 2));
		}
	}
	.year {
		width: 100%;
		max-width: 190px;

		@include belowSm {
			max-width: calc(50% - ($dateRightMargin / 2));
		}
	}
}
