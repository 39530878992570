@import '../../../../../styles/variables/variables';

.container {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	margin-bottom: 15px;
}

.text {
	font-family: $base-font;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 150%;
	color: $title_color;
}

.beneficiary {
	text-align: right;

	> div {
		margin-bottom: 5px;

		display: flex;
		justify-content: flex-end;
		align-items: flex-start;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.proportion {
	margin-left: 10px;

	color: $line_color;
}
