@import '../../../../../../styles/responsive';

.inviteAdvisorsPopup__form {
	.footer {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 35px;

		button {
			margin: 0;
			width: 100%;
		}
	}
}
