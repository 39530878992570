@import '../../../styles/variables/variables';
@import '../../../styles/responsive';

.pf-user-description {
	display: flex;
	align-items: center;
	margin-bottom: 40px;
	@include belowLg {
		margin-bottom: 30px;
	}

	& .pf-avatar {
		margin-right: 25px;
	}

	.userDescription {
		flex-grow: 1;

		h3 {
			margin: 10px 0;
			font-family: $secondary-font;
			font-weight: 500;
			font-size: 25px;
			line-height: 125%;
			color: $title_color;
			word-break: break-word;
		}

		.children_wrapper {
			font-size: 13px;
			line-height: 18px;
			color: $text_color;
		}
	}
}
