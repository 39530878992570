@import '../../styles/responsive';

@keyframes appearance {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.notifications {
	margin-right: 15px;

	&.hidden {
		visibility: hidden;
	}

	.button-container {
		.toggle-button {
			display: flex;
			justify-content: center;
			align-items: center;
			width: auto;
			min-width: 22px;
			height: 22px;
			padding: 0;
			outline: none;
			border: none;
			border-radius: 24px;
			background-color: $secondary_bg_color;
			transition: width 0.4s ease-out;

			div {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.count-text {
				display: none;
				margin: 0 6px 0 8px;
				font-family: $base-font;
				font-style: normal;
				font-weight: 500;
				font-size: 10.24px;
				line-height: 12px;
				text-align: right;

				color: $white;
			}

			&.new {
				background-color: $primary_color;

				.bell-icon {
					margin-right: 8px;
					svg {
						path {
							fill: $white;
						}
					}
				}

				.count-text {
					display: flex;
				}
			}
		}
	}

	&__menu-paper.MuiMenu-paper {
		width: 100vw;
		bottom: 24px;
		max-width: 640px;
		border-radius: 6px;
		border: none;
		filter: drop-shadow(0px 0px 12px rgba($title_color, 0.2));
		margin-top: 29px;
		padding: 35px 0 0;

		&:before {
			right: 7px;
		}

		@include belowMd {
			height: 100%;
			bottom: initial;
			max-height: initial;
			max-width: initial;
			border-radius: 0;
			margin-top: initial;
			left: initial !important;
			top: initial !important;
			padding-top: 20px;

			&:before {
				content: none;
			}
		}
	}

	&__menu-list.MuiMenu-list {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		height: 100%;
		padding: 0;

		.notifications__menu-list-header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			padding: 0 40px 25px;
			min-height: 30px;
			width: 100%;

			@include belowMd {
				padding: 0 20px 25px;
			}

			@include belowMd {
				display: flex;
			}

			&__title {
				font-family: $secondary-font;
				font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 150%;

				color: $title_color;
			}

			&__buttonWrapper {
				display: flex;
				justify-content: space-between;
				align-items: center;

				&__refresh {
					display: flex;
					justify-content: center;
					align-items: center;
					border: none;
					outline: none;
					background-color: transparent;
					padding: 0;
					width: 24px;
					height: 24px;

					div {
						display: flex;
						justify-content: center;
						align-items: center;
					}

					svg {
						width: 100%;
						height: auto;
					}
				}

				&__closeButton {
					width: 24px;
					height: 24px;
					display: none;
					justify-content: center;
					align-items: center;
					border: none;
					outline: none;
					background-color: transparent;
					padding: 0;

					@include belowMd {
						display: flex;
						margin-left: 50px;
					}

					div {
						display: flex;
						justify-content: center;
						align-items: center;
					}

					&-icon {
						svg {
							width: 100%;
							height: auto;

							path {
								stroke: #d1d5db;
							}
						}
					}
				}
			}
		}

		.notifications__menu-list-body {
			flex-grow: 1;
			overflow: hidden auto;
			height: 50%;
			display: flex;
			flex-direction: column;

			&__tabWrapper {
				display: flex;
				flex-direction: column;
				justify-content: center;
				flex: 1 1;

				& > div {
					animation: appearance 0.2s ease-in-out;
					padding: 0;

					/* width */
					&::-webkit-scrollbar {
						width: 6px;
					}

					/* Track */
					&::-webkit-scrollbar-track {
						background: rgba($placeholder_color, 0.1);
						border-radius: 8px;
					}

					/* Handle */
					&::-webkit-scrollbar-thumb {
						background: rgba($placeholder_color, 0.3);
						border-radius: 8px;
					}

					/* Handle on hover */
					&::-webkit-scrollbar-thumb:hover {
						background: rgba($placeholder_color, 0.5);
					}
				}
			}

			.tab-page {
				padding: 0;
				margin: 0;
				height: 100%;
			}

			.tabs-flexContainer {
				padding: 0 40px;

				@include belowMd {
					padding: 0 20px;
				}

				.tab-button {
					@include belowMd {
						min-width: 160px;
					}
				}
			}

			&__loading-spinner {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;

				&-icon {
					svg {
						width: 70px;
						height: auto;
					}
				}
			}

			&__empty-label {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				height: 100%;

				.no-notifications-icon {
					display: flex;
					justify-content: center;
					align-items: center;
				}

				.no-notifications-text {
					margin-top: 40px;
					font-family: $secondary-font;
					font-weight: 500;
					font-size: 20px;
					line-height: 150%;
					text-align: center;
					color: $title_color;
				}
			}
		}

		.notifications__menu-list-footer {
			position: relative;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			padding: 25px 40px;

			@include belowMd {
				padding: 25px 20px;
			}

			&__dismissAll {
				&__button {
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: transparent;
					border: none;
					outline: none;
					padding: 0;

					font-family: $secondary-font;
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 20px;
					text-align: center;

					color: #8B919B;

					&__none {
						display: none;
					}
				}
			}

			&__viewArchive {
				&__button {
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: transparent;
					border: none;
					outline: none;
					padding: 0;

					font-family: $secondary-font;
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 20px;

					color: #3349B5;

					animation: appearance 0.2s ease-in-out;

					&-icon {
						display: flex;
						justify-content: center;
						align-items: center;
						margin-left: 10px;

						div {
							display: flex;
							justify-content: center;
							align-items: center;
						}
					}
				}
			}
		}
	}

	&__loadMore {
		display: flex;
		justify-content: center;
		align-items: center;

		&-icon {
			display: flex;
			justify-content: center;
			align-items: center;

			div {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			svg {
				width: 50px;
			}
		}
	}
}
