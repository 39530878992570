@import '../../../styles/variables/variables';

input[type='checkbox'] {
	width: 16px;
	height: 16px;
	border: 1px solid $placeholder_color;
	border-radius: 2px !important;
	background: $white;
	cursor: pointer;
}

.form-check-input {
	flex-shrink: 0;

	position: relative;
	width: 16px;
	height: 16px;
	margin: 1px 15px 0 0 !important;
	border: 1px solid $placeholder_color;
	border-radius: 2px !important;
	background-color: $white;
	cursor: pointer;
	line-height: 19px;

	&:checked {
		background-color: $primary_color !important;
		background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5L4 8L10.5 1.5' stroke='%23ffffff' stroke-width='2'/%3E%3C/svg%3E") !important;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		border: 1px solid $primary_color !important;
	}
}

.form-check {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	//margin-bottom: 0 !important;
	padding-left: 0 !important;

	&__label {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;
		font-family: $base-font;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		color: $title_color;
		text-align: left;
	}

	&.disabled {
		opacity: 0.5;
	}
}

.form-check-input:focus {
	border-color: $placeholder_color !important;
	outline: 0;
	box-shadow: none !important;
}
