@import '../../../styles/responsive';

.pf-accordion {
	&__wrapper {
		margin-bottom: 40px;
	}

	&__item {
		.accordion-header {
			.accordion-button {
				display: flex !important;
				justify-content: flex-start;
				align-items: center;
				font-family: $secondary-font;
				font-style: normal;
				font-weight: 500;
				font-size: 20px !important;
				line-height: 150%;
				color: #1f2c6e !important;

				&::after {
					content: none;
				}
				&::before {
					content: '';
					width: 16px;
					height: 11px;
					background-image: url("data:image/svg+xml,%3Csvg width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 6L8 1.5L15 6' stroke='%23A4ABB7'/%3E%3Cpath d='M1 10.5L8 6L15 10.5' stroke='%23A4ABB7'/%3E%3C/svg%3E");
					margin-right: 35px;
					transition: transform 0.2s ease-in-out;
				}
				&.collapsed::before {
					transform: rotate(-180deg);
				}
			}
		}
		.accordion-body {
			padding: 30px 0 30px 50px !important;
		}

		.accordion-collapse {
			opacity: 0;
			transition: opacity .25s ease, height .3s ease .1s !important;
			&.show {
				opacity: 1;
			}
		}
	}
}
