@import '../../styles/responsive';
@import '../../styles/variables/variables';

.apiModal__wrapper {
  display: flex;
  flex-direction: column;

  &__instanceName {
    margin-bottom: 15px;
    font-family: $secondary-font;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 125%;

    color: $title_color;
  }

  &__copyKey {
    cursor: pointer;
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      margin-right: 14px;
      background-color: $secondary_bg_color;
      border-radius: 50%;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__text {
      font-family: $base-font;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      word-break: break-all;

      color: $text_color;
    }
  }

  &__note {
    margin-bottom: 40px;
    font-family: $base-font;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;

    color: $text_color;
  }

  &__actionContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__dropdown {
      &>div:last-of-type {
        min-width: 240px;
        padding: 0 !important;
        border-radius: 3px !important;
        overflow: hidden !important;
        border: 1px solid rgba(0, 0, 0, 0.04) !important;
        box-shadow: 0 1px 12px rgba(63, 72, 87, 0.1) !important;
        margin-top: 5px;

        a {
          display: flex !important;
          justify-content: flex-start;
          align-items: center;
          height: 45px;
          padding: 0 15px !important;
          text-decoration: none !important;
        }
      }
    }

    &__editBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 40px;
      border: none;
      border-radius: 3px;

      & span {
        margin-left: 7px;
        font-family: $secondary-font;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        text-transform: capitalize;
        color: $white;
      }

      svg {
        path {
          stroke: $white;
        }
      }

      &:not(:last-of-type) {
        margin-right: 10px;
      }

      &:first-of-type {
        width: 280px;
        height: 40px;
        background-color: $primary_color;

        &:hover {
          background-color: $primary_hover_color;
        }
      }
    }

    &__deleteBtn {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      & span {
        margin-left: 7px;
        font-family: $secondary-font;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        text-transform: capitalize;
        color: $warning_color;
      }
    }
  }
}