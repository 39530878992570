@import '../../styles/responsive';
@import '../../styles/variables/variables';

.pf-companyProfileModal {
	.pf-IconButton {
		padding: 8px 0px !important;
	}

	.pf-activeInactiveButton {
		padding: 8px 14px !important;
		min-width: auto !important;

		span {
			position: absolute;
			left: 0;
			margin: 0;
		}
	}

	.descriptionInfo {
		font-family: $base-font;
		font-style: normal;
		font-weight: normal;
		font-size: 12.8px;
		line-height: 18px;
		color: $secondary_active_color;
		text-transform: uppercase;
	}
}
