@import '../../../../styles/responsive';
@import '../../../../styles/variables/variables';

.root {
  width: 100%;
  max-width: 540px;
  z-index: 5000;

  &>div{
    border-radius: 6px !important;
    background-color: transparent !important;
    border: none !important;
  }

  @media (min-width:600px) {
    min-width: 344px !important;
  }

  @media (max-width:600px) {
    padding: 0 10px;
  }
}

.card {
  background-color: transparent;
  width: 100%;
}

.typography {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  font-family: $base-font !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  letter-spacing: initial !important;

  color: $white;
}

.actionRoot {
  flex-wrap: nowrap;
  padding: 18px 20px 18px 30px !important;
  border-radius: 6px;
  backdrop-filter: blur(12.5px);
  box-shadow: 0 0 12px rgba(63, 72, 87, 0.1);
  justify-content: space-between;

  &.default {
    background-color: rgba(63, 72, 87, 0.9);
  }
  &.error {
    background-color: rgba(249, 45, 77, 0.9);
  }
  &.success {
    background-color: rgba(63, 72, 87, 0.9);
  }
  &.warning {
    background-color: rgba(249, 45, 77, 0.9);
  }
  &.info {
    background-color: rgba(63, 72, 87, 0.9);
  }
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;

  button:not(:nth-last-child) {
    margin-right: 10px;
  }
}

.closeActionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background-color: transparent;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    path {
      stroke: #D1D5DB;
    }
  }
}