@import '../../../../styles/responsive';
@import '../../../../styles/variables/variables';

@keyframes appearance {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.view-all-notification {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 1 1;
  padding: 40px 0 0;

  @include belowMd() {
    padding: 20px 0 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    min-width: 320px;
    padding: 0 70px;
    height: 100%;

    @include belowMd() {
      padding: 0;
    }

    &-backButton {
      margin-bottom: 20px;
      width: fit-content !important;

      @include belowMd() {
        margin-left: 20px;
      }
    }

    &-titleWrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      flex-wrap: wrap;
      margin-bottom: 30px;

      @include belowMd() {
        flex-direction: column;
        align-items: flex-start;
        padding: 0 20px;
        margin-bottom: 24px;
      }

      &-title {
        font-family: $secondary-font;
        font-style: normal;
        font-weight: 500;
        font-size: 31.25px;
        line-height: 39px;
        text-transform: capitalize;
        margin-right: 30px;

        color: $title_color;

        @include belowMd() {
          font-size: 25px;
          margin-right: 0;
        }
      }

      &-removeAllButton {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border: none;
        outline: none;
        padding: 0;

        font-family: $secondary-font;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;

        color: #8B919B;

        @include belowMd() {
          margin-top: 24px;
        }
      }
    }

    &-body {
      height: 100%;

      & > div {
        animation: appearance 0.2s ease-in-out;
        padding: 0;

        /* width */
        &::-webkit-scrollbar {
          width: 6px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: rgba($placeholder_color, 0.1);
          border-radius: 8px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: rgba($placeholder_color, 0.3);
          border-radius: 8px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: rgba($placeholder_color, 0.5);
        }
      }

      &__empty {
        display: flex;
        align-items: flex-start;
        justify-content: center;

        &-label {
          margin-top: 100px;

          .no-notifications-icon {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .no-notifications-text {
            font-family: $secondary-font;
            margin-top: 40px;
            font-weight: 500;
            font-size: 20px;
            line-height: 150%;
            text-align: center;
            color: $title_color;
          }
        }
      }

      &__loading {
        display: flex;
        align-items: center;
        justify-content: center;

        &__spinner {
          &-icon {
            svg {
              width: 70px;
              height: auto;
            }
          }
        }
      }
    }
  }
}