@import '../../styles/responsive';
@import '../../styles/variables/variables';

.description {
  font-family: $base-font;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: $text_color;
  overflow-wrap: break-word;

  &-label {
    font-family: $secondary-font;
    font-style: normal;
    font-weight: 500;
    font-size: 12.8px;
    line-height: 25px;
    text-transform: uppercase;
    color: $placeholder_color;

    margin-top: 45px;
    margin-bottom: 20px;
  }
  &-heading {
    font-family: $base-font;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: $text_color;
    margin-bottom: 5px;
    word-break: break-word;
  }
}
