@import '../../styles/responsive';
@import '../../styles/variables/variables';

.social {
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    span {
      color: $title_color;
    }
  }
  &__icon {
    margin-right: 15px;
  }
}
