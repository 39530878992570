@import '../../../styles/variables/variables';

.container-buttons {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.pf-inputAvatar {
	width: 100%;
	&__label {
		margin-bottom: 10px;
		color: $text_color;

		&-asterisk {
			color: $warning_color;
			margin-right: 3px;
		}

		&-wrapper {
			width: fit-content;
			font-family: $base-font;
			font-style: normal;
			font-weight: normal;
			font-size: 12.8px;
			line-height: 18px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-bottom: 5px;
		}
	}
	&__field {
		display: none;
		&-wrapper {
			width: 100%;
			position: relative;
			display: flex;
			justify-content: flex-start;
		}
	}
	&__hint {
		font-weight: normal;
		width: 100%;
		text-align: left;
		font-size: 12.8px;
		color: $text_color;
		line-height: 1.38;
		margin-top: 5px;
		&--error {
			color: $warning_color;
		}
	}

	.pf-button {
		padding: 5px 20px;
		height: auto;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		&__icon {
			padding: 0;
			justify-content: flex-start;
		}
		&__content {
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}
	}

	&__button {
		&-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-left: 5px;
		}
		&-label {
			position: relative;

			.pf-button {
				padding: 0 20px;
				margin-bottom: 5px;
			}

			.uploadHint {
				margin: 0;
				padding: 0 20px;
				font-family: $base-font;
				font-style: normal;
				font-weight: 400;
				font-size: 12.8px;
				line-height: 145%;
				color: $placeholder_color;
			}
		}
		&-icon {
			margin-right: 10px;
			svg path {
				stroke: currentColor !important;
			}
			span {
				display: flex;
			}
			svg,
			span {
				width: 16px;
				height: 16px;
			}
		}
	}

	&--error {
		.pf-input {
			&__field {
				background-color: $input_error_bg_color;
				color: $warning_color;
				&:focus {
					border-color: $warning_color;
				}
				&-icon {
					color: $warning_color;
				}
			}
		}
	}
}
