@import '../../../../../styles/responsive';

.content {
	max-width: 600px !important;
}

.date {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
	$dateRightMargin: 10px;

	@include belowSm {
		flex-wrap: wrap;
	}

	& > div:not(:last-child) {
		margin-right: $dateRightMargin;
	}

	.month {
		width: 100%;
		flex-grow: 1;

		@include belowSm {
			max-width: 100%;
			margin-right: 0 !important;
		}
	}
	.day {
		width: 100%;
		max-width: 135px;

		@include belowSm {
			max-width: calc(50% - ($dateRightMargin / 2));
		}
	}
	.year {
		width: 100%;
		max-width: 135px;

		@include belowSm {
			max-width: calc(50% - ($dateRightMargin / 2));
		}
	}
}
