@import '../../../../styles/variables/variables';

.emptyPage {
  &__container {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10px;
  }

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    h2 {
      margin-bottom: 50px;
      font-family: $base-font;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      white-space: pre-line;

      color: $text_color;
    }
  }
}