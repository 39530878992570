@import '../../styles/responsive';

.clientTagsComponent {
    display: flex;
    flex-direction: column;
    width: 100%;

    .titleWrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 15px;

        .title{
            font-family: $secondary-font;
            font-style: normal;
            font-weight: 500;
            font-size: 12.8px;
            line-height: 16px;
            text-transform: uppercase;

            color: #A4ABB7;
        }
        .editTagsButton{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 10px;

            &__icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 16px;
                height: 16px;

                div, span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                svg {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    .tagsContentWrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 40px;

        .addTagsButton {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            min-width: 110px;

            &__icon {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px;
                width: 20px;
                height: 20px;

                div, span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                svg {
                    width: 100%;
                    height: auto;
                }
            }
            &__text {
                font-family: $base-font;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                display: flex;
                align-items: center;

                color: #3349B5;
            }
        }
        .tagsContent {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;
            padding-top: 5px;
            margin: 0 -2.5px;

            .tagBadge {
                font-size: 12.8px;
                margin: 5px 2.5px;
                padding: 2px 12px;

                &:hover {
                    background-color: $secondary_bg_color;
                }
                &:active {
                    background-color: $secondary_bg_color;
                }
            }
        }
    }

    .button {
        background-color: transparent;
        padding: 0;
    }

    .displayNone {
        display: none !important;
    }
}
