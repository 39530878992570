@import '../../../styles/responsive';
@import '../../../styles/variables/variables';

.accountSettings {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 40px 0;

  &__container {
    width: 100%;
    max-width: 780px;
    min-width: 320px;
    padding: 0 70px;

    @include belowMd() {
      max-width: 680px;
      padding: 0 20px;
    }

    &-backButton {
      margin-bottom: 20px;
    }

    &-title {
      margin-bottom: 35px;
      font-family: $secondary-font;
      font-style: normal;
      font-weight: 500;
      font-size: 31.25px;
      line-height: 39px;
      text-transform: capitalize;

      color: $title_color;

      @include belowMd() {
        margin-bottom: 45px;
      }
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 30px 35px 30px 0;
    width: 100%;
    background-color: transparent;

    &.warning {
      .accountSettings__item__text-title {
        color: $warning_color;
      }
    }

    &:before {
      position: absolute;
      right: 0;
      content: '';
      width: 16px;
      height: 16px;
      background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6 4L10 8L6 12' stroke='%23A4ABB7'/%3e%3c/svg%3e");
    }

    &:not(:last-child) {
      border-bottom: 1px solid #EEEFF1;;
    }

    &__text {
      display: flex;
      flex-direction: column;
      width: 100%;

      &-title {
        margin-bottom: 10px;
        font-family: $secondary-font;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        text-align: left;
        color: $title_color;
      }
      &-description {
        font-family: $base-font;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: left;
        color: $placeholder_color;

      }
    }
  }
}

.warningMessage {
  font-family: $base-font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: $text_color;
}
