@import '../../../styles/responsive';
@import '../../../styles/variables/variables';

.teamData__item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;

  &-avatar {
    margin-right: 10px;
  }

  &-name-wrapper {
    display: flex;
    flex-direction: column;
  }

  &-name {
    margin-bottom: 5px;
    font-family: $base-font;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    color: $title_color;
  }

  &-companyName {
    margin-bottom: 5px;
    font-family: $base-font;
    font-style: normal;
    font-weight: 400;
    font-size: 12.8px;
    line-height: 150%;
    color: $text_color;
    opacity: 0.5;
  }

  &-specializations {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    &-badge {
      margin: 0 5px 5px 0;
      padding: 2px 7px;
      font-family: $base-font;
      font-style: normal;
      font-weight: 400;
      font-size: 10.24px;
      line-height: 12px;

      color: $text_color;
      background-color: $main_bg_color;
      border-radius: 26px;
    }
  }
}
