@import 'styles/variables/fonts';
@import 'styles/variables/colors';
@import 'styles/responsive';

.wrapper {
	display: flex;
	justify-content: center;
	align-items: center;

	background: #eeeff1;

	user-select: none;
}

.container {
	display: flex;
	justify-content: center;
	align-items: center;

	margin: 8px 60px;

	gap: 10px;

	@include belowSm() {
		flex-direction: column;
	}
}

.icon {
	> span > svg > rect {
		stroke: #3349b5;
	}

	> span > svg > path:nth-child(2) {
		fill: #3349b5;
	}

	> span > svg > path:last-child {
		stroke: #3349b5;
	}
}

.text {
	&Container {
		display: flex;
		align-items: flex-start;
	}

	text-align: center;

	font-family: $secondary-font;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;

	color: #3349b5;
}

.arrow {
	height: 6px;
	width: 6px;

	> span {
		display: flex;

		height: 6px;
		width: 6px;

		> svg > path {
			stroke: #3349b5;
		}
	}
}
