@import '../../../styles/variables/variables';

.search {
	display: flex;
	justify-content: flex-start;
	align-items: center;

	.search-box {
		margin-left: 10px !important;
	}

	&:before {
		content: '';
		display: inline-block;
		width: 16px;
		height: 16px;
		background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' fill='none'/%3E%3Ccircle cx='12.4853' cy='11' r='5.375' transform='rotate(-45 12.4853 11)' stroke='%238B919B' stroke-width='1.25'/%3E%3Cpath d='M17.082 15.5967L19.5569 18.0716' stroke='%238B919B' stroke-width='1.25'/%3E%3C/svg%3E") !important;
		background-position: center;
	}
}
