@import '../../../styles/responsive';

.pf-copyright {
	text-align: center;
	margin-top: auto;
	color: $placeholder_color;
	font-weight: 400;
	@include belowLg {
		font-size: 12.8px;
	}
}
