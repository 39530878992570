@import '../../../styles/variables/variables';

.pf-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;

    .icon {
        svg {
            width: 100%;
            height: auto;
        }
    }
}