@import '../../../styles/responsive';
.pf-userCellItem {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;

	&__name-group {
		display: flex;
		flex-direction: column;
		padding-left: 20px;
		font-weight: 500;
		overflow: hidden;

		.name {
			font-family: $base-font;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
			color: $text_color;
		}
	}
}

.pf-avatar {
	position: relative;
	&__img {
		object-fit: cover;
	}
	&__circle {
		position: absolute;
		left: 0;
		top: 0;
		transform: rotate(-90deg);
	}
	&__prog {
		fill: rgba($white, 0.1);
		stroke: $primary_color;
		stroke-width: 10px;
		stroke-dasharray: 0.999;
		stroke-dashoffset: 0;
	}
}
