@import '../../styles/responsive';

.pf-portal {
	display: flex;
	flex: 1;
	flex-direction: column;
	padding-top: 60px;
	overflow-x: hidden;
	background-color: $main_bg_color;

	scrollbar-color: rgba(87, 87, 87, 0.6) rgba(229, 229, 234) !important;
	scrollbar-width: thin !important;
	&::-webkit-scrollbar {
		width: 8px;
		background-color: rgba(229, 229, 234) !important;
		border-radius: 9px !important;
	}
	&::-webkit-scrollbar-thumb {
		background-color: rgba(87, 87, 87, 0.6) !important;
		border-radius: 9px !important;
	}
	&::-webkit-scrollbar-thumb:hover {
		background-color: rgba(87, 87, 87, 0.6) !important;
	}

	@include belowLg {
		padding-top: 50px;
	}

	&__container {
		display: flex;
		flex: 1;
		overflow: hidden;
	}

	& .pf-header {
		position: fixed;
		top: 0;
		left: 0;
	}

	&.hideHeader {
		padding-top: 0;
	}
}
