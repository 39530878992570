@import '../../../styles/variables/variables';

.asyncCreatableSelect {

    &__label {
        color: $text_color;
        &-asterisk {
            color: $warning_color;
            margin-right: 3px;
        }
        &-wrapper {
            font-family: $base-font;
            font-style: normal;
            font-weight: normal;
            font-size: 12.8px;
            line-height: 18px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 5px;
        }
    }

    &__component {
        &-wrapper {
            display: flex;
            flex-direction: row;

            .pf-input__field-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                right: -30px;

                span {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    &__hint {
        font-weight: normal;
        width: 100%;
        text-align: left;
        font-size: 12.8px;
        color: $text_color;
        line-height: 1.38;
        margin-bottom: 0 !important;
        &--error {
            margin-top: 3px;
            color: $warning_color;
        }
    }
    &--error {
        .pf-input {
            &__field {
                background-color: $input_error_bg_color;
                color: $warning_color;
                &:focus {
                    border-color: $warning_color;
                }
                &-icon {
                    color: $warning_color;
                }
            }
        }
    }
}

.customTagsLabel {
    div {
        padding: 10px 20px !important;
    }

    .newLabel__hr {
        display: flex;
        width: auto;
        margin: 2.5px 20px;
        height: 0;
        border-bottom: solid 1px #EEEFF1;
    }
}
