@import '../../../styles/responsive';

$dot_size: 55;
$dot_font_size: 25;
$item_border_width: 4;

.emptyPage {
	&__container {
		position: relative;
		z-index: 1;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		overflow: auto;
		padding: 50px 20px;


		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			width: 0;
			height: 0;
		}

		&__wrapper {
			width: 100%;
			max-width: 840px;
			height: fit-content;
		}
	}

	&__header {
		h1 {
			margin-bottom: 20px;
			font-family: $secondary-font;
			font-style: normal;
			font-weight: 500;
			font-size: 31.25px;
			line-height: 39px;

			color: $title_color;
		}
		h2 {
			margin-bottom: 50px;
			font-family: $base-font;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 150%;

			color: $text_color;

			@include belowLg {
				margin-bottom: 40px;
			}
		}
	}

	&__main {
		.main-item {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			padding-left: 57px;
			padding-bottom: 66px;
			margin-left: calc((#{$dot_size + 5 + px} / 2) - #{$item_border_width + px});

			&:not(:last-child) {
				border-left: $item_border_width + px solid $secondary_bg_color;
			}
			&:last-child {
				border-left: $item_border_width + px solid transparent;
			}

			.dot {
				position: absolute;
				display: flex;
				justify-content: center;
				align-items: center;
				top: calc(0px - (#{$dot_size + px} / 2) + (#{$dot_font_size + px} / 2));
				left: calc(0px - (#{$dot_size + px} / 2) - (#{$item_border_width + px}) / 2);
				width: $dot_size + px;
				height: $dot_size + px;
				border-radius: 50%;
				background-color: $secondary_bg_color;

				font-family: $secondary-font;
				font-weight: 500;
				font-size: $dot_font_size + px;
				line-height: 36px;
				color: $title_color;
			}

			@include belowLg {
				margin-left: calc((#{40 + px} / 2) - #{4 + px});
				padding-left: 30px;
				padding-bottom: 40px;

				.dot {
					width: 40 + px;
					height: 40 + px;
					top: calc(0px - (#{40 + px} / 2) + (#{16 + px} / 2));
					left: calc(0px - (#{40 + px} / 2) - (#{$item_border_width + px}) / 2);
					font-size: 16 + px;
				}
			}

			.description {
				margin-bottom: 15px;
				font-family: $base-font;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 150%;

				color: $text_color;
			}

			.content {
				width: 100%;
				max-width: 440px;

				img {
					height: auto;
					width: 100%;
				}

				button {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					max-width: 380px;
					height: 40px;
					padding: 0 10px;

					.whiteIcon {
						svg {
							path {
								stroke: $white;
							}
						}
					}
				}
			}
		}
	}
}
