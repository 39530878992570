@import '../../../styles/responsive';
@import '../../../styles/variables/variables';

.pf-tableBar {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0 20px 0 0;
	font-family: $base-font;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	color: $text_color;
	background-color: $white;
	overflow: hidden;

	transition: all 0.5s;
	position: absolute;
	top: 60px;
	z-index: 5;
	width: 100%;
	opacity: 0;

	&.pf-tableBar-selected {
		opacity: 1;
	}

	@media only screen and (max-width: 360px) {
		top: 60px;
	}

	&__checkbox {
		display: flex;
		padding: 13px 10px;
		justify-content: center;
		align-items: center;
		width: 90px;
	}

	.group-buttons {
		display: flex;

		button {
			width: 100%;
			max-width: max-content;
			height: 40px;

			@media only screen and (max-width: 991px) {
				padding: 0;
				min-width: 48px;

				.pf-button__icon--prefix {
					padding: 0;
				}

				.button-text {
					display: none;
				}
			}

			&:not(:last-child) {
				margin-right: 7px;
			}
		}
	}

	.pf-close-button {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 161px;
		min-width: 121px;
		height: 16px;
		padding: 0;
		margin-right: 48px;
		background-color: transparent;
		font-family: $base-font;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;

		&__text {
			margin-left: 5px;
		}

		@media only screen and (max-width: 1030px) {
			margin-right: 24px;
		}

		@media only screen and (max-width: 991px) {
			margin-right: 24px;
		}
	}

	.pf-icon-close {
		width: 7.5px;
		height: 7.5px;
		margin-right: 9px;
		background-color: transparent;

		div {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;

			svg {
				width: 7.5px;
				height: 7.5px;
				stroke: $text_color;
			}
		}
	}

	.pf-icon-request-report {
		span {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 2px;
		}
	}

	.pf-request-report-button,
	.pf-consumer-block-button,
	.pf-consumer-delete-button,
	.pf-quit-from-team-button {
		max-width: max-content;
		min-width: 180px;
		height: 40px;
		//padding: 0;
		margin: 0 7px;

		span {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			font-family: $secondary-font;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
		}
	}

	.pf-consumer-block-button {
		min-width: 180px;
		background-color: $secondary_bg_color;

		&:hover {
			background-color: $main_bg_color;
		}

		span {
			color: $primary_color;
		}
	}

	.pf-consumer-delete-button {
		background-color: $warning_color;

		&:hover {
			background-color: $warning_hover_color;
		}

		span {
			color: $white;
		}
	}

	.pf-icon-delete {
		svg {
			path {
				stroke: $white;
			}
		}
	}
}

.companyProfile {
	@media only screen and (max-width: 1650px) {
		.pf-tableBar {
			padding: 0 22.25px;
			.pf-tableBar__checkbox {
				display: none;
			}

			.pf-close-button {
				justify-content: flex-start;
				width: auto;
				min-width: auto;
				margin-right: auto;
			}

			.pf-consumer-delete-button,
			.pf-consumer-block-button,
			.pf-request-report-button,
			.pf-quit-from-team-button {
				min-width: 48px;
				width: 48px;

				span {
					margin: 0;
					padding: 0;
				}
				.button-text {
					display: none;
				}
			}

			.pf-splitButton {
				.activeInactiveTitle {
					display: none;
				}
			}
		}
	}
}

@media only screen and (max-width: 991px) {
	.pf-tableBar {
		padding: 0 22.25px;
		.pf-tableBar__checkbox {
			display: none;
		}

		.pf-close-button {
			justify-content: flex-start;
			width: auto;
			min-width: auto;
			margin-right: auto;

			.pf-close-button__text {
				display: none;
			}
		}

		.pf-consumer-delete-button,
		.pf-consumer-block-button,
		.pf-request-report-button,
		.pf-quit-from-team-button {
			min-width: 48px;
			width: 48px;

			span {
				margin: 0;
				padding: 0;
			}
			.button-text {
				display: none;
			}
		}

		.pf-splitButton {
			.activeInactiveTitle {
				display: none;
			}
		}
	}
}
