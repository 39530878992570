@import '../../../styles/responsive';
@import '../../../styles/variables/variables';

.teamData {
  width: 100%;

  &__viewAllButton {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    height: 40px;
    background-color: transparent;

    button {
      padding: 0;
      font-family: $base-font;
      font-style: normal;
      font-weight: 500;
      font-size: 12.8px;
      line-height: 15px;
      display: flex;
      align-items: center;
      background-color: transparent;
      color: $primary_color;

      svg {
        margin-left: 10px;
        color: #8b919b;
        path {
          stroke: $primary_color;
        }
      }
    }
  }
}
