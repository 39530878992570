@import '../../../styles/responsive';
@import '../../../styles/variables/variables';

.reportsBloc {
  width: 100%;
  font-family: $base-font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: $text_color;

  &__report-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;

    background-color: transparent;
    border-radius: initial;
    border: none;

    &:hover {
      .report-icon {
        svg {
          path.origin {
            opacity: 0;
          }
          path.hover {
            opacity: 1;
          }
        }
      }
    }

    .report-icon {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-right: 14px;

      div {
        display: flex;
        justify-content: center;
        align-items: flex-start;
      }

      &.new-report {
        svg {
          circle.circle {
            display: initial;
          }
        }
      }

      svg {
        transition: opacity 0.2s;
        path.origin {
          opacity: 1;
        }
        path.hover {
          opacity: 0;
        }
        circle.circle {
          display: none;
        }
      }
    }

    .report-date {
      width: 100%;
      min-width: 90px;
      max-width: 150px;
      margin-right: 5px;
      font-family: $base-font;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: left;
      color: $title_color;

      &.new-report {
        color: $primary_color;
      }
    }

    .report-time {
      width: 100%;
      min-width: 140px;
      font-family: $base-font;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: left;
      color: $placeholder_color;
    }
  }

  &__viewAllButton {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    height: 40px;
    background-color: transparent;

    button {
      padding: 0;
      font-family: $base-font;
      font-style: normal;
      font-weight: 500;
      font-size: 12.8px;
      line-height: 15px;
      display: flex;
      align-items: center;
      background-color: transparent;
      color: $primary_color;

      svg {
        margin-left: 10px;
        color: #8b919b;
        path {
          stroke: $primary_color;
        }
      }
    }
  }
}
