@import '../../../../../styles/variables/variables';

.container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	white-space: pre-wrap;

	&:not(:last-of-type) {
		margin-bottom: 35px;
	}
}

.icon {
	flex-shrink: 0;
	height: 100%;
	margin-right: 14px;
	margin-top: 2px;

	display: flex;
	justify-content: center;
	align-items: flex-start;
	align-self: flex-start;

	div {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		height: 16px;
		width: 16px;
	}

	svg {
		width: 100%;
		height: auto;
	}
}

.infoContainer {
	display: flex;
	flex-direction: column;

	flex-grow: 1;

	font-family: $base-font;
	font-style: normal;

	.title {
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		word-break: break-word;

		color: $title_color;
	}

	.text {
		margin-top: 5px;

		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		word-break: break-word;

		color: $title_color;
	}

	.details {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;

		.date,
		.whoDidTheAction {
			margin-top: 10px;
			margin-right: 15px;

			font-weight: 400;
			font-size: 12.8px;
			line-height: 15px;

			color: #afb5bf;
		}
	}

	.note {
		margin-top: 5px;
		font-weight: 400;
		font-size: 12.8px;
		line-height: 15px;
		margin-right: 15px;

		color: #afb5bf;
	}
}

.actions {
	display: flex;
	justify-content: center;
	align-items: center;

	margin-left: 18px;

	background-color: white;
}
