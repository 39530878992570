@import '../../styles/responsive';

.inviteAdvisorModal {
	.pf-modal__paper {
		max-width: 1000px !important;
		padding-left: 7vh;
		padding-right: 7vh;
	}

	.pf-modal__subtitle {
		text-align: start;
	}

	.advisorCategories {
		margin-bottom: 30px;
		padding-left: 16px;
	}
	
}
.inviteAdvisorModal__form {
	.footer {
		margin-top: 35px;
		display: flex;
		justify-content: center;

		button {
			max-width: fit-content;
		}
	}
	.email {
		padding: 0px 20px 0px 14px;
	}

	.emailTileWrapper {
		margin-top: 15px;
		max-height: 150px;
		overflow-y: auto;
	}
}
