@import '../../../../../styles/responsive';

.button {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 25px;
	margin-top: 20px;
	width: -moz-fit-content;
	width: fit-content;
	min-height: 30px;
	border: none;
	outline: none;
	background-color: #eeeff1;
	border-radius: 3px;

	font-family: $secondary-font;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;

	color: #3349b5;

	&.buttonNew {
		background-color: #ffffff;
	}

	&.buttonDisabled {
		opacity: 0.5;

		color: #ffffff;
		background-color: #a4abb7;
	}
}

.note {
	margin-top: 20px;
}
