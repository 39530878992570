@import '../../styles/responsive';

.deepLinkPopup {

    &__contentBody {
        margin: 0 !important;
    }

    &__paper {
        position: relative;
        max-width: 480px !important;
        padding: 0 !important;

        .pf-modal__title {
            padding: 50px 35px 0;
        }

        .pf-modal__subtitle {
            padding: 0 35px;
        }

        overflow-y: auto !important;
        overflow-x: hidden !important;

        scrollbar-color: rgba(87, 87, 87, 0.6) rgba(229, 229, 234) !important;
        scrollbar-width: thin !important;
        &::-webkit-scrollbar {
            width: 8px;
            background-color: rgba(229, 229, 234) !important;
            border-radius: 9px !important;
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgba(87, 87, 87, 0.6) !important;
            border-radius: 9px !important;
        }
        &::-webkit-scrollbar-thumb:hover {
            background-color: rgba(87, 87, 87, 0.6) !important;
        }
    }

    &__contentWrapper {

        .main {
            padding: 0 35px 35px;

            .specializationsBlock {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 35px;
                min-height: 180px;

                &.mb0 {
                    margin-bottom: 0;
                }

                &__content {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    width: 100%;
                    height: 100%;

                    .specializationItem-all {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 20px;

                        &:before {
                            content: '';
                            position: absolute;
                            right: -10px;
                            height: 24px;
                            border-right: 1px solid #EEEFF1;
                        }
                    }
                }
            }

            .emailField {
                margin-bottom: 15px;
            }

            .sendButton {
                height: 40px !important;
            }
        }
        .footer {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 70px;


            .copyProfileButton {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                background-color: #F7F7F8;
                padding: 0;

                &__icon {
                    margin-right: 7px;

                    div, svg {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                &__text {
                    font-family: $base-font;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;

                    color: $primary_color;
                }

                &:hover {
                    background-color: #eeeff1;
                }

                &:disabled {
                    .copyProfileButton__text {
                        color: #8B919B;
                    }

                    .copyProfileButton__icon {
                        svg {
                            path:first-child {
                                stroke: #8B919B;
                            }
                            path:last-child {
                                fill: #8B919B;
                            }
                        }
                    }
                }
            }
        }
    }
}
