@import '../../../styles/responsive';

.pf-avatar__wrapper {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	.displayFlex {
		display: flex !important;

		img {
			object-fit: cover;
		}
	}

	.pf-avatar {
		position: relative;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		border-radius: 50%;
		z-index: 1;

		&.rounded-circle {
			border-radius: 50%;
		}

		&.rounded-square {
			border-radius: 7%;
		}

		&__img {
			object-fit: cover;
			opacity: 1;
			transition: opacity 0.4s;

			&.rounded-square {
				border-radius: 7%;
			}
		}

		&__circle {
			position: absolute;
			left: 0;
			top: 0;
			transform: rotate(-90deg);
			width: 100%;
			height: auto;
		}

		&__prog {
			fill: rgba($white, 0.1);
			stroke: $primary_color;
			stroke-width: 5.5px !important;
			stroke-dashoffset: 0;
			transition: stroke-dasharray 0.7s linear 0s;
		}

		&__loader-wrapper {
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			background-color: rgba(0, 0, 0, 0.4);

			@include belowLg {
				width: 100px !important;
				height: 100px !important;
			}

			.pf-avatar__loader-color {
				stroke: $secondary_color;
			}
		}
	}

	&__avatarStatusIcon {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 2;


		svg {
			width: 100%;
			height: auto;
		}

		div {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

