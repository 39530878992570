@import '../../../styles/variables/variables';
@import '../../../styles/responsive';

.stepsBlock {
	.step-page {
		padding: 55px 0 45px;
		margin-bottom: 30px;

		@include belowLg {
			padding: 30px 0 45px;
		}
	}

	.tab-indicator {
		background-color: transparent;
	}

	.tab-scroller {
		border-bottom: 1px solid #EEEFF1;
	}

	.tabs-flexContainer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 30px;

		.MuiStep-root {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			width: 100%;

			&:not(:last-child) {
				margin-right: 25px;
			}
		}
	}

	.label-wrapper {
		.label-iconContainer {
			padding: 0;
			margin-right: 15px;
			.label-icon {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 30px;
				height: 30px;
				border-radius: 50%;
				background-color: #F7F7F8;

				font-family: $base-font;
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 19px;
				text-align: center;

				color: $primary_color;
			}
		}

		.label {
			font-family: $secondary-font;
			font-style: normal;
			font-weight: 400;
			font-size: 20px;
			line-height: 25px;

			display: flex;
			align-items: center;
			text-align: center;
			color: $primary_color;
		}

		&.label {
			&-active {

				.label-labelContainer {

					.label {
						color: $primary_color !important;
						font-weight: 500;
					}
				}

				.label-iconContainer {
					.label-icon {
						background-color: #EEEFF1;
					}
				}

			}
			&-disabled {
				.label-labelContainer {

					.label {
						color: #59657A !important;
						opacity: 0.7;
					}
				}
				.label-iconContainer {
					.label-icon {
						color: #59657A;
						opacity: 0.7;
					}
				}
			}
			&-completed {
				.label-labelContainer {

					.label {
						color: #59657A !important;
						opacity: 0.7;
					}
				}

				.label-iconContainer {
					.label-icon {
						background-color: transparent;
					}
				}
			}
		}
	}
}

