@import '../../../../../styles/variables/variables';

.container {
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 12px 0;
}

.icon {
	flex-shrink: 0;

	height: 24px;
	width: 24px;

	margin-right: 18px;

	svg {
		width: 100%;
		height: auto;

		filter: grayscale(100%);
	}
}

.infoContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;

	flex-grow: 1;

	font-family: $base-font;
	font-style: normal;

	.title {
		font-weight: 500;
		font-size: 16px;
		line-height: 150%;

		color: $title_color;
	}

	.hint {
		margin-top: 1px;

		font-weight: 400;
		font-size: 12.8px;
		line-height: 15px;

		color: $warning_color;
	}
}

.actions {
	display: flex;
	justify-content: center;
	align-items: center;

	margin-left: 18px;

	background-color: white;
}
