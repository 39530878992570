@import '../../../styles/responsive';
@import '../../../styles/variables/variables';

.editableNote {
  width: 100%;

  &__contentEditable {
    position: relative;
    padding: 20px 20px 20px 50px;
    background-color: #FFECBC;
    border-radius: 3px;
    font-family: $base-font;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #3F4857;
    caret-color: $primary_color;
    white-space: pre-wrap;

    &:hover {
      background-color: rgba(255, 236, 188, 0.6);
    }

    &:focus-visible {
      outline: none;
      background-color: rgba(255, 236, 188, 0.6);
    }

    &:before {
      position: absolute;
      top: 50%;
      transform: translate(-30px, -50%);
      content: '';
      width: 16px;
      height: 16px;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.8'%3E%3Cpath d='M5.80467 13.1378L12 6.94242L9.05733 3.99976L2.862 10.1951C2.77671 10.2805 2.71612 10.3874 2.68667 10.5044L2 13.9998L5.49467 13.3131C5.612 13.2838 5.71933 13.2231 5.80467 13.1378ZM14 4.94242C14.25 4.69239 14.3904 4.35331 14.3904 3.99976C14.3904 3.6462 14.25 3.30713 14 3.05709L12.9427 1.99976C12.6926 1.7498 12.3536 1.60938 12 1.60938C11.6464 1.60938 11.3074 1.7498 11.0573 1.99976L10 3.05709L12.9427 5.99976L14 4.94242Z' fill='%23DB8F2C'/%3E%3C/g%3E%3C/svg%3E");
    }

    &.focused {
      padding: 20px;

      &:before {
        content: none;
      }
    }

    &[contentEditable=true]:empty:not(:focus):after{
      content:attr(data-text);
      font-family: $base-font;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #DB8F2C;

    }
  }
}
