@import '../../../../styles/variables/variables';

.virtuoso__loadMore {
  display: flex;
  justify-content: center;
  align-items: center;

  &_icon {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg {
      width: 50px;
    }
  }
}