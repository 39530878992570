@import '../../../styles/responsive';
@import '../../../styles/variables/variables';

.ecosystemAdditionalInfo {
	.btnContainer {
		display: flex;
		margin-bottom: 50px;

		.btnGroup {
			display: flex;
			flex-direction: column;
			width: 100%;
			z-index: 10;

			.shareProfileButtonWrapper {
				display: flex;
				align-items: center;
				position: relative;
				margin-bottom: 5px;

				.shareProfileButton {
					&:disabled {
						svg {
							path {
								fill: rgba($text_color, 0.5);
							}
						}
					}
				}

				.shareProfileTooltip {
					position: absolute;
					right: -34px;
				}
			}

			.viewProfileButton {
				svg {
					path {
						stroke: $primary_color;
					}
				}

				&:disabled {
					svg {
						path {
							stroke: rgba($text_color, 0.5);
						}
					}
				}
			}

			&>button:first-child {
				margin-bottom: 5px;
			}
		}

		hr {
			color: $hr;
			margin: 5px 10px;
		}

		.dropdownItemText {
			display: flex;
			align-items: center;

			svg {
				path {
					fill: $title_color;
				}
			}

			span {
				margin-left: 14px;
				font-family: $base-font;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 22px;
				color: $title_color;
			}

			&.removeText {

				svg {
					path {
						fill: $warning_color !important;
					}
				}

				span {
					color: $warning_color;
				}
			}
		}
	}

	.additionalInfoTitle {
		margin-bottom: 20px;
		font-family: $secondary-font;
		font-weight: 500;
		font-size: 13px;
		line-height: 25px;
		text-transform: uppercase;
		color: $placeholder_color;
	}

	.specializations {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		.specialization {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			padding: 6.5px 20px;
			background: $secondary_bg_color;
			border-radius: 30px;
			margin: 0 5px 10px 5px;
			font-family: $base-font;
			font-style: normal;
			font-weight: normal;
			font-size: 12.8px;
			line-height: 18px;
			color: $text_color;
		}
	}
}