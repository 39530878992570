@import '../../../../../styles/variables/variables';

.beneficiaries {
	&-item {
		display: flex;

		margin-bottom: 26px;

		&--proportion {
			max-width: 138px;

			margin-left: 10px;
			margin-right: 10px;
		}

		&--remove-button {
			display: flex;
			align-items: center;
			justify-content: center;

			margin-top: 23px;

			padding-left: 25px;

			cursor: pointer;

			.icon {
				svg {
					height: 10px;
					width: 10px;
					path {
						stroke: #f92d4d;
					}
				}
			}
		}
	}

	&--error-hint {
		margin-bottom: 26px;

		font-family: $base-font;
		font-style: normal;
		font-weight: 400;
		font-size: 12.8px;
		line-height: 15px;
		color: $warning_color;
	}

	&--add-button {
		padding: 0;
		height: auto;
		width: auto;

		span {
			justify-content: flex-start;
		}
	}
}
