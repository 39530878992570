@import '../../../styles/responsive';

.emptySuperadminPage {
	&__container {
		display: flex;
		//flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;


		&__wrapper {
			display: flex;
			padding: 10px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

		position: absolute;
		z-index: 10;
	}

	&__header {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;

		h1 {
			margin: 30px 0 20px;
			font-family: $secondary-font;;
			font-style: normal;
			font-weight: 500;
			font-size: 31.25px;
			line-height: 39px;
			text-align: center;

			color: $title_color;
		}
		h2 {
			margin-bottom: 50px;
			font-family: $base-font;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 150%;
			text-align: center;

			color: $text_color;

			img {
				height: auto;
				width: 100%;
			}
		}
	}

	&__main {
		.emptyButton {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			min-height: 50px;
			padding: 10px 46px;

			font-family: $secondary-font;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 20px;
			text-transform: capitalize;
			box-shadow: 0 0 12px rgba($title_color, 0.1);
			border-radius: 36px;

			.pf-icon-plus {
				margin-right: 13px;
				span,
				div {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
				}
			}

			&.primary {
				color: $white;
				background: $primary_color;

				&:hover {
					background: $primary_hover_color;
				}

				.pf-icon-plus {
					svg {
						rect {
							fill: $white;
						}
					}
				}
			}

			&.secondary {
				color: $title_color;
				background: $secondary_color;

				&:hover {
					background: $secondary_hover_color;
				}

				.pf-icon-plus {
					svg {
						rect {
							fill: $title_color;
						}
					}
				}
			}
		}
	}
}
