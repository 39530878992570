@import 'styles/responsive';
@import 'styles/variables/variables';

.container {
	flex-grow: 1;
	flex-shrink: 1;

	min-width: 320px;

	border-radius: 4px;
	overflow: hidden;

	background: #f7f7f8;

	&Selected {
		background: #f0f4ff;
	}
}

.header {
	display: flex;
	align-items: flex-start;

	padding: 30px 25px 25px;

	background: #eeeff1;

	&Selected {
		background: #e5ecff;
	}

	.icon {
		display: flex;
		justify-content: center;
		align-items: center;

		margin-top: 4px;
		margin-right: 10px;

		div {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	.title {
		font-family: $secondary-font;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;

		color: #3f4857;
	}

	.price {
		font-family: $base-font;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;

		color: #8b919b;

		> span {
			text-decoration: line-through;
			opacity: 0.6;
		}

		&Selected {
			color: #3349b5;
			opacity: 0.8;
		}
	}
}

.content {
	flex-grow: 1;

	padding: 25px 25px 30px;

	.features {
		display: flex;
		flex-direction: column;

		gap: 15px;

		.feature {
			font-family: $base-font;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;

			color: #59657a;
		}
	}
}
