@import '../../../../styles/responsive';

.virtuosoCurrentItem {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 15px 0;

  @include belowMd {
    padding: 10px 0;
  }

  .checkbox {
    margin-right: 10px;
    min-height: initial !important;
  }
  .avatar {
    margin-right: 15px;
  }
  .info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .name {
    font-family: $base-font;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $text_color;
  }
  .specialization {
    margin-top: 5px;
    font-family: $base-font;
    font-style: normal;
    font-weight: 400;
    font-size: 12.8px;
    line-height: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    color: #7A8495;
  }
}