@import '../../../../styles/responsive';

.addNewInsurance {
	&__tabsContainer {
		.tab-button {
			max-width: 50% !important;
		}
	}
	&__form {
		padding-bottom: 50px;
		width: 700px;
		max-width: 100%;
		h4 {
			margin-top: 70px;
			margin-bottom: 25px;
			font-family: $secondary-font;
			color: $text_color;
			font-size: 20px;
			line-height: 150%;
			@include belowLg {
				margin-top: 10px;
			}
			.asterisk {
				color: $warning_color;
			}
		}
		h5 {
			margin-bottom: 25px;
			margin-top: 10px;
			font-family: $base-font;
			font-style: normal;
			font-weight: 400;
			font-size: 12.8px;
			line-height: 15px;
			display: flex;
			align-items: center;

			color: #59657A;

			opacity: 0.7;
		}
		&-button {
			width: 150px;
			&--submit {
				margin-left: 15px;
				width: 230px;
				@include belowLg {
					margin-left: 5px;
				}
			}
		}

		.policyRenewalDate {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-end;
			$dateRightMargin: 10px;

			@include belowSm {
				flex-wrap: wrap;
			}

			&>div:not(:last-child) {
				margin-right: $dateRightMargin;
			}

			.month {
				width: 100%;
				max-width: 300px;

				@include belowSm {
					max-width: 100%;
					margin-right: 0 !important;
				}
			}
			.day {
				width: 100%;
				max-width: 190px;

				@include belowSm {
					max-width: calc(50% - ($dateRightMargin / 2));
				}
			}
			.year {
				width: 100%;
				max-width: 190px;

				@include belowSm {
					max-width: calc(50% - ($dateRightMargin / 2));
				}
			}
		}

		.typeByDuration {
			margin-bottom: 70px !important;
		}
	}
}