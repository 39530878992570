@import '../../../../styles/responsive';

.notifications-item {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 25px 40px;
  border-bottom: 2px solid #F7F7F8;

  @include belowMd {
    padding: 25px 20px;
  }

  &.inactive {
    opacity: 0.4;
  }

  &.newItem {
    background-color: #FFF4D9;
  }

  &__avatar-wrapper {
    flex-grow: 0;

    &__avatarComponent {
      @include belowMd {
        display: none;
      }
    }

    &__onlyIcon {
      display: none;
      @include belowMd {
        display: flex;
      }
    }


  }

  &__body {
    width: 100%;
    padding-left: 25px;
    display: flex;
    flex-direction: column;

    &-title {
      margin-bottom: 5px;
      padding-right: 40px;

      font-family: $base-font;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;

      color: $title_color;
    }

    &-amountTime {
      font-family: $base-font;
      font-style: normal;
      font-weight: 400;
      font-size: 12.8px;
      line-height: 15px;
      display: flex;
      align-items: flex-end;

      color: $placeholder_color;

      &.newItem {
        color: #DB8F2C;
      }
    }

    &-description {
      margin-top: 15px;
      font-family: $base-font;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;

      color: $text_color;
    }

    &-actionButton {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 25px;
      margin-top: 20px;
      width: -moz-fit-content;
      width: fit-content;
      min-height: 30px;
      border: none;
      outline: none;
      background-color: #EEEFF1;
      border-radius: 3px;

      font-family: $secondary-font;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;

      color: #3349B5;

      &.newItem {
        background-color: #FFFFFF;
      }
    }
  }

  &__action-wrapper {
    position: absolute;
    top: 25px;
    right: 40px;

    @include belowMd {
      right: 24px;
    }

    &__closeActionButton {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      outline: none;
      background-color: transparent;
      padding: 0;

      &:hover {
        .notifications-item__action-wrapper__closeActionButton__icon {
          path {
            stroke: $primary_hover_color;
          }
        }
      }

      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__icon {
        path {
          transition: stroke 0.2s ease-in-out;
          stroke: #A4ABB7;
        }

        &.newItem {
          path {
            stroke: #DB8F2C;
          }
        }
      }
    }
  }
}