@import '../../../styles/variables/variables';
.pf-contacts {
	padding: 0;
	margin-bottom: 40px;

	&__item {
		display: flex;
		align-items: center;
		color: $text_color;
		list-style-type: none;
		font-family: $base-font;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 19px;

		&:not(:last-of-type) {
			margin-bottom: 15px;
		}

		&.pf-companyName {
			font-weight: 500;
			margin-bottom: 40px;
		}
	}

	& span {
		position: relative;
		top: 1px;
	}

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		//width: 16px;
		//height: 30px;
		margin-right: 14px;
		div {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	&__companyLogo {
		margin-right: 11px;
	}

	& rect + path {
		fill: $white;
	}

	&__streetAddress {
		margin-top: -10px;
		margin-bottom: 5px !important;
	}
	&__streetAddress,
	&__postalCode {
		span {
			margin-left: 30px;
		}
	}
	&__email {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.emailWrapper {
			display: flex;
			flex-direction: row;
			.pf-button {
				margin-left: 10px;
				span {
					position: initial;
					top: initial;
				}
			}
		}
		.unconfirmedEmail {
			margin: 5px 0 0 31px;
			font-family: $base-font;
			font-style: normal;
			font-weight: 400;
			font-size: 12.8px;
			line-height: 15px;

			color: #F92D4D;
		}

	}

	.pf-edit-email {
		span {
			display: flex;
			align-items: center;
		}
	}
}

.pf-profileEdit__form-button.pf-profileEdit__form-button--submit.pf-edit-email-submit {
	margin-left: 0;
	width: 100% !important;
}

.pf-edit_user_email {
	.modal-body {
		padding: 0 35px;
	}
}
