@import '../../../styles/responsive';
@import '../../../styles/variables/variables';

.inviteToEcosystem {
	position: relative;

	.specializationFilterBar {
		padding: 0 83px;
		display: flex;
		align-items: center;
		flex-direction: row;
		flex-wrap: nowrap;
		height: 80px;
		border-bottom: 1px solid #eeeff1;

		@media only screen and (max-width: 992px) {
			height: 110px;
			padding: 0 0 0 20px;
			flex-direction: column;
			align-items: flex-start;
		}

		.backButton-wrapper {
			margin-right: 44px;

			@media only screen and (max-width: 992px) {
				margin-left: 5px;
				margin-right: 0;
			}
		}
	}

	.pf-tableBar {
		top: 80px;
	}
}

.newAdvisor_button {
	overflow: hidden;
	position: fixed;
	right: 40px;
	bottom: 90px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 227px;
	height: 50px;
	padding: 0 15px;
	background: $primary_color;
	border-radius: 36px;
	color: $white;
	transition: width 0.5s ease-out;
	box-shadow: 0 0 12px rgba($title_color, 0.1);

	.icon_plus {
		position: absolute;
		left: 19px;
		transition: 0.5s ease-out;

		span {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	.newAdvisor_button_text {
		position: absolute;
		left: 50px;
		width: 150px;
		font-family: $secondary-font;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 23px;
		text-transform: capitalize;
		color: $white;
		opacity: 1;
	}
}

@media only screen and (max-width: 991px) {
	.newAdvisor_button {
		width: 220px;
		height: 40px;
		bottom: initial;

		.icon_plus {
			left: 20px;
			margin-right: 0;
		}
	}
}

@media only screen and (max-width: 530px) {
	.newAdvisor_button {
		&:hover {
			width: 220px;

			.icon_plus {
				left: 20px;
			}

			.newAdvisor_button_text {
				display: none;
			}
		}
	}
}
