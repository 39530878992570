@import '../../../styles/variables/variables';
@import '../../../styles/responsive';
.pf-restore-password {
	background-color: $main_bg_color;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	@include belowLg {
		background-color: $white;
		.pf-header {
			justify-content: center;
			box-shadow: 0px 0px 12px rgba(63, 72, 87, 0.1);
			&__label,
			&__user {
				display: none;
			}
		}
	}
	&__container {
		display: flex;
		flex: 1;
		align-items: center;
		justify-content: center;
	}

	&__main {
		width: 100%;
		max-width: 580px;
	}

	&__card {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		align-items: center;
		margin: auto;
		width: 100%;
		padding: 60px 70px;
		border-radius: 6px;
		background-color: $white;
		color: $text_color;
		max-width: 580px;
		@include belowLg {
			padding-left: 35px;
			padding-right: 35px;
		}
		.pf-input__error {
			margin-bottom: 23px;
		}

		form {
			width: 100%;
			max-width: 450px;
		}
	}
	&__icon {
		text-align: center;
		color: $secondary_color;
		padding-bottom: 15px;
		&--fail {
			color: $warning_color;
		}
	}
	&__title {
		font-family: $secondary-font;
		font-style: normal;
		font-weight: 600;
		line-height: 29px;
		color: $title_color;
		text-align: center;
		margin-bottom: 20px;
	}
	&___text {
		margin-bottom: 50px;
	}
	&__button {
		margin-top: 23px;
	}
	&__contact {
		margin-top: 50px;
		text-align: center;
	}
}
