@import '../../../styles/responsive';


.isActive {
	margin-bottom: 15px;

	.isActiveButton-wrapper {

		.isActiveButton {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			padding: 0;

			.pf-button__content {
				display: flex;
				justify-content: flex-start;
				align-items: center;

				font-family: $base-font;
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 19px;

				color: $title_color;
			}
		}

		.isActiveButton__edit-icon {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: 16px;

			div {
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}

	}

	.isActive-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;

		.isActiveButton__isActive-dot {
			margin-right: 14px;
		}

		&__text {
			font-family: $base-font;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 19px;
			display: flex;
			align-items: center;

			color: $title_color;
		}
	}

	.isActiveButton__isActive-dot {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 7px;

		div {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		&.activeDot {
			svg {
				circle {
					fill: #00874E;
				}
			}
		}
		&.inactiveDot {
			svg {
				circle {
					fill: #F92D4D;
				}
			}
		}
	}
}

.pf-IsActive__form {
	.isActive__radioGroup {
		display: flex;
		flex-direction: row !important;
		justify-content: flex-start;
		align-items: center;

		.pf-radio {
			margin-bottom: 0 !important;
		}
	}

	.reasonInactive {
		height: 50px;
		opacity: 1;
		transition: all 0.4s;
		&.disabled {
			opacity: 0;
			height: 0;
		}
	}
}