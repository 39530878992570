@import 'styles/variables/colors';
@import 'styles/variables/fonts';

.container {
	cursor: pointer;

	display: flex;
	align-items: center;

	margin: 0 10px;
	padding: 17px 16px 17px 12px;

	background: #3349b5;
	border-radius: 6px;
}

.labels {
	flex-grow: 1;
	flex-shrink: 1;

	margin: 0 6px;
}

.title {
	margin-bottom: 2px;

	font-family: $secondary-font;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	color: $white;
}
.subtitle {
	font-family: $base-font;
	font-style: normal;
	font-weight: 400;
	font-size: 12.8px;
	line-height: 15px;
	color: $white;

	opacity: 0.6;
}
