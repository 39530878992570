@import '../../styles/responsive';
@import '../../styles/variables/variables';

.ecosystemModal {
	&.loading {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}

	.pf-IconButton {
		padding: 8px 0 !important;
	}

	.isActiveSection {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 15px;

		&__isActive-dot {
			margin-right: 14px;

			&.activeDot {
				svg {
					circle {
						fill: #00874E;
					}
				}
			}

			&.inactiveDot {
				svg {
					circle {
						fill: #F92D4D;
					}
				}
			}
		}

		&__text {
			font-family: $base-font;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 19px;
			display: flex;
			align-items: center;

			color: $title_color;
		}
	}

	.descriptionInfo {
		font-family: $base-font;
		font-style: normal;
		font-weight: normal;
		font-size: 12.8px;
		line-height: 18px;
		color: $secondary_active_color;
		text-transform: uppercase;
	}
}
