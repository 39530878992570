@import 'styles/responsive';
@import 'styles/variables/variables';

.wrapper {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	padding: 40px 0;
}

.container {
	width: 100%;
	max-width: 1200px;
	min-width: 320px;
	padding: 0 70px;

	@include belowMd() {
		padding: 0 20px;
	}
}

.back {
	margin-bottom: 20px;
}

.title {
	margin-bottom: 65px;
	font-family: $secondary-font;
	font-style: normal;
	font-weight: 500;
	font-size: 31.25px;
	line-height: 39px;
	text-transform: capitalize;

	color: $title_color;

	@include belowMd() {
		margin-bottom: 45px;
	}
}

.content {
	display: flex;
	flex-wrap: wrap;

	@include belowXL() {
		flex-direction: column;
	}
}

.info {
	max-width: 680px;

	.plans {
		display: grid;
		grid-template-columns: 1fr 1fr;

		gap: 5px;

		@include belowMd() {
			grid-template-columns: 1fr;
		}
	}

	.hint {
		margin-top: 40px;

		font-family: $base-font;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;

		color: #3f4857;
	}
}

.actions {
	display: flex;
	flex-direction: column;

	margin-left: 120px;

	@include belowXL() {
		margin-left: 0;
	}

	.links {
		display: flex;
		flex-direction: column;

		margin-top: 65px;

		gap: 30px;

		@include belowXL() {
			margin-bottom: 150px;
		}
	}
}
