@import '../../../../../styles/variables/variables';

.wrapper {
	display: flex;

	margin-top: 10px;
}

.container {
	display: flex;
	flex-direction: row;

	justify-content: flex-start;
	align-items: center;

	flex-shrink: 1;
	flex-grow: 0;

	gap: 7px;

	padding: 4px 12px 4px 9px;

	border-radius: 3px;

	&Basic {
		background-color: $secondary_bg_color;
	}

	&Warning {
		background-color: #fff0d1;
	}

	&Error {
		background-color: #ffe1e2;
	}
}

.icon {
	display: flex;
	justify-content: center;
	align-items: center;

	div {
		display: flex;
		justify-content: center;
		align-items: center;

		height: 10px;
		width: 10px;
	}

	svg {
		width: 100%;
		height: auto;
	}

	&Basic {
		svg {
			> path {
				fill: $title_color;
			}
		}
	}

	&Warning {
		svg {
			> path {
				fill: $secondary_active_color;
			}
		}
	}

	&Error {
		svg {
			> path {
				fill: $warning_color;
			}
		}
	}
}

.date {
	font-family: $base-font;
	font-style: normal;

	font-weight: 400;
	font-size: 12.8px;
	line-height: 19px;

	&Basic {
		color: $title_color;
	}

	&Warning {
		color: $secondary_active_color;
	}

	&Error {
		color: $warning_color;
	}
}
