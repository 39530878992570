@import '../../../styles/responsive';
@import '../../../styles/variables/variables';

.myEcosystem {
	position: relative;

	.pf-tableBar {
		top: 0;

		.remove-button, .recommend-button {
			min-width: 220px;

			@media only screen and (max-width: 991px) {
				min-width: 48px;
			}
		}
	}

	.inviteNewAdvisorsEmptyPageButton {
		width: fit-content !important;
		min-width: 280px;
		border-radius: 36px;
	}

	.newAdvisor_button {
		overflow: hidden;
		position: fixed;
		right: 40px;
		bottom: 90px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 260px;
		height: 50px;
		padding: 0 15px;
		background: $primary_color;
		border-radius: 36px;
		color: $white;
		box-shadow: 0 0 12px rgba($title_color, 0.1);

		.icon_plus {
			position: absolute;
			left: 20px;

			span {
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}

		.newAdvisor_button_text {
			position: absolute;
			left: 50px;
			width: 190px;
			font-family: $secondary-font;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 23px;
			text-transform: capitalize;
			color: $white;
			opacity: 1;
		}
	}

	@media only screen and (max-width: 991px) {
		.newAdvisor_button {
			width: 260px;
			height: 40px;
			bottom: initial;

			.icon_plus {
				left: 20px;
				margin-right: 0;
			}
		}
	}

	@media only screen and (max-width: 530px) {
		.newAdvisor_button {
			&:hover {
				width: 260px;

				.icon_plus {
					left: 20px;
				}

				.newAdvisor_button_text {
					display: none;
				}
			}
		}
	}
	.inviteNewAdvisorsEmptyPageDescription {
		color: #59657a;
		margin-top: 15px;
		min-width: 650px;

		.code {
			color: #59657a;
		}
	}
	.inviteNewAdvisorsEmptyPageModalButton {
		justify-content: center;
		align-items: center;
		max-width: 276px !important;
		height: 40px;
		padding: 0 10px;
		color: $primary_color;
		border-radius: 36px;
		margin-top: 10px;
		font-weight: 500;

		.icon_plus {
			position: relative;
			left: -5px;
			top: -1.5px;
			margin-right: 7px;
		}
	}
}
