body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
	font-family: 'Inter';
	src: local('Inter'), url(./fonts/Inter-Regular.woff) format('woff'),
		url(./fonts/Inter-Regular.otf) format('opentype');
}

@font-face {
	font-family: 'Inter';
	font-weight: 300;
	src: local('Inter'), url(./fonts/Inter-Light.woff) format('woff'),
		url(./fonts/Inter-Light.otf) format('opentype');
}

@font-face {
	font-family: 'Inter';
	font-weight: 500;
	src: local('Inter'), url(./fonts/Inter-Medium.woff) format('woff'),
		url(./fonts/Inter-Medium.otf) format('opentype');
}

@font-face {
	font-family: 'Inter';
	font-weight: 600;
	src: local('Inter'), url(./fonts/Inter-SemiBold.woff) format('woff'),
		url(./fonts/Inter-SemiBold.otf) format('opentype');
}

@font-face {
	font-family: 'Made Tommy';
	src: local('Made Tommy'), url(./fonts/made_tommy_regular-webfont.woff) format('woff'),
		url(./fonts/made_tommy_regular-webfont.woff2) format('woff2'),
		url(./fonts/made_tommy_regular-webfont.otf) format('opentype');
}

@font-face {
	font-family: 'Made Tommy';
	font-weight: 500;
	src: local('Made Tommy'), url(./fonts/made_tommy_medium-webfont.woff) format('woff'),
		url(./fonts/made_tommy_medium-webfont.woff2) format('woff2'),
		url(./fonts/made_tommy_medium-webfont.otf) format('opentype');
}
