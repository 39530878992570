@import '../../../styles/responsive';

.insurance {
	width: 100%;

	.emptyInsurance {
		font-family: $base-font;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 150%;

		color: $text_color;
	}

	&__viewAllButton {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		align-self: stretch;
		height: 40px;
		margin-top: 35px;
		background-color: transparent;

		button {
			padding: 0;
			font-family: $base-font;
			font-style: normal;
			font-weight: 500;
			font-size: 12.8px;
			line-height: 15px;
			display: flex;
			align-items: center;
			background-color: transparent;
			color: $primary_color;

			svg {
				margin-left: 10px;
				color: #8b919b;
				path {
					stroke: $primary_color;
				}
			}
		}
	}

	.insurance-item {
		padding: 20px 0;

		&:not(:last-child) {
			border-bottom: 1px solid #eeeff1;
		}
	}

	.insurance-compact-item {
		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}

	.insuranceItem {
		&:not(:last-child) {
			.longInsuranceItem {
				border-bottom: 1px solid #eeeff1;
			}
		}

		.shortInsuranceItem {
			margin-bottom: 30px;
		}

		.longInsuranceItem {
			padding: 30px 0;

			&:first-child {
				padding-bottom: 30px;
			}

			.shortInsuranceItem {
				margin-bottom: 0;
			}
		}

		.longLifeInsuranceItem {
			padding-bottom: 30px;
			border-bottom: 1px solid #eeeff1;

			&.noData {
				padding-bottom: 0;
			}

			.longInsuranceItem {
				border-bottom: none;
			}
		}
	}

	.shortInsuranceItem {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		&__icon {
			width: 24px;
			height: 24px;
			margin-right: 18px;
			flex-shrink: 0;

			svg {
				width: 100%;
				height: auto;
			}

			.grey {
				display: flex;
				justify-content: center;
				align-items: center;

				width: 100%;
				height: 24px;
				border-radius: 50%;
				overflow: hidden;

				div {
					display: flex;
					justify-content: center;
					align-items: center;
				}
				svg {
					width: 100%;
					height: auto;
					filter: grayscale(100%);
				}
			}
		}

		&__description {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: space-around;
			width: 100%;
			margin-right: 21px;
			flex-grow: 1;
			flex-shrink: 1;

			&-text {
				font-family: $base-font;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 150%;
				color: $title_color;
			}

			&-date {
				font-family: $base-font;
				font-style: normal;
				font-weight: 400;
				font-size: 12.8px;
				line-height: 15px;
				color: #00814d;
			}

			&-none,
			&-isNone {
				font-family: $base-font;
				font-style: normal;
				font-weight: 400;
				font-size: 12.8px;
				line-height: 15px;
				color: #f92d4d;

				&.green {
					color: #00814d;
				}
			}
		}

		.lifeInsurance-amountOfTheCoverage {
			display: flex;
			justify-content: center;
			align-items: center;
			font-family: $base-font;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 150%;
			text-align: right;
			color: $title_color;
			flex-shrink: 0;
		}
	}

	.longInsuranceItem {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;

		&__button {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: 18px;
			background-color: white;
			//overflow: hidden;

			.plusButton {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 24px;
				height: 24px;
				border: none;
				outline: none;
				border-radius: 50%;
				background: $primary_color;

				&__icon,
				&__icon div {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 8px;
					height: 8px;

					svg {
						width: 100%;
						height: auto;
					}
				}
			}

			.dropdownButton {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 24px;
				height: 24px;
				border-radius: 3px;
				border: none;
				background-color: transparent;

				#bg-nested-dropdown.dropdown-toggle {
					position: absolute;
					width: 100%;
					height: 100%;
					padding: 0;
					margin: 0 !important;
					background-color: transparent;
					transition: none;

					div {
						background-color: transparent;
					}
				}

				.dropdown-menu {
					padding: 0;
					margin-top: 7px;
					width: 100%;
					min-width: 220px;
					max-width: 220px;
					border-radius: 3px;
					border: none;
					background-color: #ffffff !important;
					box-shadow: 0 1px 12px rgba(63, 72, 87, 0.1);

					.dropdown-item {
						font-family: $base-font;
						font-style: normal;
						font-weight: 400;
						font-size: 16px;
						line-height: 19px;

						color: #59657a;
					}
				}
			}
		}
	}
}
