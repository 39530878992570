@import '../../../../styles/responsive';
@import '../../../../styles/variables/variables';

.undoActionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  //margin-right: 20px;
  width: fit-content !important;
  border: none;
  outline: none;
  background-color: transparent;

  font-family: $base-font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #FFCE66;

  @include belowMd {
    margin-right: 0;
  }
}