@import '../../../styles/responsive';
@import '../../../styles/variables/variables';

.todoData {
  width: 100%;

  .empty {
    font-family: $base-font;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color:$text_color;
  }

  &__sendTaskButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 220px;
    min-height: 40px;
    background: $secondary_bg_color;
    border-radius: 3px;
    margin-bottom: 30px;

    &:hover {
      background: $main_active_bg_color;
    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 7px;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__text {
      font-family: $secondary-font;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;

      color: $primary_color;
    }
  }

  &__viewAllButton {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    height: 40px;
    background-color: transparent;
    margin-top: 35px;

    button {
      padding: 0;
      font-family: $base-font;
      font-style: normal;
      font-weight: 500;
      font-size: 12.8px;
      line-height: 15px;
      display: flex;
      align-items: center;
      background-color: transparent;
      color: $primary_color;

      svg {
        margin-left: 10px;
        color: #8b919b;
        path {
          stroke: $primary_color;
        }
      }
    }
  }
}

.deleteTodoDescription {
  font-family: $base-font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;

  color: $text_color;

}
