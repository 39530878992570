@import '../../../../styles/responsive';

.companies-page {
	height: 100%;
	.name-group {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: center;

		.companyLogo {
			margin-right: 14px;
		}

		.name {
			display: flex;
			flex-direction: column;

			p {
				padding: 0;
				margin: 0;
				font-family: $base-font;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 19px;
				color: $text_color;
			}
		}
	}
}

.pf-confirmBlockedModal {
	.modal-header {
		text-align: center;
	}
}

.pf-confirmDeleteModal {
	.modal-content {
		padding: 50px 35px 35px 35px;

		.modal-header {
			text-align: center;
			padding: 0;
		}

		.modal-body {
			padding: 40px 20px 0 20px;
		}

		.modal-footer {
			padding: 35px 0 0;
		}
	}

	.pf-profileEdit__form {
		padding-bottom: 0px;

		.inputSelectCompany {
			height: 0;
			overflow: hidden;
			transition-duration: 0.5s;
			opacity: 0;
		}
	}

	.pf-profileEdit__form.option_3 {
		.inputSelectCompany {
			opacity: 1;
			height: 85px;
			overflow: initial;
		}
	}
}

.pf-newCompany-button {
	overflow: hidden;
	position: fixed;
	right: 40px;
	bottom: 90px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 50px;
	padding: 0 15px;
	background: $primary_color;
	border-radius: 36px;
	color: $white;
	transition: width 0.5s ease-out;
	box-shadow: 0 0 12px rgba($title_color, 0.1);

	.pf-icon-plus {
		position: absolute;
		left: 19px;
		transition: 0.5s ease-out;

		span {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	.pf-newCompany-button-text {
		position: absolute;
		left: 50px;
		width: 160px;
		font-family: $secondary-font;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 23px;
		text-transform: capitalize;
		color: $white;
		opacity: 0;
		transition: 0.5s ease-out;
	}

	&:hover {
		width: 260px;
		background: $primary_hover_color;

		.pf-icon-plus {
			position: absolute;
			left: 46px;
		}

		.pf-newCompany-button-text {
			left: 71px;
			opacity: 1;
		}
	}
}
@media only screen and (max-width: 991px) {
	.pf-newCompany-button {
		width: 40px;
		height: 40px;

		.pf-icon-plus {
			left: 14px;
			margin-right: 0;
		}
	}
}

@media only screen and (max-width: 530px) {
	.pf-newCompany-button {
		width: 40px;
		height: 40px;
		right: 20px;
		bottom: 77px;

		.pf-icon-plus {
			margin-right: 0;
		}

		&:hover {
			width: 40px;

			.pf-icon-plus {
				left: 14px;
			}

			.pf-newCompany-button-text {
				display: none;
			}
		}
	}
}

@media only screen and (max-width: 360px) {
	.rs-table-cell:first-child,
	.rs-table-cell-header:first-child {
		width: 60px !important;
		min-width: 60px !important;
	}
	.rs-table-cell-first .rs-table-cell-content {
		width: 60px !important;
		min-width: 60px !important;
	}
}
