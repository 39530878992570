@import '../../../../styles/responsive';

.companyProfile {
	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: stretch;
	height: 100%;

	.companyInfo {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		width: 34%;
		min-width: 320px;
		box-sizing: content-box;
		padding: 60px 60px 40px 60px;
		height: calc(100vh - 160px);
		background-color: $white;
		overflow: auto;
		-ms-overflow-style: none;
		scrollbar-width: none;
		max-width: 500px;

		&::-webkit-scrollbar {
			width: 0;
			height: 0;
		}
		.companyInfo_wrapper {
			width: 100%;
			max-width: 380px;

			.pf_company_description {
				display: flex;
				align-items: center;
				margin-bottom: 23px;
				width: 100%;

				@include belowLg {
					margin-bottom: 30px;
				}

				.companyLogo {
					margin-right: 25px;
				}

				h3 {
					margin: 10px 0px;
					font-family: $secondary-font;
					font-weight: 600;
					font-size: 25px;
					line-height: 125%;
					color: $title_color;
					word-break: break-all;
				}
			}

			&__edit-button {
				color: $placeholder_color;
				padding: 0;
				margin-bottom: 50px;
				font-weight: 400;

				@include belowLg {
					margin-top: 25px;
					color: $text_color;
					text-align: left;
					padding-left: 0;
					padding-right: 0;
				}
			}

			.btnContainer {
				display: flex;
				margin-bottom: 50px;

				.consumerInfoBtn {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 70px;
					height: 40px;
					border: none;
					border-radius: 3px;

					& span {
						margin-left: 7px;
						font-family: $secondary-font;
						font-weight: normal;
						font-size: 16px;
						line-height: 23px;
						color: $primary_color;
					}

					&:not(:last-of-type) {
						margin-right: 10px;
					}

					&:first-of-type {
						width: 100%;
						//max-width: 280px;
						height: 40px;
						background-color: $secondary_bg_color;

						&:hover {
							background-color: $main_active_bg_color;
						}
					}
				}

				.invite_btn {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 280px;
					height: 40px;
					background-color: #3349b5;
					border: none;
					border-radius: 3px;
				}

				hr {
					color: $hr;
					margin: 5px 10px;
				}

				.dropdownItemText {
					&__block,
					&__delete {
						display: flex;
						align-items: center;

						span {
							margin-left: 10px;
							font-family: $base-font;
							font-style: normal;
							font-weight: normal;
							font-size: 16px;
							line-height: 22px;
						}
					}

					&__block {
						margin-left: -4px;

						span {
							color: $title_color;
						}

						svg {
							path {
								stroke: $title_color;
							}
							circle {
								stroke: $title_color;
							}
						}
					}

					&__delete {
						span {
							color: $warning_color;
						}

						svg {
							path {
								stroke: $warning_color !important;
							}
						}
					}
				}

				.dropdown {
					.dropdown-menu.show {
						min-width: 240px;
						padding: 0 !important;
						border-radius: 3px !important;
						overflow: hidden !important;
						border: 1px solid rgba(0, 0, 0, 0.04) !important;
						box-shadow: 0 1px 12px rgba(63, 72, 87, 0.1) !important;
						margin-top: 5px;
					}
				}
			}

			.additionalInfoWrapper {
				margin-bottom: 50px;

				.additionalInfoTitle {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					margin-bottom: 25px;

					.text {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						font-family: $secondary-font;
						font-weight: 500;
						font-size: 13px;
						line-height: 16px;
						text-transform: uppercase;
						color: $placeholder_color;
					}

					.adminIcon {
						display: flex;
						justify-content: center;
						align-items: center;
						margin-right: 14px;
						margin-bottom: 2px;

						span {
							display: flex;
							justify-content: center;
							align-items: center;
						}
					}
				}

				.admin-wrapper {
					.name, .email, .phone {
						font-family: $base-font;
						font-style: normal;
						font-weight: 600;
						font-size: 16px;
						line-height: 19px;

						color: $text_color;

						.generalAdminIcon {
							margin-left: 10px;
						}
					}
					.email, .phone {
						font-weight: 400;
					}

					p {
						margin: 0;

						&:not(:last-child) {
							margin-bottom: 10px;
						}
					}

					&:not(:last-child) {
						margin-bottom: 25px;
					}
				}
			}
		}
	}

	.companyUsers {
		width: 66%;
		background-color: $white;
		position: relative;

		.pf-tableBar,
		.pf-tableBar.pf-tableBar-selected {
			top: 0px;
		}
	}

	.newCompany_button {
		overflow: hidden;
		position: fixed;
		right: 40px;
		bottom: 90px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 50px;
		height: 50px;
		padding: 0 15px;
		background: $primary_color;
		border-radius: 36px;
		color: $white;
		transition: width 0.5s ease-out;
		box-shadow: 0 0 12px rgba($title_color, 0.1);

		&:hover {
			width: 210px;
			background: $primary_hover_color;

			.icon_plus {
				left: 40px;
			}

			.newCompany_button_text {
				left: 65px;
				opacity: 1;
			}
		}

		.icon_plus {
			position: absolute;
			left: 19px;
			transition: 0.5s ease-out;
			display: flex;
			justify-content: center;
			align-items: center;

			span {
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}

		.newCompany_button_text {
			position: absolute;
			left: 50px;
			width: 120px;
			font-family: $secondary-font;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 23px;
			text-transform: capitalize;
			color: $white;
			opacity: 0;
			transition: 0.5s ease-out;
		}
	}

	.activeInactiveTitle {
		color: #a4abb7;
		padding: 0;
		font-weight: 400;
		margin-bottom: 0;
		margin-right: 12px;
	}

	.tableEmailCell {
		display: flex;
		flex-direction: column;

		.unconfirmedEmail {
			margin: 5px 0 0;
			font-family: $base-font;
			font-style: normal;
			font-weight: 400;
			font-size: 12.8px;
			line-height: 15px;

			color: #F92D4D;
		}
	}

	@media only screen and (max-width: 991px) {
		.newCompany_button {
			width: 40px;
			height: 40px;
			right: 65px;
			bottom: initial;

			.icon_plus {
				left: 14px;
				margin-right: 0;
			}

			&:hover {
				width: 200px;

				.icon_plus {
					left: 25px;
				}

				.newCompany_button_text {
					left: 51px;
				}
			}
		}
	}

	@media only screen and (max-width: 530px) {
		.newCompany_button {
			&:hover {
				width: 40px;

				.icon_plus {
					left: 14px;
				}

				.newCompany_button_text {
					display: none;
				}
			}
		}
	}

	@media only screen and (max-width: 1500px) {
	}

	@media only screen and (max-width: 900px) {
		flex-direction: column;

		.companyInfo {
			height: auto;
			padding: 60px 120px;
			flex-direction: row;
			overflow: visible;
		}

		.companyUsers {
			width: 100%;
		}
	}

	@media only screen and (max-width: 784px) {
		.pf-tableBar .pf-close-button {
			margin-top: 0;
		}
	}

	@media only screen and (max-width: 569px) {
		flex-direction: column;

		.companyInfo {
			padding: 10px 20px;
		}

		.companyUsers {
			padding: 0;
		}

		//.pf-close-button {
		//  order: 1;
		//}
		//.pf-splitButton {
		//  order: 3;
		//}
		//.group-buttons {
		//  order: 2;
		//}

		//.pf-tableBar .pf-consumer-delete-button {
		//  min-width: 100px !important;
		//}
		//
		//.pf-tableBar .pf-consumer-block-button {
		//  min-width: 200px !important;
		//}
		//
		//.consumerInfoBtn {
		//  width: 250px !important;
		//}
	}
}

.pf-confirmSendInviteModal {
	.modal-content {
		padding: 50px 35px 35px;

		.modal-header {
			padding: 0 0 35px;
		}

		.modal-body {
			padding: 0;

			.pf-profileEdit__form {
				padding: 0;

				div:last-child {
					button {
						width: 100%;
						margin: 0;
					}
				}

				.inputUserRole {
					.pf-select {
						display: flex;
						align-items: center;

						.pf-select__label-wrapper {
							flex: 0 0 68px;
							padding: 0;
							margin-right: 15px;

							.pf-select__label {
								font-family: $base-font;
								font-style: normal;
								font-weight: normal;
								font-size: 16px;
								line-height: 22px;
								color: $line_color;
							}
						}

						.pf-select__hint {
							display: none;
						}
					}

					.pf-select > div:nth-child(2) {
						.pf-select__value-container {
							padding: 0;

							.pf-select__single-value {
								position: static;
								transform: initial;
								-webkit-transform: initial;
								-moz-transform: initial;
								-ms-transform: initial;
							}

							input {
								display: none;
							}
						}

						.pf-select__control {
							background-color: transparent !important;
							border: none !important;
							min-height: initial;

							svg {
								width: 14px;
								height: 14px;
								path {
									fill: $text_color;
								}
							}
						}

						.pf-select__option--is-selected {
							background-color: $white;
							color: $text_color;

							&:hover {
								background: $secondary_bg_color;
								color: $text_color;
							}
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 360px) {
	.rs-table-cell:first-child,
	.rs-table-cell-header:first-child {
		width: 60px !important;
		min-width: 60px !important;
	}
	.rs-table-cell-first .rs-table-cell-content {
		width: 60px !important;
		min-width: 60px !important;
	}

	.companyProfile .consumerInfoBtn {
		width: 200px !important;
	}

	.rs-table-word-wrap .rs-table-cell-content {
		word-break: break-word !important;
	}

	.companyProfile .pf-tableBar {
		flex-wrap: wrap;
		padding: 0 10px;
	}
}
