@import '../../../styles/variables/variables';
.pf-button {
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: $secondary-font;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 23px;
	letter-spacing: 0em;
	text-align: center;
	text-transform: capitalize;
	padding: 5px 28px;
	height: 40px;
	width: 100%;
	border-radius: 3px;
	cursor: pointer;
	position: relative;

	&__icon {
		padding-right: 7px;
		display: inline-flex;
		align-items: center;
		justify-content: center;

		div {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		span.me-2 {
			padding-right: 10px !important;
			margin: 0 !important;
		}

		span.calendarIcon {
			svg {
				path {
					stroke: $primary_color;
				}
			}
		}

		span {
			display: inline-flex;
			align-items: center;
			justify-content: center;
		}

		&--loading {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;

			animation-name: spin;
			animation-duration: 1000ms;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
		}

		&--arrow {
			position: absolute;
			top: 6px;
			right: 6px;

			padding: 0;
		}
	}
	&__content {
		display: flex;
		justify-content: center;
		align-items: center;
		text-transform: capitalize;
		&--hidden {
			opacity: 0;
		}
	}
	// variants
	&--primary {
		background-color: $primary_color;
		color: $white;
		transition: all 0.3s;
		&:hover {
			background-color: $link_hover_color;
		}
		//active = "pressed" in the design system on figma
		&:active {
			background-color: $link_visited_color;
		}
		&:disabled {
			cursor: not-allowed;
			background-color: rgba($placeholder_color, 0.5);
		}
	}
	&--yellow {
		background-color: $secondary_button_color;
		color: $title_color;
		transition: all 0.3s;
		font-weight: 450;

		&:hover {
			background-color: $secondary_button_hover_color;
		}
		&:active {
			background-color: $secondary_active_color;
		}
		&:disabled {
			cursor: not-allowed;
			background-color: $disabled_btn_bg_color;
			color: rgba($text_color, 0.5);
		}
	}
	&--alert {
		background-color: $warning_color;
		color: $white;
		transition: all 0.3s;
		&:hover {
			background-color: $warning_hover_color;
		}
		&:active {
			background-color: $warning_active_color;
		}
		&:disabled {
			cursor: not-allowed;
			background-color: rgba($placeholder_color, 0.5);
		}
	}
	&--secondary {
		background-color: $secondary_bg_color;
		color: $primary_color;
		transition: all 0.3s;

		&:hover {
			background-color: $main_hover_bg_color;
		}
		&:active {
			background-color: $main_active_bg_color;
		}
		&:disabled {
			cursor: not-allowed;
			background-color: rgba($placeholder_color, 0.5);
			color: $white;
		}
	}
	&--plain {
		background-color: transparent;
		color: $primary_color;
		text-transform: none;
		transition: all 0.3s;
		&:hover {
			color: $link_hover_color;
		}
		&:active {
			color: $link_visited_color;
		}
		&:disabled {
			cursor: not-allowed;
			color: rgba($text_color, 0.5);
		}
	}
	&--plain-alert {
		background-color: transparent;
		color: $warning_color;
		text-transform: none;
		transition: all 0.3s;
		&:hover {
			color: $warning_hover_color;
		}
		&:active {
			color: $warning_active_color;
		}
		&:disabled {
			cursor: not-allowed;
			color: rgba($text_color, 0.5);
		}
	}
	&--white-alert {
		background-color: #eeeff1;
		color: $warning_color;
		text-transform: none;
		transition: all 0.3s;
		&:hover {
			background-color: #e6e7ea;
			color: $warning_hover_color;
		}
		&:active {
			background-color: #e6e7ea;
			color: $warning_active_color;
		}
		&:disabled {
			cursor: not-allowed;
			color: rgba($text_color, 0.5);
		}
	}
	&--green {
		background-color: #00814d;
		color: #ffffff;
		text-transform: none;
		transition: all 0.3s;
		&:hover {
			background-color: #008f55;
		}
		&:active {
			background-color: #008f55;
		}
		&:disabled {
			cursor: not-allowed;
			color: rgba($placeholder_color, 0.5);
		}
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
