@import '../../../styles/responsive';
.pf-error {
	display: flex;
	flex-direction: column;
	flex: 1;

	a {
		text-decoration: none !important;
	}
	@include belowLg {
		background-color: $white;
		.pf-header {
			justify-content: center;
			box-shadow: 0px 0px 12px rgba(63, 72, 87, 0.1);
			&__label,
			&__user {
				display: none;
			}
		}
	}
	&__heading {
		font-family: $secondary-font;
		font-style: normal;
		font-size: 200px;
		line-height: 1;
		color: $title_color;
		@include belowLg {
			font-size: 144px;
		}
	}
	&__description {
		color: $text_color;
		margin-bottom: 50px;
		@include belowLg {
			margin-bottom: 35px;
		}
	}
	&__block {
		justify-content: center;
		align-items: center;
		display: flex;
		flex-direction: column;
		flex: 1;
		margin: auto;
		width: 520px;
		max-width: 100%;
		@include belowLg {
			width: 290px;
		}
	}
}
