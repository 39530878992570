@import '../../../../../styles/variables/variables';

.container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;

	margin-bottom: 15px;

	&Compact {
		margin-bottom: 5px;
	}

	.label,
	.value {
		font-family: $base-font;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 150%;
		color: $title_color;
	}

	.value {
		text-align: right;
	}
}
