@import 'styles/responsive';
@import 'styles/variables/variables';

.container {
	margin-top: 20px;
}

.button {
	cursor: pointer;
	user-select: none;

	span {
		font-family: $base-font;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;

		color: #3349b5;
	}

	.icon {
		margin-left: 14px;

		> span > svg {
			transform: rotate(180deg);
		}

		&Expanded {
			> span > svg {
				transform: rotate(0deg);
			}
		}
	}
}

table {
	margin-top: 20px;
	width: 100%;

	tr,
	th {
		background: #f7f7f8;
	}

	tr:nth-child(odd) {
		background: #ffffff;
	}

	th,
	td {
		padding: 10px 25px;

		@include belowMd() {
			padding: 10px 15px;
		}

		font-family: $base-font;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;

		color: #8b919b;
	}

	th:not(:first-child) {
		text-align: right;
	}

	td:not(:first-child) {
		color: #59657a;

		text-align: right;

		padding: 10px 10px 10px 0;

		@include belowMd() {
			padding: 10px 10px 10px 0;
		}
	}

	td:last-child {
		width: 65px;
		box-sizing: content-box;

		padding: 10px 25px 10px 0;

		@include belowMd() {
			padding: 10px 15px 10px 0;
		}

		> span {
			display: flex;

			justify-content: center;
			align-items: center;

			width: 65px;

			padding: 0 8px;
			background-color: #db8f2c;
			color: #fff;
			border-radius: 888px;

			font-family: $base-font;
			font-size: 10.24px;
			font-style: normal;
			font-weight: 700;
			line-height: 20px;
			text-transform: uppercase;
		}
	}
}
