@import 'styles/variables/colors';
@import 'styles/variables/fonts';

.container {
	cursor: pointer;

	display: flex;
	align-items: center;

	margin: 0 10px;
	padding: 5.5px 20px 5.5px 15px;

	background: #3349b5;
	border-radius: 24px;
}

.label {
	margin-left: 7px;

	font-family: $secondary-font;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	color: $white;
}
