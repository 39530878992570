@import '../../../../styles/responsive';

.referPopup_main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 35px;
  padding-top: 35px;

  @media only screen and (max-width: 991px) {
    margin-bottom: 30px;
  }

  @include belowMd {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .description {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}