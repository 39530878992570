@import '../../../styles/responsive';
@import '../../../styles/variables/variables';

.current-info {
	&__btn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 70px;
		height: 40px;
		border: none;
		border-radius: 3px;

		& span {
			margin-left: 15px;
			font-family: $secondary-font;
			font-weight: 400;
			font-size: 16px;
			line-height: 23px;
			text-transform: capitalize;
			color: $white;
		}

		&:not(:last-of-type) {
			margin-right: 10px;
		}

		&:first-of-type {
			width: 280px;
			height: 40px;
			background-color: $primary_color;

			&:hover {
				background-color: $primary_hover_color;
			}
		}
	}

	&__btn-wrapper {
		margin-bottom: 50px;
	}

	&__btn-container {
		display: flex;
	}

	&__btn-group-main {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	&__title {
		margin-bottom: 25px;
		font-family: $secondary-font;
		font-weight: 500;
		font-size: 13px;
		line-height: 25px;
		text-transform: uppercase;
		color: $placeholder_color;
	}

	&__subtitle {
		margin-bottom: 10px;
		font-weight: 500;
		color: $primary_color;
	}

	&__risks-list {
		padding: 0;
		margin-bottom: 50px;

		& li {
			position: relative;
			padding-left: 30px;
			color: $text_color;
			list-style-type: none;

			&:not(:last-of-type) {
				margin-bottom: 12px;
			}

			&::before {
				content: '';
				position: absolute;
				top: 50%;
				left: 1px;
				transform: translateY(-50%);
				width: 14px;
				height: 14px;
				background: $approval_color;
				border-radius: 2px;
			}
		}

		& li.high-risk::before {
			background: $warning_color;
		}
	}

	&__meeting {
		display: flex;
		margin-bottom: 50px;

		& > div {
			margin-right: 15px;
		}

		& span:first-of-type {
			margin-right: 15px;
			font-weight: 500;
			color: $title_color;
		}

		& span:last-of-type {
			color: $text_color;
		}
	}

	&__reports {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;

		.currentReport-badge {
			margin-bottom: 7.5px;
		}

		.report-wrapper {
			cursor: pointer;
			display: flex;
			align-items: center;

			.report-dot {
				display: inline-block;
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background-color: $primary_color;
				margin-right: 10px;
			}

			& > .report-icon {
				display: flex;
				justify-content: center;
				align-items: center;

				div,
				svg {
					display: flex;
					justify-content: center;
					align-items: center;
				}
				margin-left: 10px;
			}

			& > span {
				font-family: $base-font;
				font-style: normal;
				font-weight: 400;
				font-size: 12.8px;
				line-height: 15px;

				color: $text_color;
			}
		}
	}
}

.pf-leads-scheduleMeeting-button {
	margin-top: 5px;

	&:disabled {
		svg {
			path {
				fill: $white;
			}
		}
	}
}

.sendScheduleMeetingNote {
	margin-top: 20px;
}

// TODO: весь код ниже относится к выподающим кнопкам (они понадобятся когда доработают фунционал)
.btn__icon {
	cursor: pointer;
}

#bg-nested-dropdown {
	width: 70px;
	height: 40px;
	margin-left: 10px;
	border: none;
	border-radius: 3px;
	color: $title_color;
	background-color: $secondary_bg_color;
	&:after {
		content: none;
	}
	&:focus {
		box-shadow: none;
	}
	&:hover {
		background-color: $main_active_bg_color;
	}
	div {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 17.25px;
		margin: auto;
	}
}

.current-info__btn-group {
	.dropdown-menu {
		width: 260px;
		padding: 0 !important;
		inset: 5px auto auto 0 !important;
		transform: translate(-180px, 42px) !important;
		background: $white;
		border: 1px solid rgba(0, 0, 0, 0.04);
		box-sizing: border-box;
		box-shadow: 0 1px 12px rgba(63, 72, 87, 0.1);
		border-radius: 3px;
	}
}

.dropdown-item {
	display: flex !important;
	justify-content: flex-start;
	align-items: center;
	height: 45px;
	padding: 0 15px !important;
	text-decoration: none !important;

	&:hover {
		background-color: $secondary_bg_color !important;
		text-decoration: none;
	}

	&.disabled {
		opacity: 0.5;
		cursor: not-allowed;
		pointer-events: visible !important;
	}

	&__text {
		display: flex;
		align-items: center;

		span {
			margin-left: 13px;
			font-family: $base-font;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 22px;
			color: $title_color;
		}

		&.requestReport {
			svg {
				path {
					stroke: #3f4857;
				}
			}
		}

		&.referBusiness {
		}
	}
}

.quitTeamButton {
	svg {
		path {
			fill: $warning_color !important;
		}
	}

	span {
		color: $warning_color;
	}
}

hr {
	color: $hr !important;
	margin: 5px 10px !important;
}
