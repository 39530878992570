@import '../../../styles/responsive';
@import '../../../styles/variables/variables';

.pf-table {
	flex: 1;
	display: flex;
	flex-direction: column;
	&__body {
		//flex: 1;
		border-radius: 6px;
		background-color: $white;
	}
}

.rs-table-loader-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;

	.pf-table-loader {
		@media only screen and (max-width: 800px) {
			max-width: 150px;
		}
	}
}

.pf-tableText {
	font-family: $base-font;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	color: $text_color;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	.text {
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.pf-new {
	font-weight: 500;
	color: $primary_color;
}

.pf-reportsCellItem {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
}

.pf-reportsTime {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 75px;
	height: 28px;
	border-radius: 24px;
	margin-left: 10px;
	background-color: $secondary_bg_color;
	font-family: $base-font;
	font-style: normal;
	font-weight: normal;
	font-size: 10.24px;
	line-height: 14px;
	text-align: center;
	color: $text_color;
}

.new {
	color: $primary_color;
	background-color: rgba(51, 73, 181, 0.14);
}

@media only screen and (max-width: 360px) {
	.pf-tableText,
	.pf-reportsText,
	.hkcGBu,
	.pf-teamContainer > .pf-userCellItem > div {
		font-size: 12.8px;
		line-height: 18px;
	}

	.headerCellContent > span {
		font-size: 12.8px;
		line-height: 17.66px;
	}
}

.rs-table-row-header {
	box-shadow: none !important;
}

.rs-table-hover .rs-table-row:hover .rs-table-cell,
.rs-table-hover .rs-table-row:hover .rs-table-cell-group,
.rs-table-hover .rs-table-row:not(.rs-table-row-header):hover {
	background-color: $main_bg_color !important;
}

.rs-table-hover .rs-table-row-header:hover .rs-table-cell,
.rs-table-hover .rs-table-row-header:hover .rs-table-cell-group {
	background-color: $white !important;
}

.rs-table-cell,
.rs-table-cell-group,
.rs-table-row {
	line-height: 19px;
	&.active > div {
		background-color: #f7f7f8 !important;
		div {
			background-color: #f7f7f8 !important;
		}
	}
}

.modal-backdrop.show {
	opacity: 0 !important;
}

.pointer {
	cursor: pointer;
}

.blocked {
	opacity: 0.5;
}
// new 31.03.2022 test
.rs-table-row {
	.pf-tableText,
	.pf-splitButton {
		opacity: 1;
		transition: opacity 0.4s;
	}
}
.rs-table-row.deleted {
	.pf-tableText,
	.pf-splitButton {
		opacity: 0.5;
	}

	//cursor: initial;
	//position: relative;
	//&:before {
	//	left: 0;
	//}

	.rs-checkbox {
		display: none;
	}
}
// end new 31.03.2022 test
// new 12.05.2022
.rs-table-row.newCompany {
	background-color: #fff4d9 !important;
	.rs-table-cell {
		background-color: #fff4d9 !important;
	}
}

.rs-table-row.highlighted {
	background-color: #fff4d9 !important;
	.rs-table-cell {
		background-color: #fff4d9 !important;
	}
}

.rs-checkbox-wrapper .rs-checkbox-inner:before,
.rs-checkbox .rs-checkbox-inner:before,
.rs-checkbox-wrapper:before {
	width: 16px;
	height: 16px;
	border: 1px solid $placeholder_color !important;
	border-radius: 2px !important;
	background: $white;
	cursor: pointer;
}

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
	background-color: $primary_color !important;
}

.rs-table-cell-header .rs-table-cell-content {
	font-family: $base-font;
	font-style: normal;
	font-weight: 500;
	font-size: 16px !important;
	line-height: 19px !important;
	color: $line_color;
	align-items: center;
	flex-wrap: nowrap;
}

.headerCellContent {
	span {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
}

.rs-table-cell-header-sort-wrapper {
	display: none !important;
}

.rs-table-cell-content {
	white-space: normal;
	word-break: initial;
}

@media only screen and (min-width: 569px) {
	.rs-table-row .rs-checkbox {
		visibility: hidden;
	}

	.rs-table-row-header .rs-checkbox-checker,
	.rs-table-row .rs-checkbox-checked {
		visibility: visible !important;
	}

	.rs-table-row:not(.rs-table-row-header):hover .rs-checkbox-checker {
		visibility: visible;
	}

	.pf-tableBar-selected ~ .pf-table .rs-table .rs-checkbox {
		visibility: visible;
	}
}

.rs-table-row:last-child:not(.rs-table-row-header) {
	border-bottom: none !important;
}

.pf-pagination {
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
	height: 60px;
	border-top: 1px solid #f2f2f5;

	.pf-pagination-displayLength {
		display: flex;
		flex-direction: row;
		position: absolute;
		left: 40px;

		.pf-pagination-displayLength-text {
			margin-right: 15px;
			font-family: $secondary-font;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 19px;
			display: flex;
			align-items: center;
			white-space: pre-wrap;
			color: $placeholder_color;
		}

		select:focus {
			background-color: $white !important;
		}

		.pf-pagination-select {
			border: none !important;
			font-family: $secondary-font;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 19px;
			display: flex;
			align-items: center;
			color: $text_color;

			div:first-child {
				padding-top: 0 !important;
				padding-bottom: 0 !important;
				display: flex;
				align-items: center;
			}

			div:focus {
				background-color: $white !important;
			}

			label {
				box-sizing: content-box;
			}

			&::after {
				content: none !important;
			}

			&::before {
				content: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L1 9' stroke='%23A4ABB7'/%3E%3C/svg%3E") !important;
				border-bottom: none !important;
				right: 8px !important;
				left: initial !important;
				bottom: 4px;
				transform: rotate(90deg);
			}
		}
	}

	.pf-pagination-pages {
		margin: 0 auto;
		ul {
			li {
				button {
					font-family: $secondary-font;
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 19px;
					display: flex;
					align-items: center;
					color: $text_color;
				}
			}
		}
	}
}

.pf-mobile-pagesCount {
	display: none;
	font-family: $secondary-font;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	align-items: center;
	color: $text_color;
}

@media only screen and (max-width: 800px) {
	.pf-pagination {
		.pf-pagination-pages {
			display: flex;
			position: absolute;
			margin: 0;
			left: initial;
			right: 25px;
		}
	}
}

@media only screen and (max-width: 660px) {
	.pf-pagination {
		.pf-pagination-displayLength {
			left: initial;
			right: 25px;
		}

		.pf-pagination-displayLength-text {
			white-space: pre-wrap;

			span:first-child {
				display: none;
			}

			span:nth-child(2) {
				text-transform: capitalize;
			}
		}

		.pf-pagination-pages {
			display: flex;
			flex-direction: row;
			position: absolute;
			left: 25px;
			max-width: 345px;

			@media only screen and (max-width: 520px) {
				width: 180px !important;

				.MuiPagination-ul {
					li {
						display: none;
					}

					li:first-child,
					li:last-child {
						display: block;
					}

					li:first-child {
						margin-right: 40px;
					}
					li:last-child {
						margin-left: 40px;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 520px) {
	.pf-mobile-pagesCount {
		display: flex;
		position: absolute;
		left: 80px;
	}
}

//.rs-table-cell-content {
//	word-break: break-word !important;
//}

.pf-emptyPage {
	position: relative;
	top: 25%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 20px;

	@media only screen and (max-width: 520px) {
		top: 0;
	}

	.pf-emptyPage-main {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;

		h2 {
			margin: 20px 0;
			font-family: $secondary-font;
			font-style: normal;
			font-weight: 500;
			font-size: 31.25px;
			line-height: 39px;
			text-align: center;
			color: $title_color;
			word-wrap: break-word;
		}

		p {
			font-family: $base-font;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 150%;
			text-align: center;
			color: $text_color;
			word-wrap: break-word;
		}
	}

	.pf-emptyPage-footer {
		width: 100%;
		max-width: 380px;
	}
}

.rs-checkbox-checker {
	padding-bottom: 11px !important;
}
