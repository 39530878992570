@import '../../styles/responsive';

.referPopup {
    .referPopup_paper {
        overflow: auto;
        &:has(.referUnregisteredUserSection) {
            height: fit-content;

            @include belowMd {
                height: 100%;
            }
        }
    }

    .stepsBlock {
        display: flex;
        flex-direction: column;
        height: 100%;

        .tab-animation {
            flex-grow: 1;

            .tab-animation__wrap {
                height: 100%;

                .animation-item {
                    &>div {
                        height: 100%;
                    }

                    .step-page {
                        height: 100%;
                        padding: 0;
                        margin: 0;
                    }
                }
            }
        }
    }

    .stepsBlock .tabs-flexContainer {
        margin-bottom: 13px;
    }
}