@import '../../../styles/variables/variables';

.pfNote {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 20px;
	width: 100%;
	background: $note_bg;
	border-radius: 3px;

	&__img {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		width: 24px;
		height: 24px;
		flex-shrink: 0;
		margin-right: 14px;

		.infoImg {
			width: 100%;
			height: auto;

			svg {
				width: 100%;
				height: auto;
				path {
					fill: $note_icon_color;
				}
			}
		}
	}

	&__message {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;

		font-family: $base-font;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 150%;
		color: $text_color;

		@media only screen and (max-width: 713px) {
			padding-right: 10px;
		}
	}
}
