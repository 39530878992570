@import '../../../../styles/responsive';

.referPopup_main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 35px;
  padding-top: 35px;

  @media only screen and (max-width: 991px) {
    margin-bottom: 30px;
  }

  @include belowMd {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }


  .pf_mb_4 {
    margin-bottom: 25px;
    padding: 0 7.5px;
  }

  &:has(.referUnregisteredUserSection) {
    margin-bottom: 10px;
  }

  .referYourClientsSection {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .referUnregisteredUserSection {
    display: flex;
    flex-wrap: wrap;

    margin: 0 -8px;
  }

  .searchSection {
    margin-bottom: 19px;

    @media only screen and (max-width: 991px) {
      margin-bottom: 18px;
    }
  }

  .searchResultSection {
    position: relative;
    display: flex;
    margin-bottom: 35px;
    flex-grow: 1;
    border-bottom: 1px solid #EEEFF1;

    @media only screen and (max-width: 991px) {
      margin-bottom: 30px;
    }

    div[data-test-id='virtuoso-scroller'] {
      /* width */
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        transition: width 0.3s ease-in-out;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 8px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 8px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background-color: transparent;
      }
    }

    div[data-test-id='virtuoso-scroller']:hover {
      /* Track */
      &::-webkit-scrollbar-track {
        background-color: rgba($placeholder_color, 0.1);
        border-radius: 8px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background-color: rgba($placeholder_color, 0.3);
        border-radius: 8px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background-color: rgba($placeholder_color, 0.5);
      }
    }

    .loadingWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }

  .searchActionsSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .selectedCount {
      font-family: $base-font;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;

      color: #A4ABB7;
    }

    .clearAllButton {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      outline: none;
      background-color: transparent;

      &__text {
        font-family: $base-font;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;

        color: $primary_color;
      }

      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
        margin-right: 10px;

        span {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        svg {
          width: 100%;
          height: auto;

          path {
            stroke: $primary_color;
          }
        }
      }
    }
  }

  .whoIsReferred {
    flex: 0 0 auto;
    width: 100%;
    margin-bottom: 34px;

    label {
      &:first-child {
        margin-right: 40px;

        @include belowMd {
          margin-right: 30px;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}