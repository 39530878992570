@import '../../../styles/responsive';

.container {
	flex-grow: 1;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.contentContainer {
	width: 500px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	@include belowLg {
		width: min(360px, 100%);
	}
}

.back {
	width: auto;

	padding: 0;
	margin-bottom: 10px;

	@include belowLg {
		margin: 0 26px;
	}
}

.content {
	width: 500px;
	padding: 60px 70px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	background-color: $white;

	text-align: center;

	@include belowLg {
		width: min(360px, 100%);

		padding: 32px 26px;
	}

	.icon {
		svg {
			height: 80px;
			width: 80px;

			@include belowLg {
				height: 56px;
				width: 56px;
			}
		}
	}

	.title {
		margin: 15px 0;

		color: $title_color;

		font-family: $secondary-font;

		font-size: 20px;
		font-weight: 500;
		line-height: 150%;

		@include belowLg {
			margin: 24px 0 11px 0;

			line-height: normal;
		}
	}

	.subtitle {
		margin-bottom: 30px;

		color: $text_color;

		font-family: $base-font;
		font-size: 16px;
		font-weight: 400;
		line-height: 150%;

		@include belowLg {
			margin-bottom: 32px;

			font-size: 12.8px;
			line-height: 125%;
		}
	}

	.button {
		margin-bottom: 36px;
	}

	.contacts {
		.hint {
			color: $line_color;

			font-family: $base-font;
			font-size: 16px;
			font-weight: 500;
			line-height: normal;

			@include belowLg {
				font-size: 12.8px;
			}
		}

		.link {
			margin-top: 5px;

			color: $primary_color;

			font-family: $base-font;
			font-size: 16px;
			font-weight: 500;
			line-height: normal;

			&:hover {
				color: $primary_color;

				text-decoration: underline;
			}

			@include belowLg {
				font-size: 12.8px;
			}
		}
	}
}
