@import 'styles/variables/fonts';
@import 'styles/variables/colors';

.paper {
	max-width: 560px !important;
	min-width: 320px;
}

.title {
	margin: 0 20px 10px;

	text-align: center;
	font-family: $secondary-font;
	font-style: normal;
	font-weight: 500;

	font-size: 31.25px;
	line-height: 39px;

	color: #1f2c6e;
}

.description {
	margin: 0 20px 30px;

	text-align: center;
	font-family: $base-font;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;

	color: #59657a;
}
