@import '../../../styles/variables/variables';
.pf-link {
	text-decoration: none;
	color: $primary_color;
	font-weight: 500;
	word-break: break-word;
	&:hover {
		color: $link_hover_color;
	}
	&:visited {
		color: $link_visited_color;
	}
}
