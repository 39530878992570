@import '../../styles/responsive';
@import '../../styles/variables/variables';

.general-bloc {
  &__wrapper {
    width: 100%;
    max-width: 570px;

    .youtube-frame {
      width: 100%;
      max-width: 570px;
      height: 320px;
      margin-bottom: 50px;

      @include belowMd {
        max-width: 320px;
        height: 200px;
      }
    }
  }

  &__tags {
    margin-bottom: 25px;
    .pf-badge {
      margin-top: 0;
      margin-bottom: 10px;
    }
    @include belowLg {
      margin-bottom: 18px;
    }
  }
}
