@import '../../../styles/responsive';
@import '../../../styles/variables/variables';

.pf-navigation {
	display: flex;
	align-items: center;
	width: 100%;

	&__list {
		display: flex;
		padding: 0;
		margin: 0;
		width: 100%;
	}

	&__item {
		font-family: $secondary-font;
		font-weight: normal;
		font-size: 20px;
		line-height: 25px;
		list-style-type: none;
		min-width: 164px;
		max-width: 230px;
		width: 100%;

		&.single {
			display: flex;
			align-items: center;
			height: 60px;
			padding-left: 18px;

			@include belowLg {
				height: 50px;
			}
		}

		&.active {
			font-family: $secondary-font;

			@include belowXL {
				max-width: fit-content;
			}
		}

		& a {
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 60px;
			text-decoration: none !important;
			color: $text_color;

			@include belowLg {
				height: 50px;
			}
		}

		& a:hover {
			color: $text_color;
		}

		&:hover {
			background-color: $main_bg_color;
		}

		&.sidebar__item {
			a {
				height: 60px;
				font-size: 16px !important;
			}
		}
	}
}

.pf-navigation__item.active {
	@include belowXL {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		min-width: 160px;
		padding: 0 18px;
	}

	& a {
		font-size: 20px;

		@include belowXL {
			justify-content: flex-start;
		}
	}

	&:nth-of-type(2n + 1) {
		background-color: $secondary_color;

		& a {
			color: $title_color;
		}

		&:hover {
			background-color: $secondary_hover_color;
		}
	}

	&:nth-of-type(2n) {
		background-color: $primary_color;

		&:hover {
			background-color: $primary_hover_color;
		}

		& a {
			color: $white;
		}

		& .pf-button__icon {
			padding: 0;

			& path {
				stroke: $white;
			}
		}
	}

	&.SuperAdmin {
		&:nth-of-type(2n) {
			background-color: $secondary_color;
			svg {
				path {
					stroke: $title_color;
				}
			}

			& a {
				color: $title_color;
			}

			&:hover {
				background-color: $secondary_hover_color;
			}
		}

		&:nth-of-type(2n + 1) {
			background-color: $primary_color;

			&:hover {
				background-color: $primary_hover_color;
			}

			& a {
				color: $white;
			}

			& .pf-button__icon {
				padding: 0;

				& path {
					stroke: $white;
				}
			}
		}
	}
}

.pf-navigation:not(.sidebar__nav) li.active a {
	@include belowLg {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		pointer-events: none;
		font-size: 16px;
	}
}

.menu {
	@include belowXL {
		display: flex;
		align-items: center;
		width: 24px;
		height: 24px;
		margin-right: 10px;
	}
}
.menu-btn {
	padding: 0;
	width: auto;

	div,
	span {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.sidebar__nav {
	& ul {
		flex-direction: column;
		width: 100%;
	}

	& li.active,
	& li {
		padding-left: 0;
	}

	& a {
		justify-content: flex-start;
		width: 100%;
		padding-left: 70px;
	}
}
