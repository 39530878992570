@import '../../styles/responsive';

.invitationHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 34px;
	width: 100%;
	height: 60px;
	z-index: 1101;
	box-shadow: 0 0 12px rgba(63, 72, 87, 0.1);

	@include belowLg {
		height: 50px;
		padding: 0 20px;
	}

	.buttonsContainer {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		width: 100%;

		.text {
			width: fit-content;
			margin-right: 28px;
			font-family: $secondary-font;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 20px;

			color: $text_color;

			@include belowMd {
				display: none;
			}
		}

		.signUpButton, .signInButton {
			max-width: 160px !important;
			height: 40px !important;
		}

		.signUpButton {
			margin-right: 5px;
		}
	}
}
