@import '../../../styles/responsive';

.container {
	flex-grow: 1;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.contentContainer {
	width: 590px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	@include belowLg {
		width: min(360px, 100%);
	}
}

.back {
	width: auto;

	padding: 0;
	margin-bottom: 10px;

	@include belowLg {
		margin: 0 26px;
	}
}

.content {
	width: 590px;
	padding: 70px 70px 60px 70px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	background-color: $white;

	text-align: center;

	@include belowLg {
		width: min(360px, 100%);

		padding: 30px;
	}

	.title {
		margin-bottom: 20px;

		color: $title_color;

		font-family: $secondary-font;

		font-size: 31.25px;
		font-weight: 500;
		line-height: normal;
	}

	.subtitle {
		margin-bottom: 50px;

		color: $text_color;

		font-family: $base-font;

		font-size: 16px;
		font-weight: 400;
		line-height: 150%;

		@include belowLg {
			margin-bottom: 35px;
		}
	}

	.form {
		width: 100%;

		display: flex;
		flex-direction: column;
		gap: 23px;
	}

	.button {
		margin-top: 23px;
	}
}
