@import '../../../styles/responsive';
@import '../../../styles/variables/variables';

.referredToMeAdditionalInfo {
	.btnContainer {
		display: flex;
		margin-bottom: 40px;

		.btnGroup {
			display: flex;
			flex-direction: column;
			width: 100%;
			z-index: 10;

			.dismissRefer_button {
				.dismissRefer_button_icon {
					svg {
						circle, path {
							stroke: #FFFFFF;
						}
					}
				}
			}

			.scheduleMeeting_button {
				margin-bottom: 10px;
				.scheduleMeeting_button_icon {
					svg {
						circle, path {
							stroke: #3349b5;
						}
					}
				}
			}

			.accept_button {
				margin-bottom: 10px;
				.accept_button_icon {
					svg {
						circle, path {
							stroke: #FFFFFF;
						}
					}
				}
			}
		}
	}

	.referredType, .description {
		.text {
			font-family: $base-font;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 150%;
			word-break: break-word;

			color: #59657A;
		}
	}

	.referredType {
		margin-bottom: 40px;
	}

	.additionalInfoTitle {
		margin-bottom: 20px;
		font-family: $secondary-font;
		font-weight: 500;
		font-size: 13px;
		line-height: 25px;
		text-transform: uppercase;
		color: $placeholder_color;
	}

}
