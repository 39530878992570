@import '../../../styles/variables/variables';
@import '../../../styles/responsive';

.pf-signin {
	background-color: $main_bg_color;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@include belowLg {
		background-color: $white;
		.pf-header {
			justify-content: center;
			box-shadow: 0px 0px 12px rgba(63, 72, 87, 0.1);
			&__label,
			&__user {
				display: none;
			}
		}
	}

	.pf-header {
		.logo {
			@include belowXL {
				margin: 0 34px;
			}
			@include belowLg {
				margin: initial;
			}
		}
	}

	&__container {
		width: 1240px;
		max-width: calc(100% - 40px);
		margin: auto;
		display: flex;
		justify-content: center;
		border-radius: 6px;
	}
	&__banner,
	&__form-wrapper {
		background-color: $white;
		width: 50%;
		border-radius: 0px 6px 6px 0px;

		@include belowLg {
			width: 100%;
		}
	}
	&__banner {
		background-image: url('../../../assets/sign-in-bg.png');
		background-size: cover;
		height: 680px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		text-align: center;
		border-radius: 6px 0px 0px 6px;
		&-logo {
			width: 160px;
			height: 170px;
		}
		&-heading {
			color: $white;
			width: 262px;
			padding-top: 50px;
			padding-bottom: 50px;
			font-weight: 700;
			font-size: 25px;
			font-family: $base-font;
		}

		&-link {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			font-family: $secondary-font;
			text-decoration: none;
			font-weight: 500;
			font-size: 20px;
			color: $white;
			&:hover,
			&:visited {
				color: $white;
			}

			.moreIcon {
				span {
					display: flex;
					justify-content: center;
					align-items: center;
				}

				svg {
					path {
						stroke: $white;
					}
				}
				margin-left: 10px;
			}
		}
		@include belowLg {
			display: none;
		}
	}
	&__form-wrapper {
		padding: 0px 70px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		text-align: center;

		@include belowLg {
			width: 100%;
			max-width: 400px;
			padding-top: 40px;
			padding-left: 20px;
			padding-right: 20px;
			padding-bottom: 50px;
		}
	}
	&__form {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 40px;
		padding-bottom: 40px;
		border-bottom: 1px solid rgba(63, 72, 87, 0.2);

		&-title {
			font-family: $secondary-font;
			font-style: normal;
			font-weight: 500;
			font-size: 31.25px;
			color: $title_color;
		}
		&-footer {
			padding-top: 40px;

			&__title {
				margin-bottom: 20px;
				font-family: $secondary-font;
				font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 125%;

				text-align: center;

				color: #59657a;
			}

			.createProfileButton {
				height: 50px;
			}
		}

		.error-message {
			&__container {
				width: 100%;

				margin: 15px 0;

				display: flex;
				align-items: flex-start;
				justify-content: flex-start;

				gap: 10px;
			}

			&__icon {
				display: flex;
				flex-shrink: 0;
				justify-content: center;
				align-items: center;
				width: 20px;
				height: 20px;

				div {
					display: flex;
					justify-content: center;
					align-items: center;

					svg {
						path {
							fill: $warning_color;
							fill-opacity: 1;
						}
					}
				}
			}

			&__text {
				text-align: left;
				font-family: $base-font;
				font-style: normal;
				font-weight: 400;
				font-size: 12.8px;
				line-height: 20px;

				color: $warning_color;
			}
		}

		.signInButton {
			height: 50px;
		}
	}
	&__modal-btn {
		width: 180px;
	}
	&__footer {
		font-size: 16px;
		text-align: center;
		&-links {
			padding-bottom: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding-top: 32px;
		}
		&-link {
			cursor: pointer;
			text-decoration: none;
			font-weight: 500 !important;
			font-family: $base-font;
			font-style: normal;
			font-size: 16px;
			line-height: 19px !important;
			margin-left: 5px;
			color: #3349b5 !important;
			font-weight: 500;
			word-break: break-word;
			&:hover {
				text-decoration: underline !important;
			}
		}
	}
}
