@import '../../../styles/responsive';

.searchPanel {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 100%;
	margin: 0 50px 0 28px;

	@include belowXL {
		margin: 0 20px;
	}

	.searchButton {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0;
		background-color: transparent;
		border: none;
		animation: none;

		div {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&:hover {
			background-color: transparent;
		}

		.pf-search-icon {
			cursor: pointer;
			width: 16px;
			height: 16px;

			&:hover {
				opacity: 0.9;
			}

			svg {
				width: 100%;
				height: auto;

				circle, path {
					stroke: $line_color;
				}
			}
		}
	}

	.collapseAria {
		position: absolute;
		overflow: hidden;
		width: initial;
		top: 0;
		height: 0;
		left: 108px;
		right: 108px;
		border-bottom: 1px solid rgba(63, 72, 87, 0.04);
		transition: height 0.3s ease-in-out;
		box-sizing: content-box;

		&.show {
			height: 60px;

			@include belowLg {
				height: 50px;
			}
		}

		@include belowXL {
			left: 0;
			right: 0;
		}

		.pf-inputGroup {
			height: 100%;
			overflow: hidden;
			border-radius: 0 !important;
			transition: none !important;
			outline: none;
			border: none;

			&:focus-within, &:hover {
				outline: none;
				border: none;
			}

			.rs-input {
				height: 100% !important;
				padding-left: 25px;
				padding-right: 50px !important;
				border-radius: 0;
				border: none;
				background-color: #f2f2f2 !important;
				color: $title_color;
				caret-color: $primary_color;
			}

			.pf-inputGroup__button {
				cursor: pointer;
				display: flex;
				justify-content: center;
				align-items: center;
				right: 23px !important;
				top: calc(50% - 16px / 2 - 1px) !important;
				width: 16px !important;
				height: 16px !important;
				padding: 0 0 0 1px !important;
				border-radius: 0 !important;

				div {
					display: flex;
					justify-content: center;
					align-items: center;
				}

				svg {
					width: 100%;
					height: auto;

					path {
						stroke: $line_color;
						transition: stroke 0.3s ease-in-out;
					}
				}

				&:hover {
					background-color: transparent !important;

					svg {
						path {
							stroke: $primary_color;
						}
					}
				}
			}
		}
	}
}