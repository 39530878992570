@import '../../../styles/responsive';

//.pf-modalTS {
//	.MuiDialogActions-root {
//		padding: 0;
//	}
//}
.pf-modal {
	//background-color: $modal_bg;
	z-index: 2001 !important; //! 1101
	padding: 0 20px;

	.MuiDialogActions-root {
		padding: 0;
	}

	&.inputAvatarModal {
		.pf-modal__paper {
			max-width: 480px !important;
		}
	}

	&.inputAvatarModal {
		.pf-modal__paper {
			@include belowSm {
				max-width: 320px !important;
				margin: 0 !important;
			}
		}
	}

	&__close-icon {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 24px;
		height: 24px;
		right: 24px;
		top: 24px;
		padding: 0;
		margin: 0;
		background-color: transparent;

		div {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		svg {
			width: 100%;
			height: auto;

			path {
				stroke: #D1D5DB;
				transition: stroke 0.2s ease-in-out;
			}
		}

		&:hover {
			svg {
				path {
					stroke: $primary_color;
				}
			}
		}
	}

	&__paper {
		width: 100%;
		max-width: 435px !important;
		padding: 50px 35px 35px;
		margin: 0 !important;
		border-radius: 6px !important;

		@include belowMd {
			padding: 50px 20px 35px;
		}

		.pf-modal__close-area {
			padding: 0;
		}

		.pf-modal__title {
			text-align: center;
			margin-bottom: 35px;
			font-family: $secondary-font;
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 150%;
			color: $title_color;

			&.withSubtitle {
				margin-bottom: 10px;
			}

		}

		.pf-modal__subtitle {
			margin-bottom: 35px;
			font-family: $base-font;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 19px;
			text-align: center;

			color: $title_color;

		}

		.pf-modal__content {
			border: none;
			padding: 0;
			margin-bottom: 35px;
			overflow: initial;
		}

		&-header {
			border-bottom: none;
			padding: 45px 35px 16px;
			.btn-close {
				position: absolute;
				top: 20px;
				right: 20px;
				background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 3L13 13M3 13L13 3' stroke='%23D1D5DB'/%3E%3C/svg%3E");
				transition: all 0.3s;
				&:hover {
					background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 3L13 13M3 13L13 3' stroke='%232f43a7'/%3E%3C/svg%3E");
				}
			}
		}
		&-title {
			font-family: $secondary-font;
			font-weight: 600;
			font-style: normal;
			//font-weight: 500;
			font-size: 20px;
			line-height: 150%;
			text-align: center;

			color: #3f4857;
		}
		&-content {
			border: none;
		}
		&-footer {
			padding: 16px 35px 35px;
			border-top: none;
		}
		&-header {
			justify-content: center;
		}
	}
	//This is a container for the two buttons
	&__footer-inner {
		.pf-button {
			margin: 0 2.5px;
			height: 40px;
		}
	}
	&__content {
		margin: 0 2.5px;
	}
}

.modal-backdrop.fade.static {
	pointer-events: initial !important;
}

.modal-backdrop {
	z-index: 2000 !important;
}
