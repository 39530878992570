@import '../styles/responsive';

.pf-app {
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	main {
		background-color: $white;
	}
}
