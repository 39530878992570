@import '../../../styles/responsive';
.pf-badge {
	color: $text_color;
	background-color: $secondary_bg_color;

	// inherited from figma
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 6.5px 20px;
	position: relative;
	border-radius: 30px;
	margin: 2.5px;
	width: fit-content;

	@include belowLg {
		font-size: 12.8px;
	}

	&:hover {
		background-color: $main_hover_bg_color;
	}
	&:active {
		background-color: $main_active_bg_color;
	}
	&:disabled {
		opacity: 0.5;
	}
}

.pf-badge--success {
	@extend .pf-badge;
	background-color: $approval_color;
	color: $white;

	&:hover {
		background-color: $approval_color;
	}
}

.pf-badge--progress {
	@extend .pf-badge;
	background-color: $secondary_active_color;
	color: $white;
	padding: 6.5px 14px;

	&:hover {
		background-color: $secondary_active_color;
	}
}
