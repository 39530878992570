$white: #ffffff;
$primary_color: #3349b5;
$primary_hover_color: #2f43a7;
$secondary_color: #ffd680;
$secondary_hover_color: #ffce66;
$secondary_active_color: #db8f2c;
$secondary_button_color: #ffce66;
$secondary_button_hover_color: #ffc447;
$text_color: #59657a;
$title_color: #3f4857;
$warning_color: #f92d4d;
$warning_hover_color: #ff4260;
$warning_active_color: #f3092e;
$active_color: #00874e;
$approval_color: #00814d;
$border_bottom: #303742;
$link_hover_color: #334dcc;
$link_visited_color: #293ea3;
$placeholder_color: #a4abb7;
$main_bg_color: #f7f7f8;
$main_hover_bg_color: #f4f4f6;
$main_active_bg_color: #e6e7ea;
$secondary_bg_color: #eeeff1;
$input_error_bg_color: #ffe5ea;
$input_bg_color: #f2f2f2;
$disabled_btn_bg_color: #f6f6f7;
$line_color: #8b919b;
$modal_bg: #3f48574d;
$button_menu_hover: #fbfbfb;
$hr: #eeeff1;
$note_icon_color: #d1d5db;
$note_bg: rgba(238, 239, 241, 0.5);
$grey_dot: #7a8495;
